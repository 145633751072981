@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,900&display=swap);
/* == base == */

html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
}

article, aside, details, figcaption, figure, footer, header, main, nav, section, summary {
  display: block;
}

audio, canvas, progress, video,
.prod-info .blk_headline {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden], template {
  display: none;
}

.nodisplay {
  display: none;
}

a {
  background: transparent;
}

a:active, a:hover {
  outline: 0;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b, strong {
  font-weight: 700;
}

dfn {
  font-style: italic;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

mark {
  color: #000;
  background: #ff0;
}

small {
  font-size: 80%;
}

sub, sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sup {
  top: -.5em;
}

sub {
  bottom: -.25em;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 1em 40px;
}

hr {
  height: 0;
  box-sizing: content-box;
}

pre {
  overflow: auto;
}

code, kbd, pre, samp {
  font-size: 1em;
  font-family: monospace, monospace;
}

button, input, optgroup, select, textarea {
  margin: 0;
  color: inherit;
  font: inherit;
}

button {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, html input[type="button"], input[type="reset"], input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled], html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner, input::-moz-focus-inner {
  padding: 0;
  border: 0;
}

input {
  line-height: normal;
}

input[type="checkbox"], input[type="radio"],
.trust {
  padding: 0;
}

input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
}

input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

fieldset {
  margin: 0 2px;
  padding: .35em .625em .75em;
  border: 1px solid silver;
}

legend {
  padding: 0;
  border: 0;
}

textarea {
  overflow: auto;
}

optgroup {
  font-weight: 700;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td, th {
  padding: 0;
}

:focus {
  outline: 0;
}

a img {
  border: none;
  outline: none;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #fff inset;
}

*, :after, :before {
  box-sizing: border-box;
}

/* - plugin style*/
/*! fancyBox v2.1.5 fancyapps.com | fancyapps.com/fancybox/#license */
.fancybox-wrap, .fancybox-skin, .fancybox-outer, .fancybox-inner, .fancybox-image, .fancybox-wrap iframe,
.fancybox-wrap object, .fancybox-nav, .fancybox-nav span, .fancybox-tmp {
  padding: 0;
  margin: 0;
  border: 0;
  outline: none;
  vertical-align: top;
}

.fancybox-wrap {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 8020;
}

.fancybox-skin {
  position: relative;
  background: #f9f9f9;
  color: #444;
  text-shadow: none;
  border-radius: 4px;
}

.fancybox-opened {
  z-index: 10030;
}

.fancybox-opened .fancybox-skin {
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
}

.fancybox-outer, .fancybox-inner {
  position: relative;
}

.fancybox-inner {
  overflow: hidden;
}

.fancybox-type-iframe .fancybox-inner {
  -webkit-overflow-scrolling: touch;
}

.fancybox-error {
  color: #444;
  font: 14px/20px "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin: 0;
  padding: 15px;
  white-space: nowrap;
}

.fancybox-image, .fancybox-iframe {
  display: block;
  width: 100%;
  height: 100%;
}

.fancybox-image {
  max-width: 100%;
  max-height: 100%;
}

#fancybox-loading, .fancybox-close, .fancybox-prev span, .fancybox-next span {
  background-image: url(/build/images/fancybox_sprite.7b8e2ce6.png);
}

#fancybox-loading {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -22px;
  margin-left: -22px;
  background-position: 0 -108px;
  opacity: 0.8;
  cursor: pointer;
  z-index: 8060;
}

#fancybox-loading div {
  width: 44px;
  height: 44px;
  background: url(/build/images/fancybox_loading.78a563f0.gif) center center no-repeat;
}

.fancybox-close {
  position: absolute;
  top: -18px;
  right: -18px;
  width: 36px;
  height: 36px;
  cursor: pointer;
  z-index: 8040;
}

.fancybox-nav {
  position: absolute;
  top: 0;
  width: 40%;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
  background: transparent url(/build/images/blank.e5634ce3.gif);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  z-index: 8040;
}

.fancybox-prev {
  left: 0;
}

.fancybox-next {
  right: 0;
}

.fancybox-nav span {
  position: absolute;
  top: 50%;
  width: 36px;
  height: 34px;
  margin-top: -18px;
  cursor: pointer;
  z-index: 8040;
  visibility: hidden;
}

.fancybox-prev span {
  left: 10px;
  background-position: 0 -36px;
}

.fancybox-next span {
  right: 10px;
  background-position: 0 -72px;
}

.fancybox-nav:hover span {
  visibility: visible;
}

.fancybox-tmp {
  position: absolute;
  top: -99999px;
  left: -99999px;
  visibility: hidden;
  max-width: 99999px;
  max-height: 99999px;
  overflow: visible !important;
}

.fancybox-lock {
  /* overflow: hidden !important;*/
  width: auto;
}

.fancybox-lock body {
  overflow: hidden !important;
}

.fancybox-lock-test {
  overflow-y: hidden !important;
}

.fancybox-overlay {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  display: none;
  z-index: 10002;
  background: url(/build/images/fancybox_overlay.f0eb3448.png);
}

.fancybox-overlay-fixed {
  position: fixed;
  bottom: 0;
  right: 0;
}

.fancybox-lock .fancybox-overlay {
  overflow: auto;
  overflow-y: scroll;
}

.fancybox-title {
  visibility: hidden;
  font: normal 13px/20px "Helvetica Neue", Helvetica, Arial, sans-serif;
  position: relative;
  text-shadow: none;
  z-index: 8050;
}

.fancybox-opened .fancybox-title {
  visibility: visible;
}

.fancybox-title-float-wrap {
  position: absolute;
  bottom: 0;
  right: 50%;
  margin-bottom: -35px;
  z-index: 8050;
  text-align: center;
}

.fancybox-title-float-wrap .child {
  display: inline-block;
  margin-right: -100%;
  padding: 2px 20px;
  background: transparent;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 15px;
  text-shadow: 0 1px 2px #222;
  color: #fff;
  font-weight: bold;
  line-height: 24px;
  white-space: nowrap;
}

.fancybox-title-outside-wrap {
  position: relative;
  margin-top: 10px;
  color: #fff;
}

.fancybox-title-inside-wrap {
  padding-top: 10px;
}

.fancybox-title-over-wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #fff;
  padding: 10px;
  background: #000;
  background: rgba(0, 0, 0, .8);
}

/*Retina graphics!*/
@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min--moz-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
  #fancybox-loading, .fancybox-close, .fancybox-prev span, .fancybox-next span {
    background-image: url(/build/images/fancybox_sprite@2x.89fa20c7.png);
    background-size: 44px 152px; /*The size of the normal image, half the size of the hi-res image*/
  }

  #fancybox-loading div {
    background-image: url(/build/images/fancybox_loading@2x.d8f0a972.gif);
    background-size: 24px 24px; /*The size of the normal image, half the size of the hi-res image*/
  }
}

/* - jQuery UI - autocomplete */
.ui-autocomplete {
  position: absolute;
  top: 33px;
  right: 0 !important;
  cursor: default;
  padding: 0;
  margin: 0;
  background: #fff;
  margin-top: 2px;
  box-shadow: 0 0 5px 0 rgba(50, 50, 50, 0.4);
  border-radius: 3px;
  z-index: 1000;
}

.ui-autocomplete li + li {
  margin-top: 0;
}

.ui-autocomplete li.ui-menu-item {
  cursor: pointer;
  padding: 6px 5%;
  line-height: 1.2;
  font-size: 10.5pt;
  transition: all .2s;
}

.ui-autocomplete li._product {
  padding: 3px 3% 3px 3%;
}

.ui-autocomplete li._category {
  padding: 7px 3%;
}

.ui-autocomplete li:last-child {
  border-radius: 0 0 3px 3px;
}

.trust,
.f-nav,
footer .c-google-rating,
.mob-design__top{
  margin: 0 auto;
  max-width: 90vw;
}

.f-nav li:not(.black) a,
footer .c-google-rating span {
  color: #333;
  font-size: 15px;
  line-height: 20px;
}

.black {
  font-size: 16px;
  text-transform: uppercase;
}

.f-nav li:not(.black) {
  padding: 6px 0
}

.trust li:not(:first-child) {
  display: grid;
  grid-template-columns: 30px auto;
}

.ui-autocomplete li:before,
.trust li:before,
.f-icons li:before,
.prod-info > li:before {
  display: none;
}

.blue-block, footer {
  background-color: #edf6fc;
  margin-top: 2em;
  padding: 1.5em 0;
  width: 100vw;
}

.trust li:first-child,
.black a,
._pr-desc-title,
.prod-info .blk_headline,
.load-more,
.click-prod {
  line-height: 24px;
}

.f-icons {
  background-color: #f8fcff;
}

.f-icons li {
  padding: 1em;
}

.ui-autocomplete li:hover {
  background: #ff4f00;
}

footer .c-google-rating {
  padding: 1em 0;
}

.ui-autocomplete li:hover a {
  color: #fff;
}

.ui-autocomplete li + li {
  border-top: 1px solid #ececec
}

.ui-autocomplete a {
  padding: 1% 0;
  text-decoration: none;
  cursor: pointer;
  word-wrap: break-word
}

.ui-autocomplete ._category {
  padding: 0 12px 0 24px;
  position: relative;
}

.ui-autocomplete ._category a,
.search-price {
  font-weight: bold;
}

.filter_seo_block .price_sort {
  color: #000;
}

/* data picker */
.ui-datepicker {
  background: #fff;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.18);
}

.ui-datepicker-header {
  background: #ff4f00;
  color: #fff;
  padding: 10px 30px;
  text-align: center;
  position: relative;
}

.ui-datepicker-title {
  text-align: center;
}

.ui-datepicker-prev,
.ui-datepicker-next {
  color: #fff;
  font-family: 'postroyka';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  text-decoration: none;
  line-height: 1; /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 9px;
  font-size: 20px;
  cursor: pointer;
}

.ui-datepicker-prev {
  left: 10px;
}

.ui-datepicker-next {
  right: -27px;
}

.ui-datepicker-prev:hover,
.ui-datepicker-next:hover {
  color: #fff;
}

.ui-datepicker tr:nth-child(2n-1) {
  background: none;
}

.ui-datepicker thead tr {
  background: #dff0f7 !important;
  text-align: center;
}

.ui-datepicker td {
  border: 1px solid #d4d4d4;
  text-align: center;
  padding: 0;
}

.ui-datepicker td a {
  text-decoration: none;
  color: #000;
  display: block;
  padding: 8px 10px;
}

.ui-datepicker td span {
  text-decoration: none;
  color: #666;
  display: block;
  padding: 8px 10px;
}

.ui-datepicker .ui-datepicker-today a {
  color: #ff4f00;
  font-weight: bold;
}

.ui-datepicker td a.ui-state-active,
.ui-datepicker td a:hover {
  background: #ff4f00;
  color: #fff;
}

.ui-datepicker-prev:before {
  content: "\e1006";
}

.ui-datepicker-next:before {
  content: "\e1007";
}

.ui-datepicker-unselectable {
  background: #eaeaea;
  color: #fff;
}

.ui-helper-hidden-accessible {
  display: none;
}

.ui-datepicker .ui-datepicker-other-month span,
.ui-datepicker .ui-priority-secondary {
  opacity: .3;
}

/* -- fonts & icons -- */
/* - load icons font */
@font-face {
  font-family: 'postroyka';
  src: url(/build/fonts/postroyka.eot);
  src: url(/build/fonts/postroyka.eot#iefix) format("embedded-opentype"), url(/build/fonts/postroyka.woff) format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* - font icons */
[class^="_ico-"], [class*="_ico-"] {
  font-family: 'postroyka';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  text-decoration: none;
  line-height: 1; /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

._ico-location:before {
  content: "\e1000";
}

._ico-menu:before {
  content: "\e1001";
}

._ico-cart:before {
  content: "\e1002";
}

._ico-search:before {
  background: url(/build/images/lupa-2.5496baed.svg) 50% 50% no-repeat;
  content: " ";
  display: block;
  height: 28px;
  width: 30px;
}

._ico-clipboard:before {
  content: "\e1004";
}

._ico-clipboard2:before {
  content: "\e1005";
}

._ico-arr-left:before {
  content: "\e1006";
}

._ico-arr-right:before {
  content: "\e1007";
}

._ico-sales:before {
  content: "\e1008";
}

._ico-seo:before {
  content: "\e1009";
}

._ico-list-default:before {
  content: "\e100a";
}

._ico-list-mini:before {
  content: "\e100b";
}

._ico-plus:before {
  content: "\e100c";
}

._ico-minus:before {
  content: "\e100d";
}

._ico-zoom:before {
  content: "\e100e";
}

._ico-delivery:before {
  content: "\e100f";
}

._ico-close:before {
  position: relative;
  content: "\e1010";
  z-index: 1020;
}

._ico-check:before {
  content: "\e1011";
}

._ico-arr-back:before {
  content: "\e1012";
}

._ico-dots:before {
  content: "\e1013";
}

._ico-edit:before {
  content: "\e1014";
}

._ico-warning:before {
  content: "\e1015";
}

._ico-arr-top:before {
  content: "\e1016";
}

._ico-arr-down:before {
  content: "\e1017";
}

._ico-calendar:before {
  content: "\e1018";
}

._ico-box:before {
  content: "\e1019";
}

._ico-user:before {
  content: "\e101a";
}

._ico-login:before {
  content: "\e101b";
}

._ico-question:before {
  content: "";
}

._ico-question > img {
  width: 16px;
  height: 16px;
  margin-left: 10px;
  cursor: pointer;
}


/* - file icons*/
[class^="file_"]:before {
  content: "";
  display: block;
  background-repeat: no-repeat;
  background-image: url(/build/images/sprite.ae057e2c.svg);
  background-size: auto 42px;
}

.file_default {
  background-position: 0 0;
}

.file_doc:before {
  background-position: 10% 0;
}

.file_xls:before {
  background-position: 20% 0;
}

.file_pdf:before {
  background-position: 30% 0;
}

.file_txt:before {
  background-position: 40% 0;
}

.file_zip:before {
  background-position: 50% 0;
}

.file_rar:before {
  background-position: 60% 0;
}

.file_jpg:before {
  background-position: 70% 0;
}

.file_png:before {
  background-position: 80% 0;
}

.file_gif:before {
  background-position: 90% 0;
}

.svg-ico {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

/* - heading*/
h1, h2, h3, h4, h5, h6 {
  margin: 30px 0 20px;
  font-weight: 700;
  line-height: 1.2;
}

h1 {
  margin-top: 0;
  font-size: 20pt;
  line-height: 1;
}

h2 {
  font-size: 17pt;
}

h3 {
  font-size: 15pt;
}

h4 {
  font-size: 14pt;
}

h5 {
  font-size: 13pt;
}

h6 {
  font-size: 11.5pt;
}

/* - paragraph*/
p {
  margin: 0 0 .95em;
}

p:last-child {
  margin-bottom: 0;
}

/* - blockquote*/
blockquote, q {
  position: relative;
  padding: 0 40px;
  font-size: 1.2em;
  margin: 1em; /* - quotes*/ /* - author*/
}

blockquote:before, blockquote:after, q:before, q:after {
  font-family: 'workspace';
  content: "\e1000";
  position: absolute;
  font-size: 12px;
  opacity: .8;
}

blockquote:before, q:before {
  left: 10px;
  top: -5px;
}

blockquote:after, q:after {
  bottom: -5px;
  margin-left: 15px;
  content: "\e1001";
}

blockquote .author, q .author {
  float: right;
  margin: 25px 25px 0 0;
  font-size: .9em;
}

/* - lists*/
ul, ol, nav {
  list-style: none;
}

ul > li, ol > li {
  position: relative;
}

ul > li:before, ol > li:before {
  position: absolute;
  display: block;
}

ul > li:first-child, ol > li:first-child {
  margin-top: 0;
}

ul ul, ul ol, ol ul, ol ol {
  margin: .8em 0;
  padding-left: 20px;
}

/* - unordered lists */
ul > li {
  margin-top: .6em;
}

ul > li:before {
  content: " ";
  width: 4px;
  height: 4px;
  top: 9px;
  left: -15px;
  border-radius: 50%;
  background: #ff4f00;
}

/* - ordered lists */
ol {
  counter-reset: custom_ol;
}

ol > li {
  margin-top: .6em;
}

ol > li:before {
  counter-increment: custom_ol;
  content: counter(custom_ol) ".";
  left: -20px;
  top: 0;
  color: #ff4f00;
}

/* -- site grid - 12 columns -- */
/* - rows */
[class$="_row"] {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -6px;
}

[class$="_row"] + [class$="_row"] {
  margin-top: 12px;
}

/* - cols */
[class^="col_"] {
  padding: 0 6px;
}

.col_0 {
  width: auto;
}

.col_1 {
  width: 8.33333333%;
}

.col_2 {
  width: 16.6666666%;
}

.col_3 {
  width: 25%;
}

.col_4 {
  width: 33.3333333%;
}

.col_5 {
  width: 41.66666666666667%;
}

.col_6 {
  width: 50%;
}

.col_7 {
  width: 58.33333333333333%;
}

.col_8 {
  width: 66.6666666%;
}

.col_9 {
  width: 75%;
}

.col_10 {
  width: 83.3333333%;
}

.col_11 {
  width: 91.66666666666667%;
}

.col_12 {
  width: 100%;
}

/* - kind of columns */
/* - full width col */
.col_full {
  flex: 1;
}

/* - kind of columns */
/* - large margin row */
.large_row {
  margin: 0 -12px;
}

.large_row > [class^="col_"] {
  padding: 0 12px;
}

/* - medium margin row */
.medium_row {
  margin: 0 -8px;
}

.medium_row > [class^="col_"] {
  padding: 0 8px;
}

/* - small margin row */
.small_row {
  margin: 0 -6px;
}

.small_row > [class^="col_"] {
  padding: 0 6px;
}

/* Desktops and laptops ----------- */
@media (min-width: 1024px) {
  [class$="_row"] {
    margin: 0 -12px;
  }

  [class$="_row"] + [class$="_row"] {
    margin-top: 24px;
  }

  [class^="col_"] {
    padding: 0 12px;
  }

  .small_row {
    margin: 0 -6px
  }

  .small_row > [class^="col_"] {
    padding: 0 6px
  }

  .product_section .related-catalog_blk div.blk_headline {
    margin-bottom: 30px;
  }


}

@media (max-width: 1023px) {
  .product_section .tabs_blk,
  .product_section .features_blk {
    padding-top: 1em;
  }

  .product_section .related-catalog_blk div.blk_headline {
    margin-bottom: 10px;
  }
}

@media (min-width: 600px) and (max-width: 1023px) {
  #js-mobile-slider {
    max-height: 218px !important;
  }
}

/* -- base template setting -- */
html {
  font-size: 100%;
}

html, body, .main_wrapper {
  min-height: 100%;
}

body {
  background: #fff;
  /* background: url('../images/ny-bg.jpg') 0 0 repeat;*/ /* Happy new year! No delete, just comment */
}

.main_wrapper {
  display: flex;
  flex-direction: column;
  font: 400 0.875em/1.4 'PT Sans', sans-serif;
  min-width: 296px;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  background: #fff;
  color: #000;
}

/* - layout */
._layout {
  display: flex;
}

._layout ._sidebar {
  flex: 0 0 240px;
  display: none
}

._layout .f-width {
  flex: 1;
  width: calc(100% - 264px);
}

/* - inside markup in layout */
.inside-markup ._content {
  padding: 0 24px 0 0;
}

/* - container */
._container {
  padding: 0 12px;
}

/* - overlays */
/* - function overlay*/
.c-overlay {
  position: fixed;
  z-index: 100;
  width: 100%;
  height: 100%;
  display: none;
  top: 0;
  left: 0;
}

.function_overlay {
  cursor: pointer;
}

.popup_overlay{
  background: rgba(0, 0, 0, 0.55);
  z-index: 10000;
}

/* spinner overlay */
.spinner_overlay {
  z-index: 10000;
  background: rgba(255, 255, 255, 0.4);
}

.spinner_overlay .spinner {
  width: 50px;
  height: 50px;
  background: url(/build/images/spinner.1260ac49.gif) 0 0 no-repeat;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -25px 0 0 -25px;
}

/* - links*/
a {
  color: #ff4f00;
  transition: all 0.2s ease-out;
}

a:hover {
  color: #ff4f00;
}

/* - images*/
img {
  max-width: 100%;
}

/* - images for figure*/
.style-caption_img {
  margin: 0;
  position: relative;
}

.style-caption_img img {
  display: block;
}

.style-caption_img figcaption {
  position: absolute;
  bottom: 0;
  left: 0;
  text-transform: uppercase;
  color: #fff;
  padding: 10px;
  opacity: .7;
}

/* - button*/
[class$="_btn"] {
  padding: 8px 12px;
  border-radius: 3px;
  border: 2px solid #ff4f00;
  color: #000;
  line-height: 1;
  display: inline-block;
  text-decoration: none;
  background: transparent;
  transition: all 0.15s ease-in-out;
  cursor: pointer;
}

[class$="_btn"] [class^="_ico-"] {
  color: #ff4f00;
  margin-right: 6px;
  font-size: 11.5pt;
  vertical-align: -1px;
}

[class$="_btn"]:hover {
  background: #ff4f00;
  color: #fff;
}

[class$="_btn"]:hover .svg-feed {
  fill: white;
}

[class$="_btn"]:hover [class^="_ico-"] {
  color: #fff;
}

/* - orange gradient buttons */
.orange_btn {
  background: #ff4f00;
  padding: 10px 24px;
  border: none;
  color: #fff;
  font-size: 11pt;
  transition: none;
}

.orange_btn [class^="_ico-"] {
  color: #fff;
  font-size: 12pt;
}

.orange_btn:hover,
.product-popup {
  background: #e64d09;
}

.orange_btn:hover {
  background: #e64d09;
}

/*adamfixcommitdelete*/

.orange_btn:active {
  background: #ff4f00;
  box-shadow: inset 0 0 10px rgba(50, 50, 50, 0.1);
}

/* - full width buttons */
.full-width_btn {
  text-align: center;
  display: block;
  padding: 10px 0;
  font-size: 15px;
  line-height: 1.3;
}

.svg-feed {
  margin-right: 10px;
}

/* -- forms -- */
form, input, textarea, select, .control-row {
  width: 100%;
}

form {
  max-width: 630px
}

/* - default form */
input, textarea, select {
  padding: 6px 10px 5px;
  box-shadow: inset 1px 2px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid #d7d9da;
  border-radius: 4px;
  transition: all 0.2s;
}

textarea {
  resize: none;
}

select {
  padding-right: 15px;
  -webkit-appearance: none;
  background: #fff url(/build/images/arrow-select.815b6182.svg) calc(100% - 10px) 50% no-repeat;
  -moz-appearance: none;
  text-indent: 0.01px;
  text-overflow: '';
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
}


.control-label label {
  font-weight: bold;
  line-height: 1;
  font-size: 11pt;
  margin: 0;
}

input[type=checkbox], input[type=radio] {
  width: auto;
  cursor: pointer;
  border: none;
}

input[type=checkbox] + label, input[type=radio] + label {
  cursor: pointer;
}

input[type=file] {
  padding: 0;
  border: none;
  box-shadow: none;
}

/* - positioning*/
.control-row {
  line-height: 1;
  flex-wrap: wrap;
}

.row_wrapper, .control-row + .control-row, .control-submit {
  margin-top: 12px;
}

.control-submit[data-is-mobile="1"] {
  margin-top: 12px;
}

.control-label {
  line-height: 1;
  margin-bottom: 12px;
}

.control-widget {
  position: relative;
  flex: 1;
}

.control-widget label {
  font-weight: normal;
  font-size: inherit;
  vertical-align: 2px;
}

.control-widget ul {
  list-style: none;
  margin: 0;
  padding: 0;
  line-height: 1.1;
}

.control-widget ul li:before {
  display: none;
}

.control-widget ul li {
  color: #c62826;
  font-size: 9.5pt
}

.control-widget ul li {
  margin-top: 5px;
}

.widget_child {
  line-height: 1;
}

.widget_child + .widget_child {
  margin-top: 12px;
}

.control-submit [class$="_btn"] {
  margin-bottom: 12px;
  margin-right: 12px;
}

/*.control-submit [class$="_btn"] + [class$="_btn"],*/
/*.control-submit .link {*/
/*margin-top: 12px;*/

/*}*/

.control-submit .text {
  display: inline-block;
  color: #000;
}

.control-submit [class$="_btn"] + [class$="_btn"] {
  max-width: 152px;
  text-align: center;
}

.control-submit .link:hover {
  color: #ff4f00;
}

/* - column direction form */
.column-form .control-row {
  display: block;
}

.column-form .control-row + .control-row {
  margin-top: 24px;
}

.column-form .control-widget .control-label {
  width: 100%;
}

.column-form .control-label {
  margin-bottom: 8px;
}

/* - form additionally elements */
/* - form headline */
.form_headline {
  margin: 0 0 48px;
  line-height: 1;
}

/* - field description */
/* - "required" elements */
.control-required {
  position: relative;
  margin-left: 3px;
  top: -2px;
  color: #ff1a00;
}

input[type=checkbox]:not(checked), input[type=radio]:not(checked) {
  /*display: none;*/
  /*opacity: 0;*/
  position: absolute;
}

input[type=checkbox]:not(checked) + label, input[type=radio]:not(checked) + label {
  position: relative;
  margin: 2px 88px 0 0;
  display: inline-block;
  line-height: 1;
}

input[type=checkbox]:not(checked) + label:before, input[type=checkbox]:not(checked) + label:after, input[type=radio]:not(checked) + label:before, input[type=radio]:not(checked) + label:after {
  content: "";
  display: inline-block;
  cursor: inherit;
  margin-right: 6px;
}

input[type=checkbox]:not(checked) + label:before, input[type=radio]:not(checked) + label:before {
  width: 20px;
  height: 20px;
  border: 1px solid #d7d9da;
  border-radius: 3px;
  box-shadow: inset 1px 2px 3px rgba(0, 0, 0, 0.1);
  left: 0;
  vertical-align: -4px;
}

input[type=checkbox]:not(checked) + label:after {
  font-family: 'postroyka';
  content: "\e1011";
  width: 8px;
  height: 8px;
  left: 5px;
  top: 7px;
  transition: all 0.2s;
  opacity: 0;
  font-size: 8px;
  color: #ff4f00;
  position: absolute;
}

input[type=radio]:not(checked) + label:before, input[type=radio]:not(checked) + label:after {
  border-radius: 50%;
}

input[type=checkbox]:checked + label:after, input[type=radio]:checked + label:after {
  opacity: 1;
}

input[type=checkbox]:disabled + label, input[type=radio]:disabled + label {
  cursor: default;
  color: #000;
  opacity: .6;
}

input[type=checkbox]:disabled + label:before, input[type=radio]:disabled + label:before {
  border-color: #000;
  opacity: inherit;
}

.control-description {
  margin-top: 8px;
  color: #999;
}

/* - form fields wrapper */
._form-fields-wrap {
  padding: 24px;
  border: 1px solid #d7d9da;
  max-width: 700px
}

/* - form fielset */
._form-fieldset + ._form-fieldset {
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid #d7d9da;
}

/* - tables*/
table {
  width: 100%;
}

th, td {
  padding: 8px 12px;
  text-align: left;
}

tr:nth-child(2n-1) {
  background: #dff0f7;
}

th {
  font-weight: normal;
  color: #666;
}

td {
  border-left: 1px solid #fff;
}

/* - custom table */
._table-custom {
  border: 1px solid #d7d9da;
  border-collapse: collapse;
  outline: 1px solid white;
  outline-offset: -1px;
}

._table-custom thead th {
  background: #dff0f7;
}

._table-custom tr:nth-child(2n-1) {
  background: transparent;
}

._table-custom tbody tr:hover {
  background: #f3fafc;
  cursor: pointer;
}

._table-custom th, ._table-custom td {
  text-align: center;
  border: 2px solid #D9D9D9;
  min-width: 100px;
}

._table-custom .image {
  text-align: center;
}

._table-custom .image .img {
  width: 90px;
  height: auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

._table-custom .image img {
  max-width: 100%;
}

/* == components == */
/* - logotype*/
._logo {
  display: block;
  line-height: 1;
  font-size: 11.5pt;
  font-weight: 500;
  text-decoration: none;
  color: #000;
  margin-top: -9px; /* Happy new year! No delete, just comment */
  text-align: left;
}

._logo img {
  width: 199px;
}

._logo .text {
  margin-top: 2px;
  display: none;
}

._logo:hover {
  color: inherit;
}

/* - copyright*/
._copyright {
  width: 200px;
  height: 90px;
  background: url(/build/images/copyright.f0309683.svg) 0 0 no-repeat;
}

._copyright .text {
  padding: 29px 0 0 80px;
  line-height: 1.2;
}

/* - itmedia*/
._itmedia {
  font-weight: 400;
  font-size: 14px;
  line-height: 18.667px;
}

._itmedia a {
  color: #949899;
  text-decoration: none;
  line-height: 18.667px;
}

._itmedia a:hover {
  color: #000;
}

/* - files*/
._files {
}

._files li {
  min-height: 42px;
}

._files li:before {
  display: none
}

._files li + li {
  margin-top: 12px
}

._files a {
  position: relative;
  display: block;
  padding: 5px 0 0 40px;
  line-height: 1;
  min-height: inherit;
  text-decoration: none;
}

._files a:before {
  position: absolute;
  width: 30px;
  height: 42px;
  left: 0;
  top: 0;
}

._files a span {
  display: block;
}

._files .size {
  margin-top: 3px;
  color: gray
}

._y-map {
  height: 400px;
  max-width: 760px;
  margin: 24px 0;
}

._y-map b {
  display: none;
}

/* -- navigation -- */
[class$="_nav"] {
  list-style: none;
  margin: 0;
  padding: 0;
  line-height: 1;
  display: flex;

}

[class$="_nav"] li,
.f-icons {
  margin: 0;
  padding: 0;
}

[class$="_nav"] li + li {
  margin-left: 24px;
}

[class$="_nav"] li:before {
  display: none;
  position: static;
  background: none;
  width: auto;
  height: auto;
}

[class$="_nav"] a,
.close-w {
  display: block;
  text-decoration: none;
}

/* - vertical navigation*/
.vertical_nav {
  display: block;
}

.vertical_nav li + li {
  margin-left: 0;
  margin-top: 12px;
}

/* - breadcrumbs navigation */
.breadcrumbs_nav {
  display: none
}

.breadcrumbs_nav li + li {
  margin-left: 0;
}

.breadcrumbs_nav li + li:before {
  font-family: 'postroyka';
  content: "\e1007";
  display: inline-block;
  margin: 0 6px;
  color: #ff4f00;
  font-size: 8px;
}

.breadcrumbs_nav a {
  display: inline-block;
  color: #666;
  text-decoration: underline;
}

.breadcrumbs_nav a:hover {
  color: #000;
}

/* - main navigation */
.main_nav li + li {
  margin-left: 0;
}

.main_nav li + li a {
  border-left: 1px solid #fa8e59;
  box-shadow: -1px 0 0 0 #e55723;
}

.main_nav a {
  color: #fff;
  font-size: 1.3em;
  padding: 0 10px;
  height: 40px;
  line-height: 40px;
  transition: none;
  font-weight: bold;
}

.main_nav a:hover,
.main_nav .sel a,
.main_nav a:active {
  background: #e64d09;
}

.main_nav li:first-child a {
  border-radius: 3px 0 0 3px;
}

/* - categories navigation */
.categories_nav {
  position: relative;
  z-index: 120;
}

.categories_nav li + li {
  margin-top: 0;
  border-top: 1px solid #d7d9da;
}

.categories_nav > li {
  display: block;
  position: relative;
}

.categories_nav a {
  font-size: 12pt;
  color: #000;
  padding: 10px 18px 10px 24px;
  transition: all 0.1s;
}

.categories_nav span {
  font-family: 'postroyka';
  content: "\e1007";
  font-size: 12px;
  font-weight: 700;
  position: absolute;
  right: 8px;
  top: 13px;
  /*width: 0;*/
  /*height: 0;*/
  /*content: "";*/
  /*display: block;*/
  /*border-style: solid;*/
  /*border-width: 5px 0 5px 5px;*/
  /*border-color: transparent transparent transparent #f66725;*/
  transition: all 0.1s;
  display: none;
  cursor: pointer;
}

.categories_nav a:hover {
  background: #ff4f00;
  color: #fff;
}

.categories_nav a:hover + span {
  color: #fff;
}

.categories_nav .subnav,
.categories_nav .subsubnav {
  display: none;
}

.categories_nav .subnav ul,
.categories_nav .subsubnav ul {
  padding: 0;
  margin: 0;
}

.categories_nav .subnav ul {
  background: #bed6e0;
}

.categories_nav .subsubnav ul {
  background: #8cbfd4;
}

.categories_nav .subnav a,
.categories_nav .subsubnav a {
  padding: 8px 18px 8px 40px;
  position: relative;
  font-size: 16px;
  line-height: 16px;
}

.categories_nav .subsubnav a {
  padding-left: 4em;
}

.page_headline.new_page h1 {
  color: #000000;
}

.categories_nav .hover {
  position: relative;
}

.categories_nav .hover .subnav,
.categories_nav .hover .subsubnav {
  display: block;
  position: absolute;
  width: 240px;
  right: -230px;
  top: 2px;
  z-index: 50;
}

.categories_nav .hover .subnav ul,
.categories_nav .hover .subsubnav ul {
  padding: 0;
  background: #fff;
  border: 1px solid #d7d9da;
  margin: 0 0 0 20px;
  position: relative;
}

.categories_nav .hover .subnav ul:before,
.categories_nav .hover .subnav ul:after,
.categories_nav .hover .subsubnav ul:before,
.categories_nav .hover .subsubnav ul:after {
  position: absolute;
  left: -8px;
  top: 9px;
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
}

.categories_nav .hover .subnav ul:before,
.categories_nav .hover .subsubnav ul:before {
  border-width: 8px 8px 8px 0;
  border-color: transparent #d7d9da transparent transparent;
}

.categories_nav .hover .subnav ul:after,
.categories_nav .hover .subsubnav ul:after {
  top: 10px;
  left: -6px;
  border-width: 7px 7px 7px 0;
  border-color: transparent #fff transparent transparent;
}

.categories_nav .hover .subnav a,
.categories_nav .hover .subsubnav a {
  padding: 8px 18px;
}

.categories_nav .sel .subnav,
.categories_nav .sel .subsubnav,
.categories_nav .subnav li:not(.sel):hover .subsubnav,
.categories_nav .subnav li.selsub-active:not(.sel) .subsubnav,
.categories_nav .subnav li.selsub-active:not(.sel):hover .subsubnav .third-level li,
.categories_nav .subnav li.selsub-active:not(.close) .subsubnav {
  display: block;
}

.categories_nav .subsubnav li.selsub-active:hover .subsubnav,
.categories_nav li:not(.sel) .subsubnav,
.categories_nav .subnav li:not(.sel) .subsubnav {
  display: none;
}

.categories_nav .sel li,
.prod-info {
  padding: 0;
}

.prod-info > li {
  padding: 0 1em .5em 1em;
}

/*.categories_nav .sel li + li {*/
/*border: none;*/
/*}*/

.categories_nav .sel li:first-child {
  border-top: 1px solid #d7d9da;
}

.categories_nav .sel > a {
  color: #fff;
  background: #ff4f00;
}

.categories_nav .subnav a:hover,
.categories_nav .subsubnav .sel > a,
.categories_nav .subsubnav a:hover {
  background: #ff4f00;
  color: #fff;
}

.categories_nav .sel > span,
.categories_nav .selsub-active > span {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.categories_nav .close .subnav,
.categories_nav .close .subsubnav,
.categories_nav .subnav ul.second-level li.selsub-active.close .subsubnav {
  display: none;
}

.categories_nav .close > span {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.categories-nav_blk .sub-active > a {
  background: transparent;
  color: #000;
}

/* - sidebar navigation */
.sidebar_nav li + li {
  margin-top: 0;
}

.sidebar_nav a {
  padding: 12px;
  transition: all 0.15s ease-in-out;
  font-size: 11pt;
  border-radius: 3px;
  color: #000;

}

.sidebar_nav a:hover {
  color: #ff4f00;
}

.sidebar_nav .sel a {
  background: #dff0f7;
  border: 1px solid #d7d9da;
  font-weight: 700;
  color: #000;

}

/* - switch products view */
.view-switch_nav {
  margin-right: 12px
}

.view-switch_nav li + li {
  margin-left: 12px;
}

.view-switch_nav li {
  color: #a1a3a9;
  transition: all 0.15s ease-in-out;
}

.view-switch_nav span {
  display: block;
  width: 25px;
  height: 25px;
  line-height: 24px;
  border: 1px solid #a1a3a9;
  font-size: 13px;
  text-align: center;
  cursor: pointer;
  background: #fff;
}

.view-switch_nav ._ico-list-mini {
  font-size: 11px;
  line-height: 25px;
}

.view-switch_nav li .sel, .view-switch_nav li:hover {
  color: #4c4d50;
}

/* - pagination */
.pagination_nav {
}

.pagination_nav li + li {
  margin: 0
}

.pagination_nav li > * {
  display: inline-block;
  height: 24px;
  line-height: 24px;
  text-decoration: none;
  padding: 0 8px;
  color: #000;
}

.pagination_nav li > a:hover {
  color: #ff4f00;
}

.pagination_nav .prev a,
.pagination_nav .next a {
  color: #b5b5b5;
}

.pagination_nav .prev + .next {
  padding: 0;
}

.pagination_nav .prev + .next:before {
  content: "/";
  color: #b5b5b5;
  display: inline-block;
  margin: 0 4px
}

.pagination_nav .sel > *,
.pagination_nav .sel > *:hover {
  background: #ff4f00;
  color: #fff;
  border-radius: 3px;
}

.c-yt-link {
  font-size: 14px;
  font-weight: normal;
  text-align: center;
  color: inherit;
  transition: all .1s;
  display: inline-flex;
  align-items: center;
}

.c-yt-link:hover {
  color: #ff1a00;
}

.c-yt-link:hover ._text,
.ui-autocomplete a.more-cat {
  text-decoration: underline;
}

.c-yt-link ._text {
  margin-right: 6px;
}

.c-yt-link svg {
  width: 24px;
  height: auto;
}

/* -- components -- */
/* - alerts */
.alert {
  width: 100%;
  padding: 7px 0;
  color: #fff;
  font-size: 10pt;
  text-align: center;
  margin-bottom: 12px;
  position: relative;
}

.alert .close {
  /*color: #fff;*/
  /*font-size: 18pt;*/
  /*position: absolute;*/
  /*background: none;*/
  /*border: none;*/
  /*right: 6px;*/
  /*top: 3px;*/
  /*line-height: 1;*/
  display: none;
}

.alert-success {
  background: #25c31b
}

.alert-danger {
  background: #f15a5a
}

.alert-warning {
  background: #ff9f4e
}

.alert-info {
  background: #2d95bf
}

/* - header phone */
._head-phone ul {
  margin: 0;
  padding: 0;
}

._head-phone li:before {
  display: none;
}

._head-phone li + li {
  margin-top: 12px;
}

._head-phone li {
  font-size: 1.23em;
  line-height: 1;
}

._head-phone ._number,
.special-text {
  font-weight: 700;
}

._head-phone a,
.filter_seo_block a,
.f-nav a,
.load-more,
.click-prod,
.filter-set .price_sort {
  text-decoration: none;
}

._head-phone [class^="_opr"] {
  text-indent: -9999px;
  margin-right: 5px;
  width: 15px;
  height: 15px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  display: inline-block;
  position: relative;
  top: 2px;
}

._head-phone a[class^="_opr"] {
  cursor: pointer;
}

.img-list{
  display: flex;
  align-items: center;
  gap: 2.72px;
}

._head-phone ._opr-velcom,
.img-list ._opr-velcom{
  background-image: url(/build/images/velcom.4072f36e.svg);
}

._head-phone ._opr-mts ,
.img-list ._opr-mts{
  background-image: url(/build/images/_mts-new.06097d69.png);
}

._head-phone ._opr-viber {
  background-image: url(/build/images/viber.bfb31a1f.svg);
}

.header_panel ._head-phone .col_6 {
  width: auto;
}

/* - header info */
._head-info {
  line-height: 1;
  font-weight: 700;
  font-size: 1.1em;
}

._head-info .item + .item {
  margin-top: 0px;
}

._head-info .delivery a,
._head-info .location a {
  display: block;
  text-decoration: none;
  color: #f75c17;
}

._head-info .delivery a ._text,
._head-info .location a ._text {
  text-decoration: underline;
}

._head-info .delivery ._ico-delivery {
  font-size: 12px;
  margin-right: 6px;
}

._head-info .location ._ico-location {
  font-size: 14px;
  vertical-align: -1px;
  margin-right: 6px;
}

._head-info .delivery a:hover,
._head-info .location a:hover {
  color: #e81800;
}

/* - footer address */
._foot-address a {
  display: block;
  margin-top: 12px;
  line-height: 1;
}

/* - features */
._features ._row,
.f-icons li {
  margin: 0;
}

._features ._row + ._row {
  border-top: 1px solid #d7d9da;
}

._features [class^="col_"] {
  padding: 6px 0;
  line-height: 1.2;
}

._features .title {
  width: 150px;
  color: #000;
  /*color: #a1a3a9;*/
}

/* - counter */
._counter,
.f-icons {
  display: flex;
  justify-content: center;
}

._counter [class^="_ico"] {
  display: block;
  width: 25px;
  height: 25px;
  line-height: 25px;
  font-size: 12px;
  text-align: center;
  background: #dff0f7;
  border-radius: 4px;
  color: #ff4f00;
  transition: all 0.2s;
  cursor: pointer;
  border: 1px solid #bccdd4;;

}

._counter [class^="_ico"]:hover {
  color: #ff1a00;
}

._counter input {
  margin: 0 7px;
  padding: 0;
  text-align: center;
  width: 28px;
}

._gallery ._row {
  margin-top: -24px
}

._gallery .col_ {
  margin-top: 24px;
  width: 20%
}

/* - products */
.sub-categories_blk .item,
[class$="_product"],
.adv_block {
  position: relative;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.18);
  border: 1px solid #d7d9da;
  line-height: 1.3;
  transition: all 0.2s;
}

.adv_block_mob {
  position: relative;
  border: 1px solid #d7d9da;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.18);
  line-height: 1.3;
  transition: all 0.2s;
}

.supershare {
  font-size: 1.3rem;
}

.product_label.supershare {
  font-size: 0.8rem;
  top: 19px;
  left: -25px;
  width: 114px;
}

.product_label {
  position: absolute;
  top: 11px;
  left: -21px;
  z-index: 30;
  width: 90px;
  height: 24px;
  padding: 0 10px;
  font-size: 0.95rem;
  line-height: 23px;
  color: #fff;
  text-align: center;
  transform: rotate(-45deg);
}

.product_label[data-is-mobile="true"][data-type="stock"] {
  left: -25px !important;
}

.product_label[data-is-mobile="false"][data-type="stock"] {
  top: 7px !important;
}

.product_label[data-is-mobile="true"][data-type="novelty"],
.product_label[data-is-mobile="true"][data-type="markdown"] {
  left: -21px !important;
}

.product_label[data-is-mobile="false"][data-type="novelty"],
.product_label[data-is-mobile="false"][data-type="markdown"] {
  left: 4px !important;
}

.product_label[data-type="stock"] {
  background: #fd8310;
  background: -moz-linear-gradient(top, #fd8310 0%, #fa5d17 100%);
  background: -webkit-linear-gradient(top, #fd8310 0%, #fa5d17 100%);
  background: linear-gradient(to bottom, #fd8310 0%, #fa5d17 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fd8310', endColorstr='#fa5d17', GradientType=0);
}

.product_label[data-type="novelty"] {
  font-size: 0.85rem;
  background: #61cdff;
  background: -moz-linear-gradient(top, #61cdff 0%, #54abfd 100%);
  background: -webkit-linear-gradient(top, #61cdff 0%, #54abfd 100%);
  background: linear-gradient(to bottom, #61cdff 0%, #54abfd 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#61cdff', endColorstr='#54abfd', GradientType=0);
}

.product_label[data-type="markdown"] {
  background: #f22605;
  background: -moz-linear-gradient(top, #f22605 0%, #de2204 100%);
  background: -webkit-linear-gradient(top, #f22605 0%, #de2204 100%);
  background: linear-gradient(180deg,#f6e300,#fc9606);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f22605', endColorstr='#de2204', GradientType=0);
}

.product_label[data-type="supershare"] {
  background: #e81800;
  font-size: 0.8rem;
  top: 19px;
  left: -25px;
  width: 114px;
}

.product_label[data-type="sales_leader"] {
  background: #46f436;
  background: -moz-linear-gradient(top, #46f436 0%, #15c904 100%);
  background: -webkit-linear-gradient(top, #46f436 0%, #15c904 100%);
  background: linear-gradient(to bottom, #46f436 0%, #15c904 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#46f436', endColorstr='#15c904', GradientType=0);
  font-size: 0.8rem;
  left: -28px;
  top: 22px;
  width: 124px;
}

.product_label[data-size="big"] {
  top: 29px;
  left: -40px;
  width: 180px;
  height: 40px;
  padding: 0 20px;
  font-size: 1.5rem;
  line-height: 39px;
}

.product_label.sales_leader[data-size="big"] {
  font-size: 1.15rem;
}

[class$="_product"] .item + .item {
  margin-top: 8px;
}

[class$="_product"] .item.with_length {
  margin-top: 46px;
}

[class$="_product"] .image,
.special-text,
.filter-set .price_sort {
  text-align: center;
}

[class$="_product"] .image a,
.special-text {
  display: block;
}

[class$="_product"] .headline,
.filter-set .price_sort {
  color: #000;
  display: block
}

[class$="_product"] .headline:hover {
  color: #ff4f00;
}

[class$="_product"] .price {
  padding-top: 8px;
  border-top: 1px solid #d7d9da;
  min-height: 70px;
}

[class$="_product"] .price .old-price {
  color: #a1a3a9;
  text-decoration: line-through;
}

[class$="_product"] .price .old-price + .basePrice {
  color: #e81800;
}

[class$="_product"] .price .basePrice {
  font-size: 16pt;
}

[class$="_product"] .price .basePrice,
[class$="_product"] .price .basePrice span {
  font-weight: 700;
}

[class$="_product"] .price .basePrice span {
  font-size: 11pt;
}

[class$="_product"] .price .secondPrice {
  font-size: 9.86pt;
}

@media screen and (max-width: 1239px) {
  [class$="_product"] .price .secondPrice {
    font-size: 11pt;
  }
}

@media (max-width: 575px) {
  .product-rev .small_row {
    flex-wrap: initial;
  }
}

[class$="_product"] ._counter {
  justify-content: flex-start;
}

.sub-categories_blk .item:hover,
[class$="_product"]:hover,
.adv_block:hover,
.adv_block_mob:hover {
  border-color: #ff4f00;
  outline: 1px solid #ff4f00;
}

/* - default product*/
.default_product {
  padding: 0 12px;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.default_product .headline {
  font-size: 12pt;
  font-weight: bold;
}

.default_product .mini {
  display: none;
}

.default_product > .small_row > [class^="col_"] {
  padding-top: 12px;
  padding-bottom: 12px;
}

.default_product .col_image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 112px;
  box-sizing: content-box;
  position: relative;
}

.default_product .col_image .image {
  width: 100%;
  max-width: 110px;
}

.default_product .image img {
  max-height: 145px;
}

.sub-categories_blk .image img {
  max-height: 150px;
}

.default_product .col_image .color {
  position: absolute;
  bottom: 12px;
  right: 12px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.default_product .col_summary {
  flex: 1;
  margin-left: 6px;
  padding: 0 3px 0 6px;
}

.default_product .col_cart {
  width: 200px;
  margin-left: 30px;
  padding-left: 30px;
  border-left: 1px solid #d7d9da;
  display: flex;
  flex-direction: column;
}

.default_product .col_cart .price {
  border: none;
  padding: 0;
  margin: 0;
}

.default_product .col_cart ._counter {
  margin-top: 20px;
}

.default_product .send-cart {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
  width:130px;
  text-decoration: none;
  font-size: 15px;
  background: #ff4f00;
  border: none;
  border-radius: 4px;
  box-shadow: none;
  color: #fff;
  font-weight: 400;
  padding: 3.5px 21px 5px;
  cursor: pointer;
}

.default_product .send-cart:hover{
  background: #e64d09;
  border: none;
}

.default_product .send-cart ._ico-cart {
  margin-right: 10px;
  font-size: 16px;
}

.default_product .send-cart:hover {
  color: #fff;
  background: #e64d09;
}

.product_section .summary .msg,
.default_product .msg {
  position: absolute;
  width: 100%;
  bottom: -40px;
  left: 0;
  height: 40px;
  line-height: 40px;
  color: #fff;
  text-align: center;
  background: #ff4f00;
  font-size: 1.1em;
  opacity: 0;
  transition: all .2s;

}

/* - related product */
.related_product {
  box-shadow: none;
  border: none;
  padding: 0;
}

.related_product + .related_product {
  margin-top: 12px;
  padding-top: 12px;
  border-top: 1px solid #d7d9da;
}

.related_product .image {
  margin-bottom: 6px;
}

.related_product .image a {
  display: flex;
  justify-content: center;
}

.related_product .image img {
  max-width: 100%;
}

.related_product .price {
  border: none;
  min-height: inherit;
}

.related_product.related_product > .summary .item + .item {
  margin-top: 2px;
}

.related_product ._ico-cart {
  font-size: 12pt;
  margin-left: 10px;
  vertical-align: -1px;
}

.related_product:hover {
  outline: none;
}

/* Категории статей */
.article-cat_item {
  position: relative;
  cursor: pointer;
}

.article-cat_item:before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 0;
  top: 0;
  transition: all .15s;
}

.article-cat_item:hover:before {
  background: rgba(0, 0, 0, 0.7);
}

.article-cat_item .image,
.article-cat_item .image img {
  display: block;
  width: 100%;
}

.article-cat_item .summary {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
}

.article-cat_item .headline {
  text-decoration: none;
  font-size: 15pt;
  font-weight: 700;
  text-align: center;
}

/* - Превью статьи */
.article_item {
  display: block;
}

.article_item + .article_item {
  margin-top: 24px;
}

.article_item .image,
.article_item .image img {
  display: block;
  width: 100%;
  max-width: 100%;
}

.article_item .summary {
  margin-top: 12px;
}

.article_item .data {
  color: #999;
  margin-bottom: 4px;
}

.article_item .headline {
  display: block;
  font-size: 14pt;
  font-weight: 700;
  line-height: 1.2;
  text-decoration: none;
}

.article_item .short-description {
  margin-top: 6px;
}

/* - catalog */
/*._catalog > ._row,*/
.articles-cat_blk ._row,
.sub-categories_blk ._row {
  margin-top: -12px;
}

.articles-cat_blk ._row > [class^="col_"],
.sub-categories_blk ._row > [class^="col_"],
._catalog > ._row > [class^="col_"] {
  margin-top: 12px;
}

._catalog form {
  max-width: 100%
}

/* - catalog mini product view */
.mini_catalog .col_12 {
  min-width: 286px
}

.mini_catalog .default_product {
  padding: 12px;
  height: 100%
}

.mini_catalog .with_length .default_product {
  padding-bottom: 30px;
}

.mini_catalog .default_product .mini {
  display: block;
}

.item.mini a {
  color: transparent;
}

.mini_catalog .default_product .default {
  display: none;
}

.mini_catalog .default_product > .small_row > [class^="col_"] {
  padding-top: 0;
  padding-bottom: 0;
}

.mini_catalog [class^="col_"] > form,
.mini_catalog .default_product > form {
  height: 100%;
}

.mini_catalog .default_product .col_summary .item:first-child {
  flex: 1;
}

.mini_catalog .default_product .headline {
  text-decoration: none;
  font-size: inherit;
  font-weight: inherit;
}

.mini_catalog .default_product .headline:hover {
  text-decoration: underline;
}

.mini_catalog .default_product .col_cart {
  width: 100%;
  margin: 0;
  padding: 0 0 12px 12px;
  border: none;
  flex-direction: row;
  align-items: flex-end;
  flex-grow: 0;
}


.mini_catalog .default_product ._counter {
  margin-top: 0;
  margin-right: 20px;
}

/* - total */
._total {
  min-width: 340px;
}

._total .warning-message{
  max-width: 340px;
}

._total .item + .item {
  margin-top: 8px;
}

._total .item {
  display: flex;
  line-height: 1;
}

._total .title {
  color: #959393;
}

._total .content-thin {
  font-weight: normal;
  font-size: 12pt;
  text-align: right;
  flex: 1;
}

._total .content {
  font-weight: 400;
  font-size: 12pt;
  text-align: right;
  flex: 1;
}

._total .content.js-cart-total,
._total .content._total-discount,
._total .content._total-old-price{
  font-weight: 700;
}

._total .content._total-full{
  font-weight: 700;
}

._hint {
  padding: 8px;
  min-width: 160px;
  font-size: 11pt;
  background: #ef5a18;
  color: #fff;
  text-align: center;
  border: 1px solid #fff;
}

.big-hint{
  min-width: auto;
  width: 255px;
}

@media(max-width: 768px){
  .ymaps-2-1-79-hint-overlay{
    left: 50px !important;
  }
}

._hint .weight {
  border-top: 1px solid rgba(255, 255, 255, 0.65);
  font-size: 10pt;
  margin-top: 4px;
  padding-top: 4px;
}

/* == blocks == */
/* ----- blocks ----- */
/*article + article, [class$="_blk"] + [class$="_blk"] {*/
/*    margin-top: 12px;*/
/*}*/

/* - block header*/
.blk_headline {
  margin-bottom: 20px;
  line-height: 1;
  font-size: 17pt;
  font-weight: 700;
  margin-top: 20px;
}

.blk_foot {
  margin-top: 24px;
}

/* - block login */
.login_blk {
  display: flex;
  justify-content: flex-end;
  text-align: right;
}

.login_blk a,
.black a {
  color: #000;
  font-weight: bold;
}

.login_blk a:hover {
  color: #ff4f00;
}

/* - block cart */
.cart_blk {
  line-height: 1;
  background: #dff0f7;
  padding: 8px;
  border-radius: 3px;
  display: inline-block;
  margin-top: 12px !important;
}

.cart_blk .cart {
  justify-content: flex-end;
  position: relative;
  padding-left: 56px;
  display: flex;
  text-decoration: none;
  color: #000;
  align-items: center;
  min-height: 30px;
  font-size: 1.1em;
  padding-right: 5px;
}

.cart_blk .cart .title {
  font-weight: 700;
}

.cart_blk .cart:hover,
.cart_blk .cart:hover ._ico-cart {
  color: #ff1a00;
}

.cart_blk .cart .num, .cart_blk .cart ._ico-cart,
.close-w {
  position: absolute;
}

.cart_blk .cart ._ico-cart {
  font-size: 30px;
  color: #ff4f00;
  left: 4px;
}

.cart_blk .cart .num {
  border: 2px solid #000;
  color: #ff4f00;
  font-size: 8.5pt;
  font-weight: 700;
  border-radius: 20px;
  text-align: center;
  left: 12px;
  top: -5px;
  background: #dff0f7;
  padding: 0 3px 1px;
}

.cart_blk .cart .summ {
  font-size: 1em;
}

.cart_blk ._btn {
  padding: 7px;
  font-weight: bold;
}

/* - block main menu */
.main-menu_blk {
  display: none;
  border-radius: 3px;
  padding-right: 12px;
  background-image: linear-gradient(to bottom, #FF4F00 0%, #FF4F00 100%);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.18);
  position: relative;
}

.main-menu_blk ._ico-menu {
  position: absolute;
  left: 14px;
  top: 50%;
  margin-top: -8px;
  font-size: 16px;
  color: #fff;
  z-index: 20;
  display: none
}

/* - block footer */
.footer_blk [class^="col_"] {
  /*   padding-top: 12px;*/
}

.footer_blk [class^="col_"]:first-child {
  padding-top: 0;
}

.footer_blk .vertical_nav a {
  color: #000;
  white-space: nowrap;
}

.footer_blk .vertical_nav a:hover {
  color: #ff4f00;
  text-decoration: underline;
}

/* block company info */
.company-info_blk {
  padding-top: 35px;
  border-top: 1px solid #d7d9da;
  color: #D7D9DA;
  line-height: 120%;
}

/* block sidebar navigation */
.categories-nav_blk {
  background-image: linear-gradient(to bottom, #e1f0f8, #fff);
  padding: 0;
}

.categories-nav_blk.-shadow_default{
  box-shadow: none;
}

.categories-nav_blk .button {
  margin-top: 18px;
  text-align: center;

}

/* - block move slider */
.move-slider_blk {
  height: 365px;
  position: relative;
  border: none;
  display: none
}

.move-slider_blk .navigation {
  transition: opacity .2s;
}

.move-slider_blk .navigation span {
  display: block;
  border-radius: 50%;
  border: 2px solid #ff4f00;
  color: #ff4f00;
  background: #fff;
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 20px;
  line-height: 37px;
  position: absolute;
  top: 50%;
  margin-top: -20px;
  z-index: 30;
  cursor: pointer;
  transition: all 0.15s ease-in-out;
  opacity: 0;
}

.move-slider_blk .navigation span:hover {
  background: #ff4f00;
  color: #fff;
}

.move-slider_blk .navigation ._ico-arr-left {
  left: -20px;
}

.move-slider_blk .navigation ._ico-arr-right {
  right: -20px;
}

.move-slider_blk .hover span {
  opacity: 1;
}

.move-slider_blk .slider_wrap {
  height: inherit;
  overflow: hidden;
  position: relative;
  border: 2px solid #4c4d50;
}

.move-slider_blk .slide {
  background: 0 0 no-repeat;
  height: inherit;
  float: left;
  position: absolute;
  width: 100%;
  color: #000;
  text-decoration: none;
  opacity: 0;
  cursor: pointer;
  z-index: 10;
}

.move-slider_blk .summary {
  position: absolute;
  top: -300px;
  right: 50px;
  width: 400px;
  padding: 24px;
  border-radius: 10px;
  background: rgba(223, 240, 247, 0.7);
  opacity: 0;
}

.move-slider_blk .slide:first-child {
  opacity: 1;
  z-index: 20;
}

.move-slider_blk .slide:first-child .summary {
  top: 65px;
  opacity: 1;

}

.move-slider_blk .item + .item {
  margin-top: 12px;
}

.move-slider_blk .headline {
  font-size: 20pt;
  font-weight: 700;
  line-height: 1.1;
  text-align: center;
}

.move-slider_blk .description {
  line-height: 1.2;
}

.move-slider_blk .price {
  line-height: 1;
  font-size: 12pt;
  padding-top: 12px;
  border-top: 1px solid #d7d9da;
}

.move-slider_blk .price span + span {
  margin-left: 24px;
}

.move-slider_blk .price .basePrice {
  font-weight: 700;
}

.move-slider_blk .orange_btn {
  margin-top: 5px;
}

.move-slider_blk .dots_nav {
  position: absolute;
  bottom: 24px;
  left: 50%;
  z-index: 30;
}

.move-slider_blk .dots_nav li + li {
  margin-left: 12px;
}

.move-slider_blk .dots_nav li {
  width: 12px;
  height: 12px;
  border: 2px solid #ff4f00;
  cursor: pointer;
  border-radius: 50%;
}

.move-slider_blk .dots_nav li.sel, .move-slider_blk .dots_nav li:hover {
  width: 12px;
  height: 12px;
  background: #ff4f00;
  border: none;
}

/* -  block about */
.content_panel ._content .about_blk {
  margin-top: 0;
}

.about_blk {
  padding: 24px;
}

.about_blk .summary .headline {
  font-size: 20pt;
  font-weight: 700;
  line-height: 1.1;
}

.about_blk .summary .description {
  margin: 12px 0;
}

.about_blk .image {
  text-align: center;
  background: url(/build/images/delivery.e722890b.png) 50% 30px no-repeat;
  background-size: contain;
  padding-top: 170px;
  color: #ff1a00;
}

.about_blk .image .headline {
  font-size: 16pt;
  font-weight: 700;
}

.about_blk .image .text {
  font-size: 12pt;
  font-weight: 700;
}

.about_blk .col_5 {
  display: none
}

/* - block top products */
.top-products_blk {
  padding-top: 12px;
}

.top-products_blk .col_ {

}

.top-products_blk .sales {
  color: #ff4f00;
}

.top-products_blk .sales a {
  margin-left: 12px;
  color: #000;
}

.top-products_blk .sales a:hover {
  color: #ff4f00;
}

.top-products_blk .sales ._ico-sales {
  font-size: 11.5pt;
  vertical-align: -1px;
}

/* - block write to us */
.write-to-us_blk {
  padding: 9px 24px 24px;
}

.write-to-us_blk .full-width_btn:not(:first-child) {
  margin-top: 11px;
}

/* - block filter */
.filter_blk {
  border: 1px solid #e9e9e9;
  padding: 7px 12px;
  background: #edf6fc;
  border-radius: 3px;
}

.filter_blk label {
  font-weight: normal;
  font-size: 10pt;
  display: block;
  margin-bottom: 6px
}

.filter_blk select {
  padding: 4px 15px 3px 5px;
}

.filter_blk ._row {
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  -ms-grid-row-align: center;
  align-items: center;
}

.filter_blk .price_sort {
  position: relative;
  padding-right: 20px;
  text-decoration: none;
  margin: 0 5px;
}

.filter_blk .asc:after,
.filter_blk .desc:after {
  font-family: 'postroyka';
  position: absolute;
  font-size: 8px;
  top: 50%;
  right: 0;
  margin-top: -4px;
}

.filter_blk .asc:after {
  content: "\e1016";
}

.filter_blk .desc:after {
  content: "\e1017";
}

/* - block features */
.features_blk ._row {
  margin: 0;
}

.features_blk ._row + ._row {
  margin-top: 0;
}

.features_blk ._row:nth-child(2n-1) {
  background: #dff0f7;
}

.features_blk [class^="col_"] {
  padding-top: 6px;
  padding-bottom: 6px;
}

.features_blk [class^="col_"] + [class^="col_"] {
  border-left: 1px solid #fff;
}

.features_blk .title {
  color: #666;
}

/* - block related products */
.related-products_blk {
  padding: 20px;
}

.related-products_blk .blk_headline {
  font-size: 12pt;
}

/* - block cart list */
.c-cart__grid {

}

.c-cart__grid .grid__head {
  display: none;
}

.c-cart__grid .i-item {
  padding: 18px;
  border: 1px solid #d7d9da;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.c-cart__grid .i-item + .i-item {
  margin-top: 12px;
}

.c-cart__grid .i-item > *:not(.product_label) {
  padding: 6px
}

.c-cart__grid ._headline,
.trust li:first-child,
._pr-desc-title,
.prod-info .blk_headline,
.pr-video h5 {
  font-weight: 700;
  font-size: 17px;
}

.c-cart__grid ._price,
.c-cart__grid ._summ,
.enter-address_pop_up {
  font-weight: 700;
  font-size: 17px;
  color: #000;
}

.c-cart__grid .counted {
  background: none;
  border: none;
  color: #ff4f00;
  display: inline-block;
  padding: 0;
  margin-top: 6px
}

.c-cart__grid .counted:hover {
  color: #ff1a00;
  font-weight: 700;
}

.c-cart__grid ._ico-close {
  font-size: 20px;
  color: #ff4f00;
}

.c-cart__grid ._col-del {
  position: absolute;
  top: 6px;
  right: 6px;
  z-index: 40;
}

@media (min-width: 500px) {
  .c-cart__grid {
    overflow: auto;
    overflow-y: hidden;
  }

  .c-cart__grid > * {
    min-width: 740px;
  }

  .c-cart__grid .grid__head {
    display: block;
    background: #dff0f7;
    color: #666;
  }

  .c-cart__grid .grid__body {
    margin-top: -1px;
  }

  .c-cart__grid .i-item {
    display: flex;
    padding: 0;
  }

  .c-cart__grid .i-item + .i-item {
    margin-top: -1px;
  }

  .c-cart__grid [class^="_col-"] {
    padding: 12px;
    width: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .c-cart__grid .grid__head [class^="_col-"] {
    padding-top: 6px;
    padding-bottom: 6px;
  }

  .c-cart__grid [class^="_col-"] + [class^="_col-"] {
    border-left: 1px solid #d7d9da;
  }

  .c-cart__grid ._col-image {
    width: 155px;
  }

  .c-cart__grid ._col-info {
    flex: 1;
    width: auto;
  }

  .c-cart__grid ._col-num {
    width: 125px;
  }

  .c-cart__grid ._col-del {
    position: static;
  }

  .c-cart__grid ._headline {
    font-weight: inherit;
    font-size: inherit;
  }

  .c-cart__grid ._price,
  .c-cart__grid ._summ {
    font-weight: inherit;
  }

  .c-cart__grid ._price ._title,
  .c-cart__grid ._summ ._title,
  .cart-design {
    display: none;
  }

  .c-cart__grid ._price,
  .c-cart__grid ._summ {
    font-size: inherit;
  }

  .blue-block {
    margin-left: -30px;
  }
}

/* - block checkout setting */
.checkout-setting_blk {
}

.checkout-setting_blk .element {
  max-width: 590px;
}

.checkout-setting_blk .element + .element {
  margin-top: 35px;
}

.checkout-setting_blk .control-label {
  margin-right: 12px;
  padding: 0;
  width: 150px;
}

.checkout-setting_blk .info {
  color: #666;
  font-size: 11pt;
}

.checkout-setting_blk .info + .change {
  margin-top: 12px;
}

.checkout-setting_blk .change .control-row {
  max-width: 350px;
  min-width: 286px;
}

.checkout-setting_blk .summary {
  margin: 12px 0;
}

.checkout-setting_blk .summary div {
  padding: 12px;
  color: #666;
  background: #fafafa;
  border-radius: 5px;
  display: none;
}

.checkout-setting_blk .panel {
  margin-top: 24px;
  display: none;
}

.checkout-setting_blk .panel .col_full {
  margin-top: 18px;
}

.checkout-setting_blk .price {
  display: flex;
  align-items: center;
  min-width: 246px;
  padding: 5px 24px;
  background: #dff0f7;
}

.checkout-setting_blk .price .title {
  min-width: 80px;
  line-height: 1.2;
}

.checkout-setting_blk .price .content {
  font-weight: bold;
  font-size: 12pt;
  text-align: right;
  flex: 1;
}

.checkout-setting_blk .date-row,
.checkout-setting_blk .zone-row {
  margin-bottom: 12px;
}

.checkout-setting_blk .date-row {
  max-width: 540px;
  display: none;
}

.checkout-setting_blk .zone-row select {
  width: 140px;
  display: inline-block;
  margin-right: 12px;
}

.checkout-setting_blk .zone-row .link {
  color: #ff1a00;;
  text-decoration: none;
  border-bottom: 1px dashed #ff1a00;;
  display: inline-block;
  margin-top: 12px;
  font-size: 1.05em;
}

.checkout-setting_blk .zone-row .link:hover {
  color: #ff4f00;
  border-color: #ff4f00;
}

.checkout-setting_blk .unloading {
  display: none;
}

.checkout-setting_blk .unloading .panel .control-label,
.filter-set .right-part {
  width: 100px;
}

.checkout-setting_blk .unloading .panel .control-label.floor-label {
  width: 30px;
}

.checkout-setting_blk .unloading .floor-row input {
  width: 40px;
  margin-right: 12px;
}

.checkout-setting_blk .unloading .floor-row .checkbox {
  margin-left: 10px;
}

.checkout-setting_blk .unloading .floor-row .checkbox input[type=checkbox]:not(checked) + label:before {
  width: 30px;
  height: 30px;
  vertical-align: -10px;
}

.checkout-setting_blk .unloading .floor-row .checkbox input[type=checkbox]:not(checked) + label:after {
  width: 18px;
  height: 18px;
  left: 7px;
  top: 9px;
  font-size: 14px;;
}

.checkout-setting_blk .unloading .floor-row .control-widget,
.f-icons {
  display: flex;
  align-items: center;
}

.checkout-setting_blk .unloading .panel label,
.ui-autocomplete ._category a.more-cat {
  font-weight: normal;
}

.checkout-setting_blk .unloading .panel .col_12 + .col_12 {
  margin-top: 12px;
}

.checkout-setting_blk .unloading .panel .col_12:nth-child(2) .control-label {
  display: none;
}

/* - block checkout report */
.checkout-report_blk {
  display: none;
  margin: 24px 0;
  padding-left: 24px;
}

.checkout-report_blk .col_12 + .col_12 {
  margin-top: 12px;
}

.checkout-report_blk .elem {
  background: #dff0f7;
  padding: 12px;
  background: radial-gradient(182.46% 182.46% at 50% 50%, #f1fefd 0, #fff 100%);
  border: 1px solid #ff4f00;
  border-radius: 5px;
}

.checkout-report_blk .headline {
  font-size: 12pt;
  font-weight: bold;
  line-height: 1;
}

.checkout-report_blk p {
  margin: 8px 0 0 0;
  line-height: 1.2;
}

.checkout-report_blk ._ico-dots {
  display: none;
  font-size: 4px;
  color: #666;
  cursor: pointer;
  transition: all 0.2s;
}

.checkout-report_blk ._ico-dots:hover {
  color: #000;
}

.checkout-report_blk .title {
  width: 135px;
}

.checkout-report_blk .content {
  font-size: 11pt;
}

.checkout-report_blk .order-list {
}

.checkout-report_blk .order-list ul {
  margin: 12px 0
}

.checkout-report_blk .order-list li,
.checkout-report_blk .order-list li:before {
  display: none
}

/* block checkout */
.checkout-address_blk {
  margin-top: 45px !important;
}

l
.checkout-address_blk .blk_headline {
  font-size: 15pt;
  margin-bottom: 18px;
}

.checkout-address_blk .blk_body {
  max-width: 590px;
}

.checkout-address_blk textarea {

}

div.cart-clear_blk.cart-clear_blk {
  text-align: right;
  margin-top: 10px;
}

.cart-clear__popup {
  text-align: center;
}
.cart-clear__popup ._title {
  font-size: 13pt;
  font-weight: bold;
  margin-bottom: 12px;
}

/* - block cart total */
.cart-total_blk {
  padding-top: 20px;
  margin-top: 10px !important;
}

.cart-total_blk ._total {
  float: right;
}

/* - block categories */
.categories_blk {
  margin: -12px;
}

.categories_blk .item,
.categories_blk .item-sizer {
  width: 100%;
}

.sub-categories_blk .headline,
.categories_blk .headline {
  font-size: 12pt;
  text-decoration: none;
  color: #000;
  position: relative;
  padding-left: 15px;
  line-height: 1.2;
  margin-bottom: 13px;
  display: block;
}

.sub-categories_blk .headline {
  font-size: 12pt;
}

.categories_blk .headline:hover,
.categories_blk .headline:hover:after {
  color: #ff1a00;
}

.categories_blk ul {
  padding-left: 18px;
}

.categories_blk ul a {
  color: #4c4d50;
  display: block;
  position: relative;
  padding-left: 12px;
  font-size: 15px;
}

.categories_blk ul a:before {
  position: absolute;
  content: "";
  width: 4px;
  height: 4px;
  top: 7px;
  left: 0;
  border-radius: 50%;
  background: #4c4d50;
  transition: all 0.2s ease-out;
}

.categories_blk ul a:hover {
  color: #000;
}

.categories_blk ul a:hover:before {
  background: #ff4f00;
}

.categories_blk[data-mobile-item] {
  padding: 0;
}

.categories_blk[data-mobile-item] {
  margin-top: 13px;
}

.categories_blk[data-mobile-item] .item {
  position: relative;
  padding: 0 24px 0 13px;
  margin-top: 13px;
}

.categories_blk[data-mobile-item] .item > * {
  padding: 0;
}

.categories_blk[data-mobile-item] .item:before {
  display: none;
}

.categories_blk[data-mobile-item] .headline:after {
  display: none;
}

.categories_blk[data-mobile-item] .trigger {
  position: absolute;
  top: -1px;
  right: 25px;
  transition: all .2s;
}

.categories_blk[data-mobile-item] .trigger:after {
  font-family: 'postroyka';
  content: "\e1007";
  font-size: 18px;
  color: #ff4f00;
}

.categories_blk[data-mobile-item] [data-open] .trigger {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.categories_blk .vertical_nav,
.categories_blk[data-mobile-item] .vertical_nav {
  display: none;
}

.categories_blk[data-mobile-item] .vertical_nav,
.zoneDefined {
  padding-left: 6px;
}

/* .sub-categories_blk */
.sub-categories_blk ._row > [class^="col_"] {
  padding: 0 6px;
}

.sub-categories_blk .item {
  text-align: center;
  padding: 18px;
  min-height: 250px;
}

.sub-categories_blk .image {
  margin-bottom: 18px;
  display: flex;
  justify-content: center;
  align-items: center;

}

.sub-categories_blk .headline {
  margin: 0;
  padding: 0;
}

/* - cabinet blocks general setting */
/* - block user data[cabinet] */
.user-date_blk .blk_head {
  border: 1px solid #d7d9da;
  border-bottom: none;
  background: #dff0f7;
  padding: 12px 24px;
  display: flex;
  justify-content: space-between;
}

.user-date_blk .blk_headline {
  font-size: 13pt;
  margin: 0;
}

.user-date_blk .edit a {
  color: #000;
  margin-right: 8px;
}

.user-date_blk .edit a:hover {
  color: #ff4f00;
}

.user-date_blk .edit ._ico-edit {
  font-size: 16px;
  color: #ff4f00;
  vertical-align: -2px;
}

/* - block success */
.success_blk, .confirm_blk {
  padding: 12px 12px 12px 44px;
  border-radius: 3px;
  max-width: 700px;
  position: relative;
  line-height: 1.1
}

.success_blk [class^="_ico-"], .confirm_blk [class^="_ico-"] {
  font-size: 18px;
  position: absolute;
  top: 12px;
  left: 12px
}

.success_blk {
  color: #357937;
}

.confirm_blk {
  color: #ff4f00;
}

/* - block search */
.search_blk .orange_btn {
  padding: 7px 24px 8px;
}

/* - block order info */
.order-info_blk {
}

.order-info_blk .blk_body {
  margin: 12px 0
}

.order-info_blk .blk_foot {
  margin: 0
}

.order-info_blk .status {
  color: #666;
}

.order-info_blk .status li:before {
  display: none
}

.order-info_blk .status li + li {
  margin-top: 12px
}

.order-info_blk .status [class^="_ico-"] {
  font-size: 12px;
  margin-right: 6px
}

.order-info_blk .additionally span + span {
  margin-left: 12px;
}

.order-info_blk .additionally b {
  margin-right: 6px;
}

.order-info_blk ._total {
  width: 296px;
  margin-top: 12px;
  padding-top: 12px;
  border-top: 1px solid #d7d9da;
}

.order-info_blk ._total .headline {
  font-size: 13pt;
  font-weight: 700;
  margin-bottom: 12px
}

/* - block tabs */
.tabs_blk .tabs_nav li + li,
.trust li,
.prod-info .blk_headline {
  margin: 0;
}

.tabs_blk .tabs_nav li {
  padding: 12px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tabs_blk .tabs_nav svg {
  width: 22px;
  height: 22px;
  fill: #4c4d50;
}

.tabs_blk .tabs_nav span {
  margin-left: 6px;
  font-size: 12pt;
  font-weight: 700;
  color: #4c4d50;
  border-bottom: 1px dashed #4c4d50;
  cursor: pointer;
  transition: all 0.2s ease-out;
  display: none;
}

.tabs_blk .tabs_nav span:hover {
  color: #000;
  border-color: #000;
}

.tabs_blk .tabs_nav .sel {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.18);
  border: 1px solid #d7d9da;
}

.tabs_blk .tabs_nav .sel svg,
.svg-feed {
  fill: #ff4f00;
}

.tabs_blk .tabs_nav .sel:after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 5px;
  z-index: 30;
  left: 0;
  bottom: -1px;
  background: #fff;
}

.tabs_blk .tabs_nav .sel span {
  border-color: transparent;
  color: #000;
  cursor: default;
}

.tabs_blk .blk_body {
  margin-top: -2px;
  background: #fff;
  position: relative;
  z-index: 20;
  padding: 0 !important;
}

.tabs_blk .tab {
  max-width: 100%;
  display: none;
  padding: 12px;
}

.tabs_blk .active {
  display: block;
}

.product-image_blk .col_12 + .col_12 {
  margin-top: 12px
}

.product-image_blk ._counter {
  justify-content: flex-start;
}

._counter [class^="_ico"] {
  width: 28px;
  height: 28px;
  line-height: 28px;
}

@media(min-width: 1025px){
  .product-image_blk ._counter [class^="_ico"]{
    width: 28px;
    height: 36px;
    line-height: 28px;
  }

  .product-image_blk ._counter [class^="_ico"]:before {
    position: relative;
    top: 1px;
  }
}




/* - feedback blk */
.feedback_blk {
}

.feedback_blk textarea {
  height: 150px
}

/* - reviews blk */
.blk_body .tab .reviews_blk {
  margin-top: 24px;
}

.reviews_blk .item {

}

.reviews_blk .item + .item {
  margin-top: 12px;
  padding-top: 12px;
  border-top: 1px solid #e6e8e9;

}

.reviews_blk .item ._author {
  font-weight: 700;
  font-size: 12pt;
}

.reviews_blk .item ._date {
  color: #999;
}

.reviews_blk .item .body {
  margin-top: 6px;
}

.reviews_blk .item .body ._elem + ._elem {
  margin-top: 6px;
}

.review-form_blk {
  padding-bottom: 24px;
  border-bottom: 1px solid #d7d9da;
}

.review-form_blk .blk_headline {
  font-size: 14pt;
  display: inline-block;
  /*border-bottom: 1px dashed #000000;*/
  color: #ff4f00;
  border-bottom: 1px dashed #ff4f00;
  line-height: 1.3;
  margin: 0;
  cursor: pointer;
  transition: all .2s;
}

.review-form_blk .blk_headline:hover {
  color: #ff4f00;
  border-color: #ff4f00;
}

.review-form_blk .blk_headline[data-selected] {
  color: #ff4f00;
  border: none;
}

.review-form_blk .blk_body {
  margin-top: 12px;
  padding-top: 18px !important;
  border-top: 1px solid #d7d9da;

  display: none;
}

.review-form_blk form {
  padding-left: 12px;
  padding-right: 12px;
  max-width: 590px;
}

.review-form_blk textarea {
  height: 150px
}

/* delivery map blk */
.delivery-map_blk {
}

.delivery-map_blk #deliveryMap {
  width: 100%;
}

.delivery-map_blk .blk_headline {
  font-size: 11pt;
}

/* seo catalog */
.seo-catalog_blk {
  padding: 12px 24px;
  background: #dff0f7;
}

.seo-catalog_blk .blk_body {
  margin: -12px -12px 0 -12px;
}

.seo-catalog_blk a {
  border: 1px solid #e9e9e9;
  border-radius: 4px;
  display: inline-block;
  font-size: 13px;
  line-height: 24px;
  margin: 12px 7px 0 0;
  padding: 1px 5px;
  text-decoration: none;
  background: #edf6fb;
  color: #000;
}

.seo-catalog_blk a:hover{
  border: 1px solid #e9e9e9;
  background: #c2d8e4;
  color: black;
}

.seo-catalog_blk a._btn {
  background: #ff4f00;
  border: 1px solid #ff4f00;
  color: white;
  font-size: 13px;
  line-height: 24px;
  padding: 1px 5px;
}

.seo-catalog_blk ._btn {
  padding: 5px 8px;
  border-radius: 3px;
  border: 2px solid #ff4f00;
  color: #fff;
  line-height: 1;
  display: inline-block;
  text-decoration: none;
  background: #f57428;
  transition: all 0.15s ease-in-out;
  cursor: pointer;
}

.body_blk {
  padding-top: 20px;
}


/* -- section -- */
section + section, [class$="_section"] + [class$="_section"] {
  margin-top: 30px;
}

/* - section headline*/
.section_headline {
  font-size: 20pt;
  line-height: 1;
  border-bottom: 1px solid;
  padding-bottom: 15px;
  margin-bottom: 25px;
}

/* - section product */
.product_section .product-image_blk .-shadow_default {
  padding: 12px 12px 24px;
}

.product_section .product-image_blk .image {
  position: relative;
  min-height: 297.9999px;
  max-height: 298px;
  background-size: 80px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.product_section .product-image_blk .image a:not(._ico-zoom) {
  position: relative;
  display: block;
}

.fancybox-wrap .ps-lightbox-color,
.product_section .product-image_blk .image .color {
  position: absolute;
  bottom: -12px;
  right: -12px;
  width: 120px;
  height: 120px;
  border-radius: 50%;
}

.fancybox-wrap .ps-lightbox-color {
  bottom: 0;
  right: 0;
}

.product_section .product-image_blk .image img {
  max-height: 280px;
}

.product_section .product-image_blk ._ico-zoom {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #ff4f00;
  z-index: 1;
}

.product_section .product-image_blk .summary {
  position: relative;
  overflow: hidden;
}

.product_section .summary .item + .item {
  margin-top: 20px;
}

.product_section .summary .small_row {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  -ms-grid-row-align: center;
  align-items: flex-end;
}

.product_section .reference {
  line-height: 1;
  color: #666;
  font-style: italic;
}

.product_section .reference .num {
  font-style: normal;
  color: #000;
}

.product_section .product-image_blk .rating {
  margin: 2ex auto auto 1ex;
}

.product_section .product-image_blk .c-rating-preview ._stars {
  width: 90px;
  height: 15px;
}

.product_section .product-image_blk .c-rating-preview ._active:before {
  width: 90px;
  height: 15px;
}

.product_section .product-image_blk .headline {
  margin: -3px 0 0 0;
  font-size: 14pt;
  line-height: 1.2;
}

.product_section .product-image_blk .price {
  padding: 10px 0px 10px 20px;
  line-height: 1.3;
  background: #dff0f7;
  border-radius: 3px;
  font-size: 11pt;
}

.product_section .product-image_blk .price > *,
.f-icons a,
._pr-desc-title {
  display: inline-block;
}

.product_section .product-image_blk .price .old-price,
.product-rev .old-price {
  display: block;
  color: #a1a3a9;
  text-decoration: line-through;
}

.product_section .product-image_blk .price .base-price {
  font-size: 20pt;
  font-weight: 700;
  margin-right: 12px;
  white-space: nowrap;
}

.product_section .product-image_blk .price .old-price + .base-price,
.product-rev .price div:nth-child(2) {
  color: #e81800;
}

.product_section .product-image_blk .c-rating-preview {
  cursor: pointer;
  margin: -6px;
  padding-left: 0;
}

.product_section .delivery ._ico-delivery {
  display: inline-block;
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  border-radius: 50%;
  background: #dff0f7;
  color: #ff4f00;
  margin-right: 10px;
}

.product_section .delivery a {
  color: #000;
}

.product_section .delivery a:hover {
  color: #ff4f00;
}

.article_section {

}

.article_section .article-info_blk.article-info_blk {
  margin-top: 12px;
  padding-top: 12px;
  border-top: 1px solid #d7d9da;
  line-height: 1;
  color: #999;
}

.article_section .article-info_blk span + span {
  margin-left: 12px;
}

/* - section cart */
.cart_section form {
  max-width: 100%
}

/* checkout section */
.checkout_section form {
  max-width: 100%
}

.checkout_section .control-row {
  max-width: 540px;
  min-width: 246px;
}

.checkout_section .control-description {
  flex: 0 0 100%;
  width: 100%;
}

.checkout_section .delivery_price {
  padding-top: 10px;
}

.checkout_section .tooltip-inner,
.mob-design .c-google-rating {
  text-align: left;
}

/* == panels == */
/* - site layouts */
/* - sidebar */
._sidebar .blk_headline {
  font-size: 13pt;
}

.footer_panel ._head-phone {
  margin-top: -10px;
}

.footer_panel ._head-phone ._row {
  justify-content: center;
}

.footer_panel ._head-phone .col_ {
  width: auto !important;
  margin-top: 0 !important;
  padding-top: 0 !important;
}

.footer_panel ._head-phone li {
  font-size: inherit;
  margin-top: 10px;
  display: block;
}

.footer_panel ._head-phone a {
  color: #000;
}

.footer_panel ._head-phone [class^="_opr"] {
  top: 0;
}

.header_panel {
  padding: 12px 0;
}

.header_panel .large_row {
  flex-wrap: wrap;
}

.header_panel .large_row > [class^="col_"] + [class^="col_"] {
  border-left: 1px solid #d7d9da;
}

.header_panel .col_full [class$="_blk"] + [class$="_blk"] {
  margin-top: 12px;
}

/* - panel navigation */
.navigation_panel {
  position: relative;
  margin-top: 0;
}

/* - panel content*/
.content_panel {
  flex: 1 0 auto;
}

/* - panel footer */
.footer_panel {
  margin-top: 70px;
  padding: 30px 0 30px;
  background: #edf6fb;
  font-size: 11pt;
  font-family: PT Sans,sans-serif;
}

.footer_panel [class$="_blk"] + [class$="_blk"] {
  margin-top: 12px;
}

.footer_panel .col_full {
  display: none
}

.footer_panel .footer-mobil_nav {
  text-align: center;
  margin: 24px 0;
}

.footer_panel .footer-mobil_nav .sel a {
  color: #ff4f00;
}

/* == pages == */
/* -- page general setting -- */
/* - page headline */
.page_headline {
  margin: -3px 0 17px;
}

.page_headline h1 {
  margin: 0;
  line-height: 1;
  display: inline-block;
}


.page_headline .link {
  float: right;
  text-decoration: none;
  color: #ff1a00;
  line-height: 1;
  margin-top: 12px;
}

.page_headline .link [class^="_ico"] {
  font-size: 10px;
  margin-right: 8px;
}

.page_headline .link:hover {
  color: #ff4f00;
}

.page_headline sup {
  font-size: 10.5pt;
  margin-top: 12px;
  display: block;
}

.page_headline sup ._ico-sales {
  font-size: 14px;
  margin: 0 0 0 8px;
  color: #ff4f00;
}

/* == correction == */
/* - align */
.txt-align_center {
  text-align: center;
}

/* - shadow */
.-shadow_default {
  border: 1px solid #d7d9da;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.18);
}

/* - service styles */
.empty_elem {
  color: #bd3329;
  margin: 1em;
}

/* - clear list */
.list_clear {
  list-style: none;
  margin: 0;
  padding: 0;
  line-height: 1;
}

/*force link style*/
.important-link-style {
  color: #ff4f00 !important;
}

.important-link-style:hover {
  color: #ff1a00 !important;
}

.s-text-a-r {
  text-align: right;
}

.s-res-video {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  z-index: 1;

}

.s-res-video video,
.s-res-video iframe,
.s-res-video object,
.s-res-video embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.s-res-video-320,
.s-res-video-320,
.s-res-video-450 {
  max-width: 100%;
}

/* popup */
.c-popup {
  position: fixed;
  width: 480px;
  top: 100px;
  left: 50%;
  margin-left: -240px;
  background: #fff;
  z-index: 10001;
  padding: 0 25px 50px 25px;
  box-shadow: 0 1px 11px 0 rgba(50, 50, 50, 0.59);
  display: none;
}

.c-popup ._ico-close {
  position: absolute;
  font-size: 20px;
  top: -10px;
  right: -10px;
  color: #ff4f00;
  border-radius: 50%;
  background: #fff;
  border: 2px solid #fff;
  padding: 0 0 0 1px;
  cursor: pointer;
  transition: all .2s;
}

.c-popup ._ico-close--feedback:before,
.popup-for-user _ico-close:before{
  content: none;
}

/* Delivery map popup */
.delivery-map_popup {
  width: 90% !important;
  left: 5% !important;
  height: 92vh !important;
  top: 10px !important;
  margin: 0 !important;
}

.delivery-map_popup.fancybox-margin {
  height: 0 !important;
  overflow: hidden;
}

.warning_popup {
  width: 300px;
  margin: 0 0 0 -150px;
  top: 35%;
}

.warning_popup .headline {
  text-align: center;
  color: #ff1a00;
  font-size: 13pt;
  font-weight: bold;
  margin-bottom: 8px;
}

.warning_popup .description {
  line-height: 1.5;
}

.warning_popup .description > * {
  display: inline-block;
}

.mobil-phones_popup {
  width: 90%;
  max-width: 300px;
  margin: 0;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.mobil-phones_popup ul + ul li {
  margin-top: 18px;
}

.mobil-phones_popup ul {
  text-align: left;
  margin-left: 5px;
  font-size: 19px;
}

.mobil-phones_popup li {
  font-size: 1.05em;
  display: inline-block;
}

.mobil-phones_popup a {
  color: inherit;
  text-decoration: underline;
  font-size: 1em;
  font-weight: 700;
  text-decoration-skip-ink: none;
}

.mobil-phones_popup ._head-phone [class^="_opr"] {
  width: 17px;
  height: 17px;
  margin-right: 15px;
  top: -1px;
}

.mobil-phones_popup ._opr-viber {
  margin-left: -8px;
}

.f-rating-control {
  margin-top: 20px !important;
  margin-bottom: 20px;
}

.f-rating-control .control {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: flex-end;
  margin-left: -6px;
}

.f-rating-control ._star {
  width: 18px;
  height: 18px;
  color: #a9a9a9;
  margin-left: 6px;
  transition: all .3s;
  padding: 4px;
  border: 1px solid #d7d9da;
  border-radius: 3px;
  box-sizing: content-box;
}

.f-rating-control svg {
  width: inherit;
  height: inherit;
  cursor: pointer;
}

.f-rating-control ._star[data-checked],
.f-rating-control ._star[data-checked] ~ ._star,
.f-rating-control ._star:hover,
.f-rating-control ._star:hover ~ ._star {
  color: #ff4f00;
  border-color: #ff4f00;
}

.c-rating-preview {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin: -6px -14px -6px -2px;
}

/*.c-rating-preview > * {*/
/*    margin: 6px 0 0 6px;*/
/*}*/

.c-rating-preview ._stars {
  position: relative;
  background-image: url(/build/images/star-off.321749fc.svg);
  background-size: contain;
  height: 14px;
  margin-right: 3px;
  position: relative;
  width: 80px;
  background-repeat: no-repeat;
}

.c-rating-preview ._active {
  height: inherit;
  width: 0;
  background-repeat: no-repeat;
  position: absolute;
  overflow: hidden;
  background-size: cover;
  left: 0;
  top: 0;
}

.c-rating-preview ._active:before {
  position: absolute;
  left: 0;
  content: "";
  background-image: url(/build/images/star-on.ca627615.svg);
  background-size: contain;
  background-repeat: no-repeat;
  width: 80px;
  height: 14px;
}

.c-rating-preview ._num {
  color: #a9a9a9;
  font-size: 8.5pt;
  padding-top: 3px;
}

.calc-result__sort {
  color: #a9a9a9;
  margin: 20px 0;
}

.cart-empty_blk {
  text-align: center;
  max-width: 500px;
  margin: 0 auto;
}

.cart-empty_blk ._image {
  margin-bottom: 12px;
}

.cart-empty_blk .orange_btn {
  margin-top: 12px;
}

/* - mobile elem style */
/* - media style */
.hide320,
.hide768,
.hide1024,
.hide1100,
.hide1180,
.hide1280 {
  display: none
}

/* - head links */
.head-links_mobile {
  position: relative;
  z-index: 1100;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
  background: #ff4f00;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.18);
}

.head-links_mobile a {
  text-decoration: none;
  position: relative;
  padding: 0 15px;
  height: 40px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.head-links_mobile a + a {
  border-left: 1px solid #fa8e59;
  box-shadow: -1px 0 0 0 #e55723;
}

.head-links_mobile a:last-child {
  flex: 1;
  padding: 0 8.5px;
}

.head-links_mobile .num {
  position: static;
  line-height: 1;
  color: #fff;
  margin-left: 6px;
}

.head-links_mobile .cart .text {
  display: none;
}

.head-links_mobile ._catalogBtn {
  font-size: 15pt;
  font-weight: bold;
}

/* -swipe main menu */
.swipe-menu_mobile {
  width: 100%;
  text-align: center;
  top: -12px;
  position: absolute;
  z-index: 1201;
  display: none;
  transition: all .2s
}

.swipe-menu_mobile.active {
  display: block
}

.swipe-menu_mobile li + li {
  margin-top: 0
}

.swipe-menu_mobile a {
  margin: 0;
  background: #ff4f00;
  padding: 12px 24px;
  color: #fff;
}

.swipe-menu_mobile .sel a,
.swipe-menu_mobile a:hover {
  margin: 0;
  background: #ff1a00;
}

/* - profile mobile menu */
.profile-menu_mobile {
}

.profile-menu_mobile .toggle {
  text-align: center;
  color: #ff4f00;
  text-transform: uppercase;
  line-height: 1;
  cursor: pointer;
  margin-bottom: 24px
}

.profile-menu_mobile .toggle:hover {
  text-align: center;
  color: #ff1a00
}

.profile-menu_mobile .menu {
  margin: -12px 0 24px;
  display: none
}

.profile-menu_mobile .menu.active {
  display: block
}

.profile-menu_mobile .sidebar_nav {
  text-align: center;
  padding: 2px 12px;
  border: 1px solid #d7d9da;
}

.profile-menu_mobile a {
  padding: 10px
}

._captch-fieldset .control-widget {
  display: flex;
  align-items: center;
}

/* search */
.c-search-widget {
  position: relative;
}

.c-search-widget input {
  width: 100%;
  border-radius: 5px;
  padding: 0 20px;
  height: 40px;
  border: 1px solid #FF4F00;
  box-shadow: 1px 2px 3px 0 rgba(0, 0, 0, 0.10) inset;
}

.c-search-widget input::placeholder{
  color: #D9D9D9;
  font-size: 16px;
}

.c-search-widget button[type=submit] {
  padding: 0;
  position: absolute;
  background: none;
  border: none;
}

.c-search-widget[data-type="mobile"] {
  text-align: right;
  margin-top: 0;
  z-index: 1202;
}

.c-search-widget[data-type="mobile"] .trigger {
  background: none;
  box-shadow: none;
  border: 1px solid rgba(255, 255, 255, 0.89);
  color: rgba(255, 255, 255, 0.89);
  font-size: 16px;
  margin-top: 5px;
  padding: 6px 6px 5px;
  line-height: 1;
  border-radius: 4px;
  vertical-align: middle;
  transition: all .15s;
}

.c-search-widget[data-type="mobile"] .trigger:hover {
  color: #ff4f00;
  background-color: #fff;
  border-color: #fff;
}

.c-search-widget[data-type="mobile"] form {
  position: absolute;
  width: 280px;
  right: calc(50% - 140px);
  top: 5px;
  background-color: #dff0f7;
  padding: 12px;
  border-radius: 4px;
  box-shadow: 1px 1px 5px 0 rgba(50, 50, 50, .5);
  visibility: hidden;
  opacity: 0;
  text-align: left;
  z-index: 1200;
}

.c-search-widget[data-type="mobile"] form[data-status="visible"] {
  -webkit-animation: searchWidgetFrames ease .3s;
  animation: searchWidgetFrames ease .3s;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.c-search-widget .ui-autocomplete {
  overflow: hidden;
  max-height: 500px;
  overflow-y: visible;
}

.c-search-widget .ui-autocomplete ._total_products {
  background-color: #ff4f00;
  color: #fff;
  text-align: center;
}

.c-search-widget .ui-autocomplete ._total_products a {
  color: #fff;
}

.c-search-widget .ui-autocomplete ._total_products:hover {
  background-color: #ff1a00;
}

.c-search-widget[data-type="mobile"] .ui-autocomplete {
  top: 35px !important;
  left: initial !important;
  right: 0!important;
  width: 343px!important;
}

.c-google-rating {
  margin: 12px 0;
  text-align: center;
}

.pk-mobile-slider {
  margin-top: 5px;
  display: block;
}

.pk-mobile-slider .owl-dots {
  display: none;
}

.pk-mobile-breadcrumbs {
  min-height: 36px;
  padding: 12px 0;
  border-top: 1px solid #d7d9da;
  border-bottom: 1px solid #d7d9da;
  line-height: 1;
  margin-bottom: 12px;
}

.pk-mobile-breadcrumbs a {
  position: relative;
  color: #666;
  display: inline-block;
  padding-left: 16px;
  text-decoration: none;
}

.pk-mobile-c a:before {
  position: absolute;
  top: 2px;
  left: 0;
  font-family: 'postroyka';
  content: "\e1006";
  font-size: 14px;
}

@-webkit-keyframes searchWidgetFrames {
  0% {
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translate(0px, 25px);
    transform: translate(0px, 25px);
  }
  100% {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }
}

@keyframes searchWidgetFrames {
  0% {
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translate(0px, 25px);
    transform: translate(0px, 25px);
  }
  100% {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }
}

._captch-fieldset .captcha_image {
  margin-right: 24px;
}

@media only screen
and (min-width: 500px) {

  .show500 {
    display: none;
  }

  .categories_blk {
    display: block;
  }

  .categories_blk:not([data-mobile-item]) .vertical_nav {
    display: block;
  }

  .categories_blk:not([data-mobile-item]) .headline {
    font-size: 13pt;
    font-weight: bold;
  }

  .categories_blk:not([data-mobile-item]) .headline:after {
    position: absolute;
    top: 4px;
    left: 0;
    font-family: 'postroyka';
    content: "\e1007";
    font-size: 10px;
    color: #ff4f00;
  }

  .categories_blk:not([data-mobile-item]) .item {
    padding: 12px;
  }

  .categories_blk:not([data-mobile-item]) .item:before {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background: #d7d9da;
    margin-bottom: 18px;
  }

  .categories_blk .item:nth-child(1):before, .categories_blk .item:nth-child(2):before, .categories_blk .item:nth-child(3):before {
    display: none;
  }

  .header_panel {
    border-bottom: 1px solid #d7d9da;
  }

  .head-links_mobile ._catalogBtn {
    display: none;
  }

  .head-links_mobile .cart .text {
    display: block;
  }

  .navigation_panel {
    margin-top: 12px;
  }

  .articles-cat_blk .col_12,
  .top-products_blk .col_12 {
    width: 50%;
  }

  ._container {
    padding: 0 30px;
  }

  .filter_blk .col_:first-child {
    flex: 1;
  }

  .filter_blk .item {
    display: flex
  }

  .filter_blk label {
    width: 140px;
    margin: 0 12px 0 0;
    display: block;
    flex: 0 0 120px
  }

  .filter_blk select {
    padding: 4px 15px 3px 5px;
    max-height: 30px;
    min-width: 150px;
  }

  .order-info_blk .status {
    display: flex
  }

  .order-info_blk .status li + li {
    margin: 0 0 0 12px
  }

  .page_headline sup {
    margin-left: 12px;
    display: inline-block;
  }

  .control-submit [class$="_btn"] + [class$="_btn"],
  .control-submit .link {
    margin: 0 0 0 12px;
    display: inline-block;
  }

  .delivery-map_popup {
    width: 400px;
    margin-left: -200px;
  }

  .checkout-setting_blk .unloading .panel .col_12.col_12 {
    margin-top: 0;

  }

  .checkout-setting_blk .unloading .panel .col_12:nth-child(1) {
    max-width: 202px;
  }

  .checkout-setting_blk .unloading .panel .col_12:nth-child(2) {
    padding-top: 4px;
    width: auto;
    flex: 1;
  }

  .checkout-setting_blk .unloading .panel .col_12:nth-child(2) .control-label {
    display: block;
  }

  .checkout-setting_blk .unloading #extraFloor {
    max-width: 100%;
    margin-left: 0;
  }

  .article_item {
    display: flex;
  }

  .article_item .summary {
    margin: 0 0 0 15px;
  }

  .article_item .image,
  .article_item .image img {
    width: 170px;
    max-width: 170px;
  }

  .c-search-widget[data-type="mobile"] form {
    right: 0;
  }

  ._table-custom .image .img {
    width: 110px;
  }

  .categories_blk ul a {
    font-size: inherit;
  }

  .s-res-video-320 {
    max-width: 320px;
  }

  .s-res-video-450 {
    max-width: 450px;
  }

  .checkout-setting_blk .zone-row select {
    width: 240px;
  }
}

@media only screen
and (min-width: 650px) {

  .sub-categories_blk .col_12,
  .mini_catalog .col_12 {
    width: 50%;
  }

  .mini_catalog .default_product .col_summary {
    height: 174px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .mini_catalog .default_product .headline {
    height: 72px;
    overflow: hidden;
  }

  .categories_blk .item,
  .categories_blk .item-sizer {
    width: 50%;
  }

  .delivery-map_popup {
    width: 600px;
    margin-left: -300px;
  }

  .delivery-map_popup #deliveryMap {
    height: 300px;
  }

  .delivery-map_blk .blk_headline {
    font-size: 17pt;
  }

  .cart-feedback_blk .blk_headline {
    font-weight: 300;
  }

  .warning_popup {
    width: 460px;
    margin: 0 0 0 -230px;
  }

  .c-google-rating {
    text-align: left;
  }

  .pk-mobile-slider {
    margin-top: 0;
  }
}

@media only screen
and (max-width: 500px) {

  .article_item + .article_item {
    margin-top: 20px;
    padding-top: 24px;
    border-top: 1px solid #aaa;
  }

  .info__popup {
    width: 280px;
    margin-left: -140px;
    top: 20px;
  }

  .header_panel .large_row {
    display: block;
  }

  .header_panel .large_row > [class^="col_"] + [class^="col_"] {
    border-left: none;
    width: 100%;
  }

  .head-links_mobile {
    justify-content: center;
    margin-top: 7px;
  }

  .filter_blk .selects .control-row {
    display: flex
  }

  .cart-total_blk ._row {
    flex-direction: column-reverse;
  }

  .cart-total_blk ._total {
    float: none;
    margin-bottom: 10px;
  }

  ._total .warning-message{
    max-width: initial;
  }

  .checkout-setting_blk .price {
    display: block;
  }

  .checkout-setting_blk .price > * {
    display: block;
    text-align: left !important;
    margin-top: 5px;

  }

  ._head-phone .col_ {
    width: 100%;
  }

  ._head-phone .col_ + .col_ {
    margin-top: 24px;
  }

}

@media (min-width: 768px) {
  div.empty {
    flex-basis: 18%;
    -webkit-flex-basis: 18%
  }

  div.empty.three {
    flex-basis: 8%;
    -webkit-flex-basis: 8%
  }

  .footer_panel .col_full {
    display: block
  }

  .control-row,
  .delivery .wrapper-delivery_info {
    display: flex;
    align-items: center;
    line-height: 1;
  }

  .control-label {
    line-height: 1;
    width: 30%;
    padding-right: 5%;
    margin-bottom: 0
  }

  .product_section .-shadow_default {
    padding: 20px 24px 24px;
  }

  .product-image_blk .col_12 {
    width: 50%;
  }

  .product-image_blk .col_12 + .col_12 {
    margin-top: 0
  }

  .tabs_blk .tab {
    padding: 24px;
  }

  .checkout-setting_blk .zone-row {
    max-width: 100%;
  }

  .sub-categories_blk .col_12 {
    width: 33.333333%;
  }

  .delivery-map_popup {
    width: 700px;
    margin-left: -350px;
  }

  .delivery-map_popup #deliveryMap {
    height: 350px;
  }

  ._table-custom .image .img {
    width: 130px;
  }

  .s-res-video-640 {
    max-width: 640px;
  }

  ._logo-ny img {
    width: 255px;
    margin-top: -5px;
  }

  ._logo {
    font: 700 11.7pt "Arial", sans-serif;
  }

  ._logo ._text {
    display: block;
  }
}

/* Desktops and laptops ----------- */
@media (min-width: 860px) {
  .cart_section {
    padding-left: 1.5em;
  }

  .header_panel {
    padding: 24px 0 0;
    border: none;
  }

  ._logo .text {
    display: block;
  }

  .main-menu_blk {
    display: block;
  }

  .navigation_panel {
    margin-bottom: 24px;
  }

  .hide768 {
    display: block;
  }

  .show768 {
    display: none;
  }

  ._layout ._sidebar {
    display: block;
  }

  ._layout ._content {
    padding-left: 24px;
  }

  .tabs_blk .tabs_nav li {
    padding: 12px 24px;
  }

  .warning_popup {
    width: 600px;
    margin: 0 0 0 -300px;
    text-align: center;
  }

  .social_nav {
    justify-content: flex-start;
  }

  .social_nav a {
    display: flex;
    align-items: center;
    text-align: left;
  }

  .social_nav ._text {
    margin: 0 12px 0 0;
  }

  .social_nav ._text:after {
    content: ":";
  }

  .auth_blk .blk_foot .control-submit a {
    display: none;
  }
}

@media (min-width: 1050px) {
  .pr-slider.owl-carousel,
  .navigation-thumbs {
    max-width: 327px;
  }
}

/* Desktops and laptops ----------- */
@media only screen
and (min-width: 1024px) {

  .hide1024 {
    display: block;
  }

  .show1024 {
    display: none;
  }

  article + article, [class$="_blk"] + [class$="_blk"] {
    margin-top: 40px;
  }

  .default_product {
    padding: 0 20px;
  }

  .mini_catalog .default_product {
    padding: 12px 16px 30px;
  }

  .articles-cat_blk ._row,
  .sub-categories_blk ._row,
  ._catalog > ._row {
    margin-top: -24px;
  }

  div.sub-categories_blk.delete_margin ._row {
    margin: 0;
  }

  .articles-cat_blk ._row [class^="col_"],
  .sub-categories_blk ._row [class^="col_"],
  ._catalog > ._row > [class^="col_"] {
    margin-top: 24px;
  }

  .move-slider_blk {
    display: block;
  }

  .content_panel ._content .about_blk {
    margin-top: 24px;
  }

  .about_blk .col_5 {
    display: block;
  }

  .articles-cat_blk .col_12,
  .top-products_blk .col_12 {
    width: 33.33333%;
  }

  .mini_catalog .col_12 {
    width: 50%;
  }

  .breadcrumbs_nav {
    margin-bottom: 17px;
    display: flex;
  }

  .filter_blk [class^="col_"]:first-child {
    display: flex;
  }

  .filter_blk .control-row {
    display: block;
  }

  .filter_blk .control-row + .control-row {
    margin: 0 0 0 12px;
  }

  .filter_blk .control-label {
    margin: 0 0 6px;
  }

  .profile_section .col_12 {
    width: 75%;
  }

  .checkout-report_blk .col_12 {
    width: 50%;
  }

  .checkout-report_blk .col_12 + .col_12,
  .wrapper-filter-catalog_blk.price-sort_only.withoutFilter + .products_blk ._catalog.mini_catalog ._row {
    margin-top: 0;
  }

  .delivery-map_popup {
    width: 900px;
    margin-left: -450px;
  }

  .delivery-map_popup #deliveryMap {
    height: 400px;
  }

  .tabs_blk .tabs_nav svg {
    width: 18px;
    height: 18px;
  }

  .tabs_blk .tabs_nav li span {
    display: block;
  }

  .pk-mobile-slider {
    display: none !important;
  }

}

._product.ui-menu-item a, ._category.ui-menu-item a {
  align-items: start;
  display: grid;
  grid-gap: 1em;
  grid-template-columns: 50px 1fr 60px;
}

._category.ui-menu-item a {
  grid-template-columns: 100%;
}

@media (min-width: 1100px) {
  .ui-autocomplete.ui-front.ui-menu.ui-widget.ui-widget-content {
    width: 100% !important;
  }

  .pulse-button {
    height: 28px;
  }

  .hide1100 {
    display: block;
  }

  .show1100 {
    display: none;
  }

  .large_row {
    margin: 0 -24px;
  }

  .large_row > [class^="col_"] {
    padding: 0 22px 0 22px;
  }

  .large_row>.col_full:first-child{
    padding: 0 22px 0 20px;
  }

  .categories_blk .item,
  .categories_blk .item-sizer {
    width: 33.33333%;
  }
}

@media (min-width: 990px) and (max-width: 1049px) {
  .pr-slider.owl-carousel,
  .navigation-thumbs {
    max-width: 300px;
  }
}

@media (min-width: 860px) and (max-width: 989px) {
  .pr-slider.owl-carousel,
  .navigation-thumbs {
    margin: 0 auto;
    max-width: 233px;
  }
}

@media (min-width: 1180px) {
  .product_section ._row > .col_full {
    max-width: 735px;
  }

  .hide1180 {
    display: block;
  }

  .main_nav a {
    padding: 0 26px;
  }

  .main_nav li:first-child a {
    border-radius: 3px 0 0 3px;
  }

  .about_blk .image {
    background-size: auto;
  }

  .delivery-map_popup {
    width: 1000px;
    margin-left: -500px;
  }

  .delivery-map_popup #deliveryMap {
    height: 450px;
  }

  ._head-phone a {
    color: #000;
    cursor: pointer;
  }
}

@media (min-width: 1280px) {
  .water-supply .seo-catalog_blk.withoutFilter a:first-child {
    margin-left: 0;
  }

  .water-supply .seo-catalog_blk.withoutFilter a {
    padding: 0 5px;
  }
}

/* Desktops and laptops ----------- */
@media only screen
and (min-width: 1240px) {
  .hide1280 {
    display: block;
  }

  .cart_blk {
    width: 280px;
  }

  .sub-categories_blk .col_12 {
    width: 25%;
  }

  .mini_catalog .col_12 {
    width: 33.333333%;
  }

  .related_catalog .col_12 {
    width: 50%;
  }

  .sub-categories_blk ._row > [class^="col_"] {
    padding: 0 12px;
  }
}

/* ie removes arrow*/
select::-ms-expand {
  display: none;
  background-color: #fff;
}

/* - webkit normalize select height*/
@supports (-webkit-appearance: none) {
  select {
    max-height: 30px;
  }
}

/* flex table */
._flex-table {
  width: 100%;
  overflow-y: auto;
  _overflow: auto;
}

._flex-table ._table-custom {
  min-width: 700px;
}

/*placeholder*/
::-webkit-input-placeholder,
.filter-flex select {
  color: #AFA6A6;
  opacity: 1;
  transition: opacity 0.3s ease;
  font-weight: 400;

}

::-moz-placeholder { /* Firefox 19+ */
  color: #999;
  opacity: 1;
  transition: opacity 0.3s ease;
}

:-ms-input-placeholder {
  color: #999;
  opacity: 1;
  transition: opacity 0.3s ease;
}

:-moz-placeholder { /* Firefox 18- */
  color: #999;
  opacity: 1;
  transition: opacity 0.3s ease;
}

input[placeholder] {
  text-overflow: ellipsis;
}

input::-moz-placeholder {
  text-overflow: ellipsis;
}

input:-moz-placeholder {
  text-overflow: ellipsis;
}

input:-ms-input-placeholder {
  text-overflow: ellipsis;
}

input:focus::-webkit-input-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
}

input:focus::-moz-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
}

input:focus:-moz-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
}

input:focus:-ms-input-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-break: auto;
  line-height: 1.42857143;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  font-size: 12px;
  opacity: 0;
  filter: alpha(opacity=0);
}

.tooltip.in {
  opacity: 1;
  filter: alpha(opacity=1);
}

.tooltip.top {
  margin-top: -3px;
  padding: 5px 0;
}

.tooltip.right {
  margin-left: 3px;
  padding: 0 5px;
}

.tooltip.bottom {
  margin-top: 3px;
  padding: 5px 0;
}

.tooltip.left {
  margin-left: -3px;
  padding: 0 5px;
}

.tooltip-inner {
  max-width: 200px;
  padding: 5px 10px;
  color: #fff;
  text-align: center;
  background-color: #f57428;
  border-radius: 2px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, .4);
}

.tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.tooltip.top .tooltip-arrow {
  bottom: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: #f57428;
}

.tooltip.top-left .tooltip-arrow {
  bottom: 0;
  right: 5px;
  margin-bottom: -5px;
  border-width: 5px 5px 0;
  border-top-color: #f57428;
}

.tooltip.top-right .tooltip-arrow {
  bottom: 0;
  left: 5px;
  margin-bottom: -5px;
  border-width: 5px 5px 0;
  border-top-color: #f57428;
}

.tooltip.right .tooltip-arrow {
  top: 50%;
  left: 0;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #f57428;
}

.tooltip.left .tooltip-arrow {
  top: 50%;
  right: 0;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
  border-left-color: #f57428;
}

.tooltip.bottom .tooltip-arrow {
  top: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #f57428;
}

.tooltip.bottom-left .tooltip-arrow {
  top: 0;
  right: 5px;
  margin-top: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #f57428;
}

.tooltip.bottom-right .tooltip-arrow {
  top: 0;
  left: 5px;
  margin-top: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #f57428;
}

/* Correction - CSS constants */
.cr-m-t_z {
  margin-top: 0 !important;
}

.cr-t-a_c {
  text-align: center;
}

/* edit page order */
.tooltip.bottom .tooltip-arrow {
  margin-left: 0;
}

.checkout-setting_blk .unloading .checkbox input[type=checkbox]#extraFloor:not(checked) + label:before {
  width: 30px;
  height: 30px;
  vertical-align: -10px;
}

.checkout-setting_blk .unloading .checkbox input[type=checkbox]#extraFloor:not(checked) + label:after {
  width: 18px;
  height: 18px;
  left: 7px;
  top: 9px;
  font-size: 14px;
}

.checkout-setting_blk .unloading .control-widget {
  padding-top: 0 !important;
}

.tooltip-hide {
  visibility: hidden;
}

/* find form on page catalog */
.wrapper-find_form {
  /*background: #f2f2f2;*/
  background: rgb(225, 240, 249);
  background: linear-gradient(to bottom, rgba(225, 240, 249, 1) 2%, rgb(241, 248, 252) 62%);
  margin-top: 20px;
  padding: 10px 25px 15px 25px;
}

.wrapper-find_form .find_form_subtitle {
  margin-bottom: 6px;
  font-size: 12pt;
}

.wrapper-find_form form {
  display: flex;
  max-width: 100%;
  position: relative;
  align-items: center;
}

.wrapper-find_form form > input {
  max-width: 503px;
}

.wrapper-find_form h3 {
  margin-top: 0;
  margin-bottom: 5px;
}

.wrapper-find_form .orange_btn {
  margin: 0 0 0 12px;
}

.wrapper-find_form .control-submit {
  margin-top: 0;
}

.control-submit-cart{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  max-width: 478px;
  padding-left: 24px;
}

.wrapper-find_form .form-msg.error, .wrapper-find_form .form-msg.send {
  margin-left: 10px;
  line-height: 1.2;
}

.wrapper-find_form .form-msg.error {
  color: #f57428;
}

.wrapper-find_form .form-msg.send {
  color: #000;
}

.wrapper-find_form .form-msg.error span, .wrapper-find_form .form-msg.send span {
  color: orange;
}

.wrapper-find_form .ui-autocomplete {
  left: 0 !important;
  right: unset;
  width: 100% !important;
}

.wrapper-find_form .ui-autocomplete {
  overflow: hidden;
  max-height: 500px;
  overflow-y: visible;
}

.wrapper-find_form .ui-autocomplete a {
  padding: 8px 0;
}

/* edit seo group on page catalog */
.seo-catalog_blk {
  padding: 0 12px;
  background: transparent;
}

.seo-catalog_blk.withoutFilter {
  padding-top: 8px;
}

.seo-catalog_blk .blk_body {
  line-height: 1;
}

.filter_blk + .seo-catalog_blk {
  margin-top: 16px;
}

.filter_seo_block {
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
}

.filter_seo_block .right-part {
  border: 1px solid #e9e9e9;
  height: 40px;
  padding: 8px 15px;
  background: #edf6fc;
  border-radius: 3px;
  margin-left: 2px;
  text-align: center;
  box-sizing: border-box;
  margin-bottom: 15px;
}

.price_sort span {
  font-weight: 500;
  padding-right: 0.3em;
}

.price_sort img {
  transition: transform 0.3s ease;
}

.price_sort img.rotate {
  transform: rotate(180deg);
}

.filter_seo_block .left-part {
  width: 100%;
}

/* sticky cart */

@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, -100%, 0);
    transform: translate3d(-100%, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

.sticky_cart {
  display: none;
}

.sticky_cart.cart_visible {
  display: block;
}

.sticky_cart .cart_blk {
  z-index: 100;
  float: right;
  position: fixed;
  right: 4px;
  top: 3px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: all .5s ease;
  margin-top: 0 !important;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.sticky_cart .cart_blk {
  margin-top: 0 !important;
}

/*  info sticky cart  */
.sticky_cart_info {
  position: absolute;
  display: none;
}

.sticky_cart_info.info_visible {
  display: block;
}

.cart_blk.cart-info {
  width: 260px;
  background: #ff4f00;
  font: 400 .875em / 1.4 PT Sans, sans-serif;
  color: white;
  z-index: 10000;
  float: right;
  position: fixed;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: all .5s ease;
  margin-top: 0 !important;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  top: 60px;
  right: 34px;
  padding: 2px 12px;
}

.cart-info--discount{
  display: flex;
  align-items: center;
  gap: 12px;
}

.cart_blk.cart-info--discount{
  padding: 0 11px;
  right: 25px;
  top: 65px;
  border-radius: 4px;
}

.cart-info--discount__num{
  font-weight: 700;
  font-size: 30.6333px;
  line-height: 40px;
}

.cart-info--discount__text{
  font-weight: 700;
  font-size: 13px;
  line-height: 100.9%;
  padding-left: 13px;
  border-left: 1px solid white;
}

.cart-info_close-btn {
  position: absolute;
  cursor: pointer;
  width: 18px;
  height: 18px;
  display: inline-block;
  transition: all ease 0.5s;
  top: -17px;
  right: -17px;
  border: 0.744186px solid #FE5102;
  border-radius: 50%;
}

.cart-info_close-btn:before, .cart-info_close-btn:after {
  position: absolute;
  content: '';
  height: 11px;
  width: 1.5px;
  background-color: #000000;
  top: 2px;
  left: 6.5px;
}

.cart-info_close-btn:before {
  transform: rotate(45deg);
}

.cart-info_close-btn:after {
  transform: rotate(-45deg);
}

.cart-info_close-btn:hover {
  transform: scale(1.1);
}


/* edit page product */
.product_section .summary .item:first-child {
  margin-top: 10px;
}

.product_section .related-products_blk .related_count ._counter,
.wordZone {
  display: inline-flex;
}

.product_section .related-products_blk .related_count {
  margin-top: 7px;
}

.product_section .related-products_blk .related_count a,
.special-text {
  text-decoration: none;
}

.product_section .related-products_blk .related_count ._ico-cart {
  font-size: 15pt;
  margin-left: 15px;
  vertical-align: -3px;
}

/* edit catalog */
.categories_blk ul a:hover,
.load-more,
.click-prod {
  font-weight: bold;
}

[class*="_blk withoutFilter"] + [class$="_blk"] {
  margin-top: 12px;
}

/* edit deliveryMap */
.delivery-map_blk #deliveryMap {
  overflow: hidden;
}

/* edit checkout page order*/
.checkout-setting_blk .element {
  max-width: 798px;
}

.wrapper-address {
  position: relative;
  margin-bottom: 8px;
}

.wrapper-address .control-row {
  max-width: 418px;
}

.wrapper-address .openDeliveryMap {
  border-bottom: 1px dashed #ff1a00;
  display: inline-block;
  color: #ff1a00;
  font-size: 1.05em;
  left: 50%;
  position: relative;
  text-decoration: none;
  transform: translateX(-50%);
}

@media (min-width: 769px) {
  .c-popup.enter-your-zone {
    width: auto;
  }
}

@media (max-width: 768px) {
  .c-popup.enter-your-zone {
    left: 5%;
    margin-left: 0;
    top: 15vh;
    width: 90vw;
  }

  .cart-feedback_popup {
    font-family: 'PT Sans';
    margin: 0;
    max-width: 1024px;
    max-height: 90vh;
    width: calc(100% - 30px);
    top: 50%;
    left: 50%;
    transform: translate(-50% , -50%);
    display: block;
    border-radius: 15px;
    background: linear-gradient(2deg, #F0EFEA 0%, #F5F5F2 57.57%, #FEFEFE 100%);
    padding: 0;
    padding-bottom: 40px;
  }

  .cart-feedback_popup .cart-feedback_blk .logo {
    display: block;
    max-width: 139px;
    margin: 0 auto;
  }

  .cart-feedback_popup .blk_headline {
    font-size: 13pt;
    margin: 10px 0 20px;
    line-height: 1.3;
    font-family: "Roboto";
  }

  .cart-feedback_popup ._ico-number span {
    color: #000;
    font-size: 14px;
    font-family: PT Sans;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    padding: 4.5px 0;
    margin-left: 31px;
  }

  .field-cart-feedback {
    height: 60px;
    margin-bottom: 10px;
    padding: 8px 10px 8px;
  }
}

.c-popup.enter-your-zone .wrapper-address {
  margin-bottom: 0;
}

.c-popup.enter-your-zone .wrapper-delivery_info,
.pr-df {
  flex-direction: column;
}

.pr-df .review-form_blk {
  order: 2;
}

@media all and (min-width: 1130px) {
  .delivery .openDeliveryMap {
    left: 21.5%
  }
}

@media all and (min-width: 860px) and (max-width: 1129px) {
  .delivery .openDeliveryMap {
    left: 39%
  }
}

@media all and (min-width: 768px) and (max-width: 859px) {
  .delivery .openDeliveryMap {
    left: 30%
  }

  .pk-mobile-breadcrumbs.pk-mobile-breadcrumbs a::before {
    top: 4px;
  }

}

.control-row.zone-row {
  display: none;
}

.wrapper-delivery_info {
  display: flex;
  padding-bottom: 0.5em;
}

.checkout-setting_blk .element + .element {
  margin-top: 5px;
}

.checkout-address_blk {
  margin-top: 12px !important;
}

.checkout-setting_blk .panel {
  margin-top: 0;
}

#checkout_unloading .panel {
  margin-top: 10px;
}

.checkout-setting_blk .summary {
  min-height: 1px;
  margin: 0 0 13px;
}

.checkout-setting_blk .unloading .panel {
  max-width: 395px; /*465*/
}

.wrapper-address .control-row, .checkout_section .control-row,
.checkout-address_blk .blk_bod, .checkout-setting_blk .change .control-row,
.checkout-report_blk .elem {
  max-width: 395px;
}


.checkout-setting_blk .unloading .extraFloor-wrapper .checkbox {
  float: left;
}

.checkout-setting_blk .unloading .floor-row .control-widget {
  justify-content: flex-start;
}

.checkout-setting_blk .unloading .floor-row .input-floor {
  margin-right: 28px;
}

.checkout-setting_blk .unloading .floor-row .input-floor label {
  vertical-align: 0;
}

.checkout-setting_blk .unloading .floor-row .checkbox {
  margin-right: 12px;
}

.checkout-setting_blk .unloading .floor-row input {
  width: 30px;
  height: 30px;
  margin-right: 0;
  padding: 6px 6px 5px;
  text-align: center;
}

.checkout-setting_blk .unloading .change,
.pr-df {
  display: flex;
}

.checkout_section .delivery_price {
  padding: 0;
}

.checkout-setting_blk .price {
  display: flex;
  min-width: 0;
  padding: 0 0 4px 10px;
  background: transparent;
  height: 100%;
}

.checkout-setting_blk .price .title {
  min-width: 0;
}

.checkout-setting_blk .price .content {
  font-size: inherit;
  font-weight: normal;
  white-space: nowrap;
}

.checkout .breadcrumbs_nav {
  padding-left: 24px;
}

/* edit  */
.success-title {
  display: block;
  font-weight: 400;
  font-size: 23px;
  line-height: 112.52%;
  margin-bottom: 20px;
}

.success-payment .paymentLinkBtn.orange_btn {
  font-size: 13pt;
  float: left;
  margin-right: 12px;
}

.success-printOrder .printOrder {
  margin: 15px 0;
}

.success-printOrder a.printOrder {
  font-size: 13pt;
}

/* edit search block */
.search_blk {
  justify-content: space-between;
  align-items: center;
  display: flex;
  padding-bottom: 1em;
}

.search_blk ._form-fields-wrap {
  padding: 0;
  border: none;
}

.search_blk ._form-fields-wrap .left-part {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.search_blk .right-part {
  flex: 1;
  text-align: right;
  margin-right: 5px;
  margin-left: 10px;
}

.search_blk .right-part .price_sort .text,
.enter-address_pop_up {
  white-space: nowrap;
}


/* ************* edit mob header - new design 2020 ********** */
.mob-menu_categories.swipe-menu_mobile {
  background: white;
  text-align: left;
  border-bottom: 1px solid #d7d9da;
}

.mob-menu_categories.swipe-menu_mobile.active {
  position: absolute;
  top: 90px;
  left: 0;
  display: block;
  overflow-y: auto;
  max-height: calc(100vh - 80px);
  font: 400 0.875em/1.4 PT Sans,sans-serif;
}

.mob-menu_categories.swipe-menu_mobile ul {
  padding: 0;
  margin: 0;
}

.mob-menu_categories.swipe-menu_mobile a {
  width: 100%;
  display: block;
  background: transparent;
  color: #000;
  text-decoration: none;
  padding-left: 48px;
  font-size: 12pt;
}

.categories_nav_mob-menu li:first-child a{
  color: #FF4F00;
}

.mob-menu_categories.swipe-menu_mobile li:not(:first-child) a{
  border-top: 1px solid #e9e9e9;
}

.mob-menu_categories li.open .mob-menu_categories__arr-icon {
  font-weight: bold;
  color: black;
}

.mob-menu_categories__head-wrapper {
  position: relative;
}

.mob-menu_categories__head-wrapper .mob-menu_categories__head-close-icon {
  position: absolute;
  right: 10px;
  top: 8px;
  cursor: pointer;
}

.mob-menu_categories__head {
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  color: #000;
  font-size: 12pt;
  padding: 7px 10px;
  font-weight: bold;
  line-height: 20px;
  width: 100%;
  cursor: pointer;
}

.mob-menu_categories__head ._ico-arr-right {
  top: -1px;
  position: relative;
  font-size: 10px;
  font-weight: bold;
  margin-left: 5px;
  display: inline;
  color: black;
}

.mob-menu_categories__head.open_nav ._ico-arr-right {
  top: 1px;
}

.categories_nav_mob-menu {
  position: relative;
  z-index: 130;
}

.categories_nav_mob-menu > li {
  display: block;
  position: relative;
}

.categories_nav_mob-menu ul > li:before {
  display: none;
}

.categories_nav_mob-menu a {
  font-size: 16px;
  line-height: 14px;
  color: #000;
  padding: 13.5px 18px 13.5px 24px;
  transition: all 0.1s;
}

.mob-menu_categories.swipe-menu_mobile .categories_nav_mob-menu > li.open > a,
ul.second-level_mob li.open a.group {
  font-weight: bold;
}

.mob-menu_categories__arr-icon {
  width: 100%;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  height: 34px;
  cursor: pointer;
}

.second-level_mob li .mob-menu_categories__arr-icon-level2 {
  height: 50px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.second-level_mob li .mob-menu_categories__arr-icon-level2 span {
  left: 2.75rem;
}

.lonely-li .mob-menu_categories__arr-icon {
  width: 0;
}

.categories_nav_mob-menu span {
  font-family: 'postroyka';
  font-size: 8px;
  font-weight: 700;
  position: absolute;
  right: 15px;
  top: 15px;
  transition: all 0.1s;
  display: none;
  cursor: pointer;
}

.categories_nav_mob-menu span svg{
  height: initial;
}

.categories_nav_mob-menu ._ico-arr-right::before {
  color: rgb(141, 141, 141)
}

.categories_nav_mob-menu .sel > span {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.categories_nav_mob-menu li.hover,
.categories_nav_mob-menu li.open {
  font-weight: bold;
}

.categories_nav_mob-menu li.open .mob-menu_categories__arr-icon > span,
.categories_nav_mob-menu li.hover .mob-menu_categories__arr-icon > span,
.mob-menu_categories__head span._ico-arr-right,
.second-level_mob li.open .mob-menu_categories__arr-icon-level2 span {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.mob-menu_categories .vertical_nav,
.second-level_mob li:not(.open) .subsubnav {
  display: none;
}

.mob-menu_categories .vertical_nav.open_nav {
  display: block;
  min-height: calc(100vh - 80px)
}

.categories_nav_mob-menu .subnav
  /*.categories_nav_mob-menu .subsubnav*/
{
  display: none;
}

.categories_nav_mob-menu .open .subnav,
.categories_nav_mob-menu .hover .subnav,
.table-info {
  display: block;
}

.categories_nav_mob-menu .subnav ul a,
.categories_nav_mob-menu .subsubnav ul a {
  color: #4c4d50;
  font-weight: normal;
}

.categories_nav_mob-menu .subnav ul a {
  padding-left: 60px;
}

.categories_nav_mob-menu .subsubnav ul a {
  padding-left: 5em;
}

.categories_nav_mob-menu .hover .subnav ul:before,
.categories_nav_mob-menu .hover .subsubnav ul:before {
  border-width: 8px 8px 8px 0;
  border-color: transparent #d7d9da transparent transparent;
}

.swipe-menu_mobile .categories_nav_mob-menu li + li {
  margin-top: 0;
}

.head-links_mobile {
  background-image: unset;
  background-color: white;
  box-shadow: none;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 0;
}

.head-links_mobile__column-icon {
  display: flex;
}

.head-links_mobile a {
  height: 50px;
  padding: 0 8.5px;
}

.head-links_mobile .head-links_mobile__column-icon a.next:before {
  background-color: transparent;
}

.head-links_mobile .head-links_mobile__column-icon a:before {
  position: absolute;
  content: '';
  background-color: #e9e9f9;
  width: 1px;
  height: 26px;
  top: 12px;
  left: 1px;
}

.head-links_mobile .head-links_mobile__column-icon a.active:before {
  background-color: transparent;
}
#swipe-menu_btn::before {
  background-color: transparent;
}

.head-links_mobile a._logo {
  padding-left: 0;
}

.head-links_mobile a + a {
  border-left: none;
  box-shadow: none;
}

.head-links_mobile .head-links_mobile__column-icon .cart {
  padding: 0 18px 0 5px;
}

.head-links_mobile .head-links_mobile__column-icon a .num {
  position: absolute;
  right: 12px;
  top: 5px;
  color: #fa5b01;
  font-size: 9pt;
  font-weight: bold;
}

.purchased,
.close-w {
  color: white;
}

.mobil-phones_popup ._head-phone [class^="_opr"] {
  width: 26px;
  height: 26px;
  margin-right: 15px;
  top: -1px;
  border-radius: 5px;
}


.mobil-phones_popup ._head-phone li + li {
  margin-top: 12px;
}

.mobil-phones_popup ._head-phone ._opr-mts {
  margin-left: 30px;
}

.header_panel ._head-phone ._opr-a1, .footer_panel ._head-phone ._opr-a1, ._head-phone ._opr-a1 {
  background-image: url(/build/images/messengerA1_16.01429854.png);
}

.mobil-phones_popup ._head-phone ._opr-a1 {
  background-image: url(/build/images/messengerA1_26_3.211df9cb.png);
}

.mobil-phones_popup ._head-phone li {
  line-height: 1.4;
}

.c-popup.mobil-phones_popup ._ico-close {
  font-size: 24px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

/* page vacancy */
.feedback-vacancy_blk {
  margin-top: 35px;
}

.fl_upld {
  align-items: baseline;
  display: flex;
  height: auto;
  margin-top: 1em;
  justify-content: center;
  width: auto;
}


.fl_inp {
  display: none;
}

#fl_inp,
#fl_inp1,
#vacancy_form_file1,
#director_form_file1,
#director_form_file2,
#director_form_file3 {
  display: none;
}

.fl_upld label {
  background: #ff4f00;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  padding: 5px 10px;
  text-align: center;
}


.fl_upld label:hover {
  background: #e64d09;
}

#fl_nm,
#fl_nm1 {
  color: #8e9091;
  padding-left: 1em;
}

.feedback-vacancy_blk textarea {
  height: 150px;
}

/* edit media */
@media (min-width: 338px) and (max-width: 374px) {
  .checkout-setting_blk .unloading .extraFloor-wrapper .checkbox {
    float: none;
  }

  .checkout-setting_blk .unloading .floor-row .control-widget {
    justify-content: flex-start;
  }

  .checkout-setting_blk .unloading .floor-row .input-floor {
    margin-right: 5px;
  }

  .checkout-setting_blk .unloading .panel .col_flor {
    width: 55%;
  }

  .checkout-setting_blk .unloading .panel .col_extra {
    width: 45%;
  }
}

@media (min-width: 375px) and (max-width: 385px) {
  .checkout-setting_blk .unloading .floor-row .input-floor {
    margin-right: 20px; /*12*/
  }

  .checkout-setting_blk .unloading .floor-row .input-floor label {
    vertical-align: 1px;
  }

  .checkout-setting_blk .unloading .floor-row .checkbox {
    margin-right: 0;
  }

  .checkout-setting_blk .unloading .floor-row .checkbox {
    margin-left: 4px;
  }

  .checkout-setting_blk .unloading .panel .col_flor {
    width: 51%;
  }

  .checkout-setting_blk .unloading .panel .col_extra {
    width: 49%;
    padding-left: 8px;
  }

  .checkout-setting_blk .unloading .panel .control-label.floor-label {
    margin-right: 6px;
  }
}

@media screen and (max-width: 320px) {
  .sub-categories_blk .item {
    min-height: 190px;
  }

  .sub-categories_blk .headline {
    line-height: 1.1;
  }
}

@media (min-width: 321px) and (max-width: 355px) {
  .sub-categories_blk .item {
    min-height: 190px;
  }
}


@media (min-width: 356px) and (max-width: 767px) {
  .sub-categories_blk .item {
    min-height: 220px;
  }
}

@media screen and (max-width: 414px) {
  .head-links_mobile__column-logo img {
    height: auto;
  }

  .checkout-setting_blk .unloading .floor-row .checkbox input[type=checkbox]:not(checked) + label:before,
  .checkout-setting_blk .unloading .panel .control-label.floor-label,
  .checkout-setting_blk .unloading .checkbox input[type=checkbox]#extraFloor:not(checked) + label:before {
    margin-right: 10px;
  }
}

@media (min-width: 386px) and (max-width: 413px) {
  .checkout-setting_blk .unloading .floor-row .input-floor {
    margin-right: 20px; /*12*/
  }

  .checkout-setting_blk .unloading .panel .col_flor {
    width: 53%;
  }

  .checkout-setting_blk .unloading .panel .col_extra {
    width: 46%;
  }

  .wrapper-address .control-row, .checkout_section .control-row,
  .checkout-address_blk .blk_bod, .checkout-setting_blk .change .control-row,
  .checkout-setting_blk .unloading .panel, .checkout-setting_blk .price,
  .checkout-report_blk .elem {
    max-width: 100%;
  }
}

@media (max-width: 500px) {
  .header_panel .col_full.show768 {
    padding-right: 0;
  }

  .download-cart {
    width: 50vw;
    /*margin: 0 auto;*/
    padding-bottom: 3em;
    /*text-align: start;*/
  }
}

@media (max-width: 559px) {
  .wrapper-delivery_info #address {
    flex-basis: calc(100% - 100px);
  }
}

@media (min-width: 414px) and (max-width: 500px) {
  .checkout-setting_blk .unloading .floor-row .input-floor {
    margin-right: 33px; /*30*/
  }

  .checkout-setting_blk .unloading .panel .col_flor {
    width: 55%;
  }

  .checkout-setting_blk .unloading .panel .col_extra {
    width: 44%;
    margin-left: 4px;
  }

  .wrapper-address .control-row, .checkout_section .control-row,
  .checkout-address_blk .blk_bod, .checkout-setting_blk .change .control-row,
  .checkout-setting_blk .unloading .panel, .checkout-setting_blk .price,
  .checkout-report_blk .elem {
    max-width: 100%;
  }


  .head-links_mobile__column-logo img {
    max-width: 167px;
    height: auto;
  }
}

.filter_seo_block.withoutFilter.price-sort_only,
.enter-address_pop_up {
  padding-bottom: 0.5em
}

.filter_seo_block select {
  box-shadow: none;
}

@media (max-width: 649px) {

  .filter-set .price_sort {
    padding: 11px 0
  }

  .product_section .default_product .col_image {
    width: 136px;
  }

  .checkout-setting_blk .unloading .panel .control-row {
    min-width: 0;
  }

  .filter_seo_block div.filter_blk select {
    height: 40px;
    max-height: 40px;
    padding-left: 13px;
    border-radius: 5px;
    border: 1px solid #E9E9E9;
  }

  .filter_blk {
    padding: 12px
  }

  .filter_blk .control-row:not(:first-child) {
    margin-top: 0.8em;
  }

  .filter_seo_block.withoutFilter.price-sort_only {
    padding: 0;
  }

  .filter_seo_block .right-part:not(.withoutFilter) {
    transform: translateY(-130%);
    position: absolute;
  }

  .filter_seo_block.withoutFilter {
    align-items: center;
    padding-bottom: 0;
    margin-top: -0.4em;
  }

  .wrapper-filter-catalog_blk {
    margin-top: 0.5em
  }

  .wrapper-filter-catalog_blk.price-sort_only {
    margin-top: 0;
  }

  .seo-catalog_blk a {
    margin: 10px 0 0 12px;
  }

  .default_product .col_image .color.category-koleri {
    bottom: 3px;
    width: 50px;
    height: 50px;
  }

  .product_section .product-image_blk .image .color.category-koleri {
    width: 90px;
    height: 90px;
  }

  .wrapper-find_form form {
    display: block;
  }

  .wrapper-find_form .orange_btn {
    margin: 12px 0 0 0;
  }

  /** edit filter on catalog page **/
  .sub_headline, .products_blk {
    clear: both;
  }

  .wrapper-filter-catalog_blk.else,
  .btn-filter-catalog_blk.gone {
    display: none;
  }

  .btn-filter-catalog_blk:not(.gone) {
    display: block;
    float: right;
    margin-top: 0;
    margin-bottom: 5px;
    max-height: 40px;
    right: 0;
  }

  .btn-filter-catalog_blk.orange_btn {
    padding: 13px 27px
  }

  .wrapper-filter-catalog_blk.else {
    display: none;
  }

  .page_headline h1 {
    margin-bottom: 0.6em;
  }

  .page_headline {
    position: relative;
  }

  .wrapper-address .control-row {
    min-width: 0;
  }

  .success-payment .paymentLinkBtn.orange_btn {
    float: none;
    margin-bottom: 10px;
  }

  .success-printOrder .printOrder {
    margin: 10px 0;
  }

  .checkout_section .control-row.floor-row {
    margin-top: 10px;
    display: flex;
    align-items: center;
  }

  .checkout-setting_blk .unloading .panel .control-label.floor-label {
    margin-bottom: 0;
  }

  .checkout-setting_blk .unloading .panel > ._row {
    align-items: flex-end !important;
  }

  .checkout-setting_blk .unloading .panel .col_12:nth-child(2) .control-row .control-label {
    margin: 0;
  }

  .checkout-setting_blk .unloading .panel .floor-row label {
    margin-top: 0;
  }


  .wrapper-find_form .form-msg.error, .wrapper-find_form .form-msg.send {
    margin-top: 5px;
  }
}

@media (min-width: 500px) and (max-width: 767px) {
  .product_section .product-image_blk ._row,
  .mob-design.prod-info,
  .product_section .default_product {
    width: calc(100vw - 57px);
  }
}

@media (max-width: 767px) {
  footer .company-info_blk ._container {
    max-width: 90vw;
  }

  ._counter input {
    font-size: 18px;
    line-height: 28px;
  }

  .pr-slider.owl-carousel,
  .navigation-thumbs,
  footer .company-info_blk ._container {
    margin: 0 auto;
  }

  .pr-slider.owl-carousel,
  .navigation-thumbs {
    max-width: 382px;
  }

  .pr-video h5 {
    margin-top: 12px
  }

  .product_section .c-rating-preview ._stars {
    height: 11px;
    width: 64px;
  }

  .product_section .c-rating-preview ._active:before {
    height: 11px;
    width: 64px;
  }

  .mob-design .company-info_blk {
    border-top: none;
  }

  .product_section .reviews_blk .item ._author {
    padding-right: 1em;
  }

  .load-more,
  .click-prod {
    color: #f57329;
  }

  .product_section ._ico-minus::before,
  ._ico-minus::before {
    background-image: url(/build/images/t-min.054b9208.svg);
    height: 2px;
    margin-top: -1px;
  }

  .product_section ._ico-plus::before,
  ._ico-plus::before {
    background-image: url(/build/images/t-plus.bac27e7c.svg);
    height: 16px;
    margin-top: -8px;

  }

  .product_section ._ico-minus::before,
  .product_section ._ico-plus::before,
  ._ico-minus::before,
  ._ico-plus::before {
    content: '';
    display: block;
    left: 50%;
    margin-left: -8px;
    position: absolute;
    top: 50%;
    width: 16px;
  }

  .mob-design .company-info_blk ._container {
    color: #747474;
    font-size: 14px
  }

  .product_section .product_label[data-size="big"] {
    font-size: 16px;
    line-height: 20px;
    height: 26px;
    padding-top: 2px;
    top: 20px;
    width: 150px;
  }

  .product_section .product_label[data-type="sales_leader"] {
    top: 30px;
    width: 165px;
  }

  .product-image_blk ._counter [class^="_ico"],
  .product-image_blk ._counter input {
    box-shadow: none;
    width: 44px;
    height: 40px;
  }

  .product_section .product-image_blk .rating {
    margin: 5px 0 4px 0
  }

  .product_section .default_product ._counter [class^="_ico"],
  .product-image_blk ._counter [class^="_ico"],
  ._counter [class^="_ico"],
  ._counter [class^="_ico"] {
    background-color: #edf6fc;
    position: relative;
  }

  .product_section [class^="col_"],
  footer .company-info_blk ._container {
    padding: 0;
  }

  .product_section [class$="_row"],
  .product_section .small_row,
  .product_section .product-image_blk .c-rating-preview {
    margin: 0
  }

  .product_section .default_product [class^="_ico"],
  .product_section .default_product input,
  ._counter [class^="_ico"],
  ._counter input {
    appearance: none;
    box-shadow: none;
    height: 36px;
    width: 40px;
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  ._counter [class^="_ico"],
  ._counter input {
    height: 37px;
  }

  .product_section #quantity,
  .product_section .default_product input {
    font-size: 18px;
    line-height: 20px
  }

  .product_section .default_product input {
    margin: 0 4px
  }

  .product_section .default_product .col_cart {
    padding-left: 5px
  }

  .product-image_blk .pr-im {
    padding: 1em;
    position: relative;
    overflow: hidden;
    border-radius: 0 0 4px 4px;
    margin-bottom: 8px;
  }

  .product_section .product-image_blk .price .sale-price,
  .send-cart.orange_btn.full-width_btn,
  .product_section .default_product .headlin,
  .product_section .product-image_blk .price .old-price {
    font-size: 16px;
  }

  .product_section .product-image_blk .price .base-price {
    font-size: 24px;
  }

  .product_section .product-image_blk .summary .item:first-child {
    margin-top: 0
  }

  .product_section .product-image_blk .price .sale-price,
  .send-cart.orange_btn.full-width_btn,
  .product_section .product-image_blk .price .base-price,
  .product_section .default_product .send-cart,
  .product_section .default_product .headline,
  .mob-design .company-info_blk ._container {
    line-height: 20px;
  }

  .product_section .mini_catalog .default_product ._counter {
    margin-right: 8px;
  }

  .product_section .product-image_blk .price {
    padding: 0;
    background-color: transparent;
  }

  .product_section .item.mini .price {
    border-top: none
  }

  .product_section .item.mini .c-rating-preview {
    border-bottom: 1px solid #e9e9e9
  }

  .prod-info > li,
  .product_section .product-image_blk .image,
  .pr-im,
  .product_section .default_product {
    border: 1px solid #e9e9e9;
    border-radius: 1px;
    box-shadow: none
  }

  .item.mob-design .body {
    margin-top: 0;
  }

  .item.mob-design .head {
    align-items: center;
    display: grid;
    grid-template-columns: auto 1fr;
  }

  .item.mob-design .c-rating-preview {
    padding-left: 0
  }

  .item.mob-design .c-rating-preview ._stars {
    margin-right: 10px
  }

  .item.mob-design .c-rating-preview ._num {
    border-bottom: 1px solid #e9e9e9;
    padding-top: 0;
  }

  .prod-info > li,
  .prod-info > li:first-child {
    margin: 1em 0
  }

  .full-width_btn,
  .role_admin {
    padding: 10px 0;
  }

  .product-image_blk > .item {
    padding: 0 0 .5em 0;
  }

  .wordZone {
    padding-bottom: 5px;
  }

  .delivery div.wrapper-delivery_info {
    align-items: flex-end;
  }

  .delivery .openDeliveryMap {
    left: 1%;
    transform: translateX(0);
  }

  .fl_upld {
    justify-content: flex-start;
  }

  .wrapper-address #infoDeliveryZone.zoneDefined {
    display: flex;
    align-items: flex-end;
    padding-bottom: 5px;
    min-width: 135px;
  }

  .wrapper-address,
  .table-info tbody {
    display: block;
  }

  /* page order */
  .checkout-setting_blk .change .control-row {
    min-width: 0;
  }

  .checkout_section .delivery_price {
    min-width: 135px; /*150*/
  }

  .checkout-setting_blk .delivery_price .price .content {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }

  .checkout-setting_blk .unloading .change {
    align-items: flex-end;
  }

  .table-info tr {
    display: grid;
    grid-template-columns: 1fr 1.5fr;
  }

  /*checkout - order page 2*/
  .checkout_section {
    margin-top: 9px;
  }

  .checkout-setting_blk .summary {
    margin: 0 0 21px;
  }

  .checkout-address_blk .checkout-setting_blk .date-row {
    margin-bottom: 21px;
  }

  .checkout-setting_blk .element + .element {
    margin-top: 6px;
  }

  .control-row + .control-row, .control-submit {
    margin-top: 21px;
  }

  /* .checkout-address_blk {
     margin-top: 21px !important;
   }*/

  .checkout-setting_blk .date-row, .checkout-setting_blk .zone-row {
    margin-bottom: 21px;
  }

  .wrapper-address {
    margin-bottom: 8px;
  }

  .checkout-setting_blk .unloading .panel {
    margin-top: 11px;
  }

  .control-label label {
    font-size: 12pt;
  }

  /*search autocomplete*/
  .c-search-widget.show768 .ui-autocomplete {
    max-height: calc(70vh - 100px);
  }

  .show768.pk-mobile-breadcrumbs,
  .role_admin {
    display: block;
  }

  .product_section .product-image_blk .headline {
    line-height: 26px;
  }

  .pk-mobile-breadcrumbs {
    color: #747474;
    border-bottom: 0;
    line-height: 18px;
    padding: 12px 0 0 0;
  }

  .product_section .c-rating-preview ._num {
    color: rgba(116, 116, 116, 1);
    font-size: 14px;
    line-height: 20px;
  }

  .tabs_blk {
    display: none;
  }

  ._blk.catalog_main_group {
    margin-top: 40px;
  }
}

@media (min-width: 768px) and (max-width: 859px) {
  .pr-slider.owl-carousel,
  .navigation-thumbs {
    margin: 0 auto;
    max-width: 322px;
  }
}

@media (max-width: 859px) {
  .footer_blk .c-google-rating .payment-logo {
    display: none;
  }

  ._product.ui-menu-item a {

  }

  ._ico-search::before {
    height: 34px;
  }

  .pulse-button {
    height: 34px;
  }

  div.filter-flex {
    flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    -webkit-flex-direction: column-reverse;
  }

  .easy-search + .search_blk,
  .some-filters {
    flex-direction: column;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
  }

  .some-filters span {
    position: relative;
  }

  .some-filters--flex input[type=checkbox]:not(checked)+label{
    margin: 0 !important;
  }

  .easy-search + .search_blk .right-part {
    margin-top: -1.7em;
    transform: translateX(-35vw);
  }

  div.some-filters {
    align-items: flex-start;
    -webkit-align-items: flex-start;
  }

  .some-filters label {
    flex-basis: 2.5em;
    -webkit-flex-basis: 2.5em;
  }

  .filter-flex label {
    padding-bottom: 0.5em;
  }

  .cart_blk .cart {
    padding-left: 45px;
  }

  .cart_blk .cart .summ {
    margin-left: 7px;
  }

  .cart_blk .cart ._ico-cart {
    left: 3px;
  }

  .product_section .summary .item:first-child {
    margin-top: 20px;
  }

  .c-search-widget[data-type="mobile"] form {
    position: relative;
    width: 100%;
    right: 0;
    top: 0;
    background-color: #dff0f7;
    padding: 12px;
    border-radius: 4px;
    box-shadow: 1px 1px 5px 0 rgba(50, 50, 50, .5);
    visibility: visible;
    opacity: 1;
    text-align: left;
    z-index: 1200;
  }

  .c-search-widget[data-type="mobile"] form input {
    border: 1.5px solid #FF4F00;
    border-radius: 4px;
    box-shadow: none;
    height: 34px;
    color: #505050;
    padding-left: 31px;
    font-size: 13pt;
    -webkit-appearance: none;
  }

  .c-search-widget[data-type="mobile"] form input::placeholder {
    color: #898989;
  }

  .header_panel .c-search-widget.show768 ._ico-search:before {
    content: "";
    width: 24px;
    height: 24px;
    display: inline-block;
    background-size: 16px;
    background-repeat: no-repeat;
  }

  .header_panel .c-search-widget[data-type="mobile"] form {
    padding: 0 0 6px;
    box-shadow: none;
    background: transparent;
    margin: 0 auto;
    max-width: 100%;
  }

  .c-search-widget.show768.hiden[data-type='mobile'] {
    display: none;
  }

  .header_panel .large_row .col_full:first-of-type {
    display: none;
  }

  .header_panel .large_row .col_full.show768 {
    border-left: none;
    display: block;
  }

  .head-links_mobile ._logo img {
    margin-top: 0;
  }

  .header_panel {
    border-bottom: none;
    padding-top: 0;
    position: fixed;
    width: 100%;
    z-index: 1001;
    background-color: white;
  }

  .header_panel .c-search-widget.show768 form {
    max-width: 100%;
  }

  .header_panel > ._container.addSticky {
    border-bottom: 1px solid #d7d9da;
    position: fixed;
    width: 100%;
    background-color: white;
    z-index: 100;
    padding-bottom: 0;
    padding-top: 0;
  }

  .content_panel {
    padding-top: 104px;
  }

  /* mob catalog page */
  .categories_blk[data-mobile-item] .trigger {
    position: absolute;
    top: 10px;
    left: 20px;
    right: unset;
    transition: all .2s;
  }

  .categories_blk[data-mobile-item] .trigger:after {
    font-family: 'postroyka';
    content: "\e1007";
    font-size: 8px;
    color: #000;
    font-weight: bold;
  }

  .categories_blk[data-mobile-item] .item {
    position: relative;
    padding: 0;
    margin-top: 0;
  }

  .categories_blk[data-mobile-item] .item > a {
    display: block;
    padding: 11px 24px 11px 35px;
    margin: 0;
    border-top: 1px solid #e9e9e9;
    background: transparent;
    color: #333;
    text-decoration: none;
    font-size: 12pt;
  }

  .categories_blk[data-mobile-item] .item[data-open] > a {
    font-weight: bold;
  }

  .categories_blk ul.vertical_nav a:before {
    background: transparent;
  }

  .categories_blk[data-mobile-item] .item .vertical_nav {
    padding: 0;
  }

  .categories_blk[data-mobile-item] .item .vertical_nav a {
    padding: 13px 24px 12px 60px;
    color: #4c4d50;
    font-weight: normal;
    border-top: 1px solid #e9e9e9;
    background: transparent;
    font-size: 12pt;
  }

  .categories_blk ul.vertical_nav li + li {
    margin: 0;
  }

  .sub-categories_blk .headline {
    font-weight: bold;
  }

  .pk-mobile-breadcrumbs a {
    color: #7d7d7d;
    padding-left: 12px;
    font-size: 14px;
  }

  .pk-mobile-breadcrumbs {
    border-top: none;
    padding-top: 0;
    position: relative;
    /*z-index: 100;*/
  }

  .pk-mobile-breadcrumbs.zindex {
    z-index: 1;
  }


  .pk-mobile-breadcrumbs .mobile-breadcrumbs__main-link {
    padding-left: 0;
  }

  .pk-mobile-breadcrumbs .mobile-breadcrumbs__main-link:before {
    content: '';
  }

  .pk-mobile-breadcrumbs a:before {
    position: absolute;
    top: 0;
    left: 0;
    font-family: 'postroyka';
    content: "\e1007";
    font-size: 7px;
  }


  /* page category */
  .sub-categories_blk .col_12 {
    width: 50%;
  }

  .sub-categories_blk .item {
    padding: 15px 10px 5px;
    display: flex;
    flex-direction: column;
  }

  .sub-categories_blk .headline {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    flex: 1 1 auto;
  }

  .sub-categories_blk .image {
    margin-bottom: 10px;
  }

}

@media only screen and (max-width: 1075px) {
  .wrapper-address {
    display: block;
  }
}

@media only screen and (min-width: 500px) {
  .checkout-setting_blk .unloading .panel .col_12:nth-child(2) {
    padding-top: 0;
  }

  .categories_blk:not([data-mobile-item]) .item {
    padding: 8px 12px 0;
  }

  .sub-categories_blk .headline, .categories_blk .headline {
    margin-bottom: 10px;
  }

  .categories_blk .vertical_nav li:last-child {
    padding-bottom: 8px;
  }

  /*remove separator line*/
  .categories_blk:not([data-mobile-item]) .item:before {
    display: none;
  }

  .filter_seo_block .filter_blk select {
    padding: 4px 15px 4px 5px;
    height: 24px;
  }


  /* order */
  .checkout-setting_blk .unloading .floor-row input {
    padding: 6px 4px 5px;
    width: 25px;
    height: 25px;
    margin-right: 6px;
  }

  .checkout-setting_blk .unloading .panel .control-label.floor-label {
    margin-right: 0;
  }

  .checkout-setting_blk .unloading .floor-row .checkbox input[type=checkbox]:not(checked) + label:before,
  .checkout-setting_blk .unloading .checkbox input[type=checkbox]#extraFloor:not(checked) + label:before {
    width: 25px;
    height: 25px;
    vertical-align: -8px;
  }

  .checkout-setting_blk .unloading .floor-row .checkbox input[type=checkbox]:not(checked) + label:after,
  .checkout-setting_blk .unloading .checkbox input[type=checkbox]#extraFloor:not(checked) + label:after {
    width: 16px;
    height: 18px;
    left: 5px;
    top: 7px;
    font-size: 13px;
  }


  /* search for not found */
  .wrapper-find_form .ui-autocomplete {
    width: 100% !important;
    max-width: 350px;
  }
}


@media (min-width: 693px) and (max-width: 799px) {
  .filter_seo_block .left-part .filter_blk + .seo-catalog_blk {
    width: 118%;
  }
}

@media (min-width: 860px) and (max-width: 900px) {
  .filter_seo_block .left-part .filter_blk + .seo-catalog_blk {
    width: 125%;
  }
}

@media (min-width: 981px) and (max-width: 1024px) {
  .filter_seo_block .left-part .filter_blk + .seo-catalog_blk {
    width: 120%;
  }
}

@media (min-width: 1025px) and (max-width: 1117px) {
  .filter_seo_block .left-part .filter_blk + .seo-catalog_blk {
    width: 117%;
  }
}

@media (min-width: 1200px) {
  .filter_seo_block .left-part .filter_blk + .seo-catalog_blk {
    width: 127%;
  }
}


@media only screen and (min-width: 650px) {
  /** edit filter on the catalog page **/
  .btn-filter-catalog_blk {
    display: none;
  }

  .product_section .product-image_blk .image .color.category-koleri {
    width: 100px;
    height: 100px;
  }

  .wrapper-filter-catalog_blk {
    display: block ;
    margin-top: 0.5em;
  }


  .checkout-report_blk .elem {
    max-width: 395px;
  }
}

@media (min-width: 768px) {
  ._counter input {
    font-size: 14px;
    width: 36px;
  }

  .control-label {
    line-height: 1;
    width: 30%;
    padding-right: 5%;
    margin-bottom: 0;
  }

  .checkout-setting_blk .unloading .panel .col_extra {
    min-width: 200px;
  }

  .checkout-setting_blk .control-label {
    width: 125px;
  }


  /*checkout - order page*/
  .checkout_section {
    padding-left: 24px;
  }

  .checkout_section .control-label label {
    font-size: 12pt;
  }

  .checkout-setting_blk .unloading .panel > ._row {
    flex-wrap: nowrap;
    padding-left: 137px;
  }

  .checkout-setting_blk .unloading .panel .col_flor {
    padding-right: 0;
  }

  .checkout_section .control-row.floor-row {
    min-width: 136px;
  }

  .checkout-setting_blk .unloading .floor-row .input-floor {
    margin-right: 0;
  }

  .checkout-setting_blk .unloading .floor-row .checkbox {
    margin-right: 0;
  }

  .checkout-setting_blk .unloading .panel .col_extra {
    padding-left: 10px;
  }

  /*checkout - order page 2*/
  .checkout-setting_blk .summary {
    margin: 0 0 18px;
  }

  .checkout-address_blk .checkout-setting_blk .date-row {
    margin-bottom: 18px;
  }

  .checkout-setting_blk .element + .element {
    margin-top: 0;
  }

  .control-row + .control-row, .control-submit {
    margin-top: 18px;
  }

  .control-row + .control-row, .control-submit {
    margin-top: 18px;
  }

  .checkout-address_blk {
    margin-top: 18px !important;
  }

  .checkout-setting_blk .date-row, .checkout-setting_blk .zone-row {
    margin-bottom: 18px;
  }

  .show768 {
    display: none;
  }

}

@media only screen and (min-width: 860px) {
  .show859.pk-mobile-breadcrumbs {
    display: none;
  }
}

@media (min-width: 990px) and (max-width: 1239px) {
  ._logo .text {
    font-size: 11pt;
  }
}

@media only screen and (min-width: 1024px) {
  .checkout-report_blk .col_12 {
    width: 70%;
    max-width: 489px;
  }

  .checkout-setting_blk .price {
    max-width: 465px;
  }
}

@media (min-width: 1240px) and (max-width: 1280px) {
  ._logo .text {
    font-size: 10pt;
  }
}

@media (min-width: 1281px) and (max-width: 1380px) {
  ._logo .text {
    font-size: 11pt;
  }
}

/* edit filter */
.filter_blk .secret_btn {
  background: white;
}

.filter_blk .secret_btn a {
  text-decoration: none;
}

/* new owl carousel desktop */
.owl-slider2_blk {
  display: none !important;
}

.owl-slider2_blk .owl-stage-outer {
  max-height: 365px;
}

.owl-slider2_blk .owl-stage {
  position: relative;
}

.owl-slider2_blk .owl-item {
  height: 365px;
  background-repeat: no-repeat;
  overflow: hidden;
}

.owl-slider2_blk .summary {
  position: absolute;
  top: -300px;
  right: 50px;
  width: 400px;
  padding: 24px;
  border-radius: 10px;
  background: rgba(223, 240, 247, 0.7);
  opacity: 0;

  -webkit-transition: 0.6s ease;
  -webkit-transition-delay: 0.6s;
  -o-transition: 0.6s ease;
  -o-transition-delay: 0.6s;
  -moz-transition: 0.6s ease;
  -moz-transition-delay: 0.6s;

  transition: 0.6s ease;
  transition-delay: 0.6s;
}

.owl-slider2_blk .owl-item.active .summary {
  top: 65px;
  opacity: 1;
}

.owl-slider2_blk .headline {
  font-size: 20pt;
  font-weight: 700;
  line-height: 1.1;
  text-align: center;
}

.owl-slider2_blk .item + .item {
  margin-top: 12px;
}

.owl-slider2_blk .orange_btn {
  margin-top: 5px;
}

.owl-slider2_blk .owl-nav {
  height: 0;
}

.owl-slider2_blk .owl-nav span {
  display: block;
  border-radius: 50%;
  border: 2px solid #ff4f00;
  color: #ff4f00;
  background: #fff;
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 20px;
  line-height: 37px;
  position: absolute;
  top: 50%;
  margin-top: -20px;
  z-index: 30;
  cursor: pointer;
  transition: all 0.15s ease-in-out;
  opacity: 0;
}

.owl-slider2_blk:hover .owl-nav span {
  opacity: 1;
}

.owl-slider2_blk .owl-nav ._ico-arr-left {
  left: -20px;
}

.owl-slider2_blk .owl-nav ._ico-arr-right {
  right: -20px;
}

.owl-slider2_blk .owl-dots {
  position: absolute;
  bottom: -20px;
  left: 50%;
  z-index: 30;
  display: flex;
}

.owl-slider2_blk .owl-dots button {
  width: 12px;
  height: 12px;
  border: 2px solid #ff4f00;
  cursor: pointer;
  border-radius: 50%;
  box-sizing: border-box;
}

.owl-slider2_blk .owl-dots .owl-dot + .owl-dot {
  margin-left: 12px;
}

.owl-slider2_blk .owl-dots button.active, .owl-slider2_blk .owl-dots button.active:hover {
  width: 12px;
  height: 12px;
  background: #ff4f00;
  border: none;
}

@media (max-width: 1200px) {
  .our_advantages {
    justify-content: center
  }
}

@media (max-width: 600px ) {
  .adv_pic_and_text {
    margin: 0.19em;
  }

  .adv_block:not(:last-child) {
    margin-right: 8px;
  }

  .adv_block {
    height: 96px;
    width: 82px;
  }

  .adv_block:nth-child(3),
  .owl-item .active ._own_car_delivery {
    display: none;
  }

  .adv_pic_and_text figcaption {
    font-size: 10px;
    line-height: 13px;
  }
}

@media (max-width: 500px) {
  .mobile_act_user {
    display: none;
  }

  .advantages {
    display: none
  }

  .advantages_mob {
    display: block;
  }

  .our_advantages_mob {
    display: block;
    max-width: 351px;
    margin: 0 auto;
  }

  .adv_block_mob:not(:last-child) {
    margin-right: 16px
  }

  .adv_block_mob {
    height: 96px;
    width: 82px;
    display: flex;
    align-items: center;
  }

  .advantages_mob {
    margin-top: 1em;
  }

  .adv_pic_and_text_mob {
    margin: 0.2em
  }

  .adv_pic_and_text_mob p {
    text-align: center;
  }

  .adv_pic_and_text_mob figcaption {
    text-align: center;
    font-size: 10px;
    line-height: 13px;
    font-weight: 700;
  }

  .our_advantages_mob .owl-item {
    height: 96px;
    display: inline-block;
    width: 82px !important;
    margin-right: 6px;
  }

  .our_advantages_mob .owl-stage-outer {
    position: relative;
    overflow: hidden;
    -webkit-transform: translate3d(0, 0, 0);
  }

  .advantages_mob .owl-stage {
    display: flex;
  }

  ._adv-certificate {
    margin: 0.2em;
  }

  .our_advantages_mob .owl-nav {
    display: none;
  }
}

@media (min-width: 501px) {

  .advantages_mob {
    display: none;
  }

  .our_advantages {
    display: flex;
  }

  .adv_block:not(:last-child) {
    margin-right: 16px
  }

  .adv_block {
    height: 174px;
    width: 177px;
    display: flex;
    align-items: center;
  }

  .advantages {
    margin-top: 1em;
  }

  .adv_pic_and_text {
    margin: 1.6em;
  }

  .adv_pic_and_text p {
    text-align: center;
  }

  .adv_pic_and_text figcaption {
    text-align: center;
    font-size: 16px;
    line-height: 21px;
    font-weight: 700;
  }
}

@media (min-width: 319px) and (max-width: 399px) {
  .mobile_act_user {
    display: none;
  }

  .adv_pic_and_text_mob p {
    margin-bottom: 0.1em
  }

  .adv_pic_and_text_mob p img {
    height: 40px;
    width: 40px;
  }

}

@media (min-width: 400px) and (max-width: 859px) {
  .mobile_act_user {
    display: none;
  }

  .adv_pic_and_text p {
    margin-bottom: 0.2em
  }

  .adv_block:not(:last-child) {
    margin-right: 16px
  }

  .adv_block {
    height: 120px;
  }

  .adv_pic_and_text p img {
    height: 55px;
    width: 55px;
  }

  .adv_pic_and_text_mob p {
    margin: 0 0 0 0.2em
  }

  .adv_pic_and_text_mob {
    margin: 0.2em
  }
}

@media (min-width: 500px) and (max-width: 859px) {
  .pk-mobile-breadcrumbs {
    margin-top: -10px
  }

  .mobile_act_user {
    display: none;
  }

  .adv_block {
    height: 145px;
    width: 109px;
  }

  .adv_pic_and_text figcaption {
    font-size: 13px;
    line-height: 16px;
  }

  .adv_pic_and_text {
    margin: 0.2em auto;
  }

  .adv_block:not(:last-child) {
    margin-right: 6px;
  }
}

@media (min-width: 600px) and (max-width: 859px) {
  .mobile_act_user {
    display: none;
  }

  .adv_block:not(:last-child) {
    margin-right: 12px
  }

  .adv_pic_and_text {
    margin: 0.2em auto;
  }

  .adv_pic_and_text figcaption {
    font-size: 12px;
    line-height: 16px;
  }
}

@media (min-width: 700px) and (max-width: 859px) {
  .mobile_act_user {
    display: none;
  }

  .adv_block {
    height: 156px;
    width: 120px;
  }

  .adv_block:not(:last-child) {
    margin-right: 10px
  }

}

@media (min-width: 800px) and (max-width: 859px) {
  .mobile_act_user {
    display: none;
  }

  .adv_block {
    height: 150px;
    width: 137px;
  }

  .adv_block:not(:last-child) {
    margin-right: 13px
  }

}

@media (min-width: 860px) and (max-width: 1000px) {
  .adv_block {
    height: 143px;
    width: 126px;
  }

  .adv_block:nth-child(3) {
    display: none;
  }

  .adv_block:not(:last-child) {
    margin-right: 10px
  }

  .adv_pic_and_text {
    margin: 0.2em;
  }

  .adv_pic_and_text figcaption {
    font-size: 12px;
    line-height: 16px;
  }
}

@media (min-width: 1001px) and (max-width: 1200px) {
  .adv_block:nth-child(3) {
    display: none;
  }
}

@media (min-width: 767px) and (max-width: 768px) {
  .mobile_act_user {
    display: none;
  }

  .adv_block {
    height: 151px;
    width: 133px;
  }

  .adv_block:not(:last-child) {
    margin-right: 10px
  }
}

.search-empty .price_sort {
  display: none;
}

/*  some title change color  */
.index_page .blk_headline, .page_headline.stock_page h1 {
  color: #ea4800;
}

@media only screen and (min-width: 1024px) {
  .owl-slider2_blk {
    display: block !important;
  }

  ._catalog > ._row > [class^="col_"] {
    margin-top: 20px;
  }

  [class*="_blk withoutFilter"] + [class$="_blk"] {
    margin-top: 24px;
  }
}

@media only screen and (max-width: 1024px) {
  [class$="_product"] {
    border: 1px solid #d7d9da;
  }
}

@media only screen and (max-width: 1075px) {
  .seo-catalog_blk {
    padding: 6px 12px;
  }

  .col_.hide1024 {
    margin: 5px auto 0;
  }
}

/*fixed:  issue with overflow content in slider in firefox on windows*/
.index_page ._layout .f-width {
  max-width: 100%
}

@media only screen and (min-width: 860px) {

  .index_page ._layout .f-width {
    max-width: calc(100% - 243px);
  }

  .footer_blk .c-google-rating {
    display: flex;
    align-items: center;
    margin-left: 25px;
    padding: 10px 0 20px;
  }

  .owl-slider2_blk .summary .description {
    font-size: 16px;
    line-height: 1.3;
  }

  .owl-slider2_blk .summary {
    padding: 20px;
  }

  .owl-slider2_blk .item.cr-t-a_c {
    margin-top: 10px;
  }
}
@media (min-width: 1205px) {
  .footer_blk .c-google-rating .payment-logo {
    height: 20px;
    margin-left: 76px;
  }
}
@media (min-width: 860px) and (max-width: 1204px) {
  .rating-google {
    flex-basis: 25%;
  }
}
.fancybox-inner .item.lightbox-container a {
  display: none;
}

.pk-mobile-slider.owl-carousel .pk-mobile-slider__slide:first-child {
  opacity: 0;
}

.pk-mobile-slider.owl-carousel .pk-mobile-slider__slide:not(:first-child) {
  display: none;
}

.advantages_mob .adv_block_mob:first-child {
  opacity: 0;
}

.advantages_mob .adv_block_mob:not(:first-child) {
  display: none;
}

/* list of brands */
.every-letter_block {
  background: #ff4f00;
  display: block;
  float: left;
  height: 50px;
  position: relative;
  text-align: center;
  width: 50px;
}

.brand-scroll,
.special-text {
  font-size: 17px;
}

.letter-of-the-alphabet {
  color: white;
  font-size: 2em;
  font-weight: 700;
  left: 50%;
  margin-right: -50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.specific-letter-brands {
  align-items: flex-start;
  border-bottom: 2px solid #edf6fa;
  display: flex;
  justify-content: space-between;
  margin: 1px 0;
  padding: 2pc 0;
}

.brand-names {
  column-count: 5;
  width: 85%;
}

.every-brand-name a,
.filter-flex select option:not(.default-value) {
  color: black;
}

.every-brand-name a:hover {
  color: #f98424;
  text-decoration: underline;
}

@media (max-width: 499px) {
  .main_wrapper.index_page .header_panel {
    padding: 0 0 12px 0;
  }

  .main_wrapper:not(.index_page) .header_panel {
    padding: 0;
  }

  .product_section .default_product {
    width: 94vw;
  }

  .cart-design input {
    box-shadow: none;
  }

  .mob-design.prod-info {
    margin-left: 0
  }

  .product_section .product-image_blk ._row,
  .mob-design.prod-info {
    width: 94vw;
  }

  .cart-design .img img {
    max-height: 170px;
    position: relative;
  }

  .cart-design ._col-image {
    flex-basis: calc(100% - 27px);
  }

  .cart-design ._col-price {
    padding: 10px 0
  }

  ._price._price {
    font-size: 22px;
  }

  ._price.new-price {
    color: #f70000;
    padding: 5px 0 7px 0;
  }

  .new-price,
  .cart-design .old,
  .cart-design ._num span {
    line-height: 20px;
  }

  .cart-design .old {
    font-size: 16px;
    text-decoration: line-through;
  }

  .cart-design .old,
  .cart-design ._num span {
    color: #747474;
  }

  .cart-design ._num span {
    font-size: 13px;
  }

  .cart_section {
    margin-top: -10px
  }

  .cart-design ._counter input,
  .cart-design ._headline {
    font-weight: 500;
  }

  .cart-empty_section .headline {
    font-weight: 600
  }

  .cart_section ._counter [class^="_ico"],
  .cart-design ._counter input,
  .cart-empty_section ._counter [class^="_ico"],
  .cart-empty_section ._counter input {
    height: 36px;
    line-height: 32px;
    width: 40px;
  }

  .cart-empty_section ._counter input,
  .cart-design ._counter input {
    font-size: 18px;
  }

  .default_product .send-cart {
    background: #ff4f00;
    border-radius: 2px;
    border: none;
    color: white;
    font-size: 18px;
    text-align: center;
    padding: 6px 0 8px;
    width: 146px;
  }

  .cart-design {
    display: flex;
    align-items: stretch;
  }

  .pr-design {
    flex-direction: column;
    justify-content: space-between;
  }

  .pr-design:first-child {
    text-align: center;
    margin-right: 10px;
    max-width: 130px;
  }

  .pr-design:last-child {
    text-align: left;
  }

  .pr-design:last-child .col_summary {
    padding: 0;
  }

  .pr-design ._summ {
    margin-bottom: 5px
  }

  .cart_section .i-item {
    padding: 20px 10px
  }

  .c-cart__grid .i-item {
    background: #fff;
    border: 1px solid #e9e9e9;
    box-shadow: 0 0 30px rgba(0, 0, 0, .05);
    border-radius: 3px;
  }

  ._total .item + .item {
    margin-top: 3px;
  }

  ._total .title {
    color: #959393;
    font-size: 16px;
    line-height: 24px;
  }

  .cart-total_blk {
    border-top: none;
    padding-top: 5px;
  }

  .cart-total_blk .col_ {
    bottom: 0;
    margin-left: -2%;
    max-height: 60px;
    min-height: 59.999px;
    padding: 0;
    position: fixed;
    width: 101vw;
    z-index: 999;
  }

  .cart-total_blk .col_ .orange_btn {
    width: 208px;
    font-size: 23px;
    line-height: 14px;
    padding: 1em;
    border-radius: 5px;
  }

  .cart_section a._headline,
  .cart-empty_section .headline {
    display: inline-block;
    max-width: calc(100% - 20px);
    min-width: 120px;
    word-break: break-word;
    color: #212121;
    font-size: 16px;
  }

  div.cart-clear_blk.cart-clear_blk {
    margin-top: 10px
  }

  .cart_section a._headline,
  .cart-empty_section .default_product .send-cart {
    line-height: 20px;
  }

  .cart_section a._headline {
    text-decoration: none;
  }

  div.cart-empty_section.cart-empty_section,
  div.cart-empty_section.cart-empty_section h2 {
    margin-top: 0
  }

  div.cart-empty_section.cart-empty_section h2 {
    margin-bottom: 12px;
  }

  div.cart-empty_section.cart-empty_section h2 {
    margin-bottom: 12px;
  }

  .brand-names {
    column-count: 2;
    width: 77%;
  }

  .enter-address_pop_up {
    font-size: 14px;
    white-space: normal;
  }

  .mobile_act_user,
  .dsctp {
    display: none;
  }

  .cart-design .c-rating-preview {
    margin: 0 -13px 0 0;
    padding-left: 0;
  }

  .cart-design .c-rating-preview ._num {
    padding-top: 0;
  }

  .cart-design .item.mini {
    border-bottom: 1px solid #e9e9e9;
    padding: 5px 0;
  }

  .cart-design ._stars {
    /* height: 13px;*/
    margin-right: 3px;
    /*width: 75px;*/
  }

  .blue-block {
    margin-left: 0;
    border-radius: 0 0 4px 4px;

  }
  .prod-info>li{
    border-radius: 0 0 4px 4px;
  }
}

.sub-categories_blk.delete_margin h2 {
  width: 100%
}

.mobile_act_user {
  padding-bottom: 10px;
  padding-left: 10px;
}

.pr-im.-shadow_default {
  overflow: hidden;
  position: relative;
}

.pr-slider.owl-carousel {
  margin-bottom: 12px;
  max-height: 298px;
  min-height: 297.99999px;
}

.navigation-thumbs .owl-stage-outer {
  z-index: 3
}

.navigation-thumbs .owl-item {
  margin-right: 0
}

#sync1 .item {
  color: #fff;
  text-align: center;
}

#sync1 .item img {
  height: auto;
}

#sync2 .item {
  text-align: center;
  cursor: pointer;
}


.pr-slider .owl-stage,
div.owl-stage-outer {
  max-height: 298px;
}

.navigation-thumbs .owl-item.synced.active {
  border: 1px solid #ff4f00;
}

div.owl-item.active img,
div.owl-item img,
div.owl-item.active.center img {
  margin: 0 auto;
  width: initial;
  overflow: auto;
  height: auto;
}

div.owl-item .item.lightbox-container,
div.owl-item .item.lightbox-container img {
  display: block;
  max-height: 298px;
}

.pr-slider .owl-stage,
.rev-info .head,
#sync2 .owl-stage {
  align-items: center;
  display: flex;
}

.navigation-thumbs .owl-nav span {
  background: #fff;
  border-radius: 50%;
  border: 2px solid #ff4f00;
  color: transparent;
  cursor: pointer;
  display: block;
  font-size: 20px;
  height: 25px;
  line-height: 37px;
  margin-top: -10px;
  opacity: 0;
  -moz-opacity: 0;
  position: absolute;
  top: 50%;
  transition: all 0.15s ease-in-out;
  text-align: center;
  visibility: visible;
  width: 25px;
  z-index: 30;
}

.navigation-thumbs .owl-nav ._ico-arr-right::before,
.navigation-thumbs .owl-nav ._ico-arr-left::before {
  color: #ff4f00;
  font-size: 0.7rem;
  position: absolute;
  top: -8px
}

.navigation-thumbs .owl-nav ._ico-arr-right {
  left: 95%;
}

.navigation-thumbs .owl-nav ._ico-arr-right::before {
  margin-left: 1px
}

.navigation-thumbs .owl-nav ._ico-arr-left {
  left: -3%;
}

.navigation-thumbs .owl-nav ._ico-arr-left::before {
  margin-right: 1px;
}

.navigation-thumbs .owl-nav {
  height: 0;
}

.navigation-thumbs.owl-carousel {
  position: relative;
}

/*видимость стрелок навигации на слайдере изображений продукта
.navigation-thumbs .owl-nav:hover span,
.navigation-thumbs .owl-nav span:hover,
.navigation-thumbs .owl-nav:hover span,
.navigation-thumbs:hover .owl-nav span {
    opacity: 1;
}*/

@media (max-width: 444px) {
  .product_section .tabs_blk {
    max-width: 90vw;
    margin: 0 auto
  }

  .special-delivery {
    margin: 0 auto;
    max-width: 90vw;
  }
}

@media screen and (min-width: 445px) and (max-width: 767px) {
  .-shadow_default.pr-im {
    max-width: 400px;
    margin: 0 auto
  }
}

.item-specific-rev,
.main-tabs_title {
  margin-block-end: 0;
  margin-block-start: 0;
  padding-inline-start: 0;
}

.product-rev::before,
.main-tabs_content,
.main-tabs_li::before {
  display: none;
}

.main-tabs_content.active {
  display: block;
}

.special-delivery {
  padding: 1em 0;
}

.direct-delivery {
  border: 2px solid #ff4f00;
  border-radius: 4px;
}

.special-text {
  padding: 1em .5em;
  color: #ff4f00;
}

.main-tabs_content .top-products_blk {
  padding-top: 0;
}

.main-tabs_content ._row {
  margin: 0
}

@media all and (max-width: 631px) {
  .main-tabs_content .col_12:first-child {
    margin-top: 0
  }
}

@media all and (min-width: 632px) and (max-width: 1239px) {
  .main-tabs_content .col_12:first-child,
  .main-tabs_content .col_12:nth-child(2) {
    margin-top: 0
  }
}

@media all and (min-width: 1240px) {
  .main-tabs_content .col_12:first-child,
  .main-tabs_content .col_12:nth-child(2),
  .main-tabs_content .col_12:nth-child(3) {
    margin-top: 0
  }
}

/*.main-tabs_content .col-12 {
    margin-top: 0
}*/

@media screen and (min-width: 580px) {
  .main-tabs_title {
    justify-content: space-evenly;
  }

  .main-tabs_title li.main-tabs_li {
    padding: 25px 0
  }
}

@media screen and (max-width: 579px) {
  .main-tabs_title {
    justify-content: space-evenly;
    text-align: center;
  }

  .main-tabs_li {
    font-size: 1.1rem;
    padding: 28px 0
  }

  .main-tabs_li.m-novelty.m-novelty {
    margin: 0 0.5em
  }
}

.main-tabs_title li.main-tabs_li,
.enter-address_pop_up {
  color: #2a2a2a;
  margin: 0;
}

.main-tabs_li:hover {
  cursor: pointer;
}

.main-tabs_li.active:first-child {
  color: #e81800;
}

.main-tabs_li.m-novelty.active {
  color: #54abfd
}

.main-tabs_li.active:last-child,
.bestSeller h1 {
  color: #15c904
}

.product-rev:nth-child(odd) {
  border-top: 2px solid #edf6fa;
}

/*.product-rev:nth-child(even),*/
.product-rev:nth-child(odd) .rev-info {
  border-bottom: 2px solid #edf6fa;
}

.product-rev > div,
.product-rev .head,
.product-rev .text {
  padding: 5px 0;
}

.product-rev .c-rating-preview {
  margin: 0;
  padding-left: 1em;
}

.product-rev .price div:nth-child(2) {
  font-size: 1rem;
  order: -1;
  padding-right: 5px;
}

.product-rev .col_summary .mini,
.trust li,
.black,
._pr-desc-title,
.prod-info .blk_headline {
  padding: .5em 0
}

.product-rev:first-child,
.enter-your-zone .zoneDefined {
  padding-top: .6em;
}

.product-rev .headline {
  color: #2a2a2a;
  font-weight: bold;
  text-decoration: none;
}

.easy-search + .search_blk {
  box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.18);
  border: 1px solid #d7d9da;
  margin-bottom: 2em;
  padding: 1em
}

.easy-search + .search_blk .col_ {
  text-align: right;
}

.filter-flex {
  flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  -webkit-flex-direction: row-reverse;
}

.filter-flex,
.some-filters,
.main-tabs_title,
.product-rev .price {
  align-items: center;
  display: -ms-flex;
  display: -o-flex;
  display: -moz-flex;
  display: flex;
}

.some-filters,
.filter-flex {
  justify-content: space-between;
}

.filter-flex input,
.filter-flex select {
  flex-basis: 80%;
}

.filter-flex:not(:first-child),
.some-filters {
  padding: .5em 0;
}

.filter-flex:first-child {
  padding-bottom: 0.5em;
}

.product-popup {
  display: none;
  padding: 1em 29px 1em 1em;
  position: fixed;
  z-index: 9999;
  bottom: 2vh;
  right: 1em;
  width: auto;
  height: 45px;
  border-radius: 6px;
}

.close-w {
  font-size: 2.7em;
  right: .15em;
  top: -.35em;
}


.purchased {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.zoneDefined {
  padding-left: 6px;
}


.filter-flex:not(:first-child), .some-filters {
  padding: .5em 0;
}

.pulse-button {
  background: url(/build/images/micro-2.31d21887.svg) no-repeat center center;
  cursor: pointer;
  display: block;
  position: absolute;
  right: 0;
  top: 0;
}

.show-more-category {
  display: none;
}

.image-below-category {
  width: 100%;
  height: auto;
}

.related_product img {
  height: 100%;
  width: auto;
}

.mob-design.blue-block .trust img {
  height: auto;
  width: auto;
}

.working-hours {
  position: relative;
  padding-top: 10px;
  text-align: center;
  margin-left: 5px;
}

.working-hours strong {
  font-size: 15.8px;
}

.working-hours_item:first-child strong {
  color: #fa3535;
  font-size: 28px;
}

.working-hours:after {
  position: absolute;
  content: "";
  width: 26px;
  height: 26px;
  background-image: url(/build/images/clock-clock-svgrepo-com.b374267a.svg);
  top: 14px;
  left: -8px;

}

.working-hours_item:not(:first-child) {
  margin-top: 6px;
}

.location-place {
  position: relative;
  text-align: center;
  margin-left: 5px;
  padding: 20px 0 10px;
}

.location-place a {
  color: #f75c17;
  font-size: 15.4px;
}


.location-place:after {
  position: absolute;
  content: "";
  width: 26px;
  height: 26px;
  background-image: url(/build/images/icons-location.47d0af98.svg);
  background-repeat: no-repeat;
  background-position: center;
  top: 11px;
  left: -9px;
}

@media (min-width: 768px) {
  .cart-feedback_popup {
    font-family: 'PT Sans';
    margin: 0;
    max-width: 1024px;
    max-height: 90vh;
    width: calc(100% - 30px);
    top: 50%;
    left: 50%;
    transform: translate(-50% , -50%);
    display: block;
    border-radius: 20px;
    background: linear-gradient(2deg, #F0EFEA 0%, #F5F5F2 57.57%, #FEFEFE 100%);
    padding: 0;
    padding-bottom: 40px;
  }

  .cart-feedback_popup ._ico-number span {
    color: #000;
    font-size: 14px;
    font-family: PT Sans;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    padding: 4.5px 0;
    margin-left: 40px;
  }

  .cart-feedback_popup .cart-feedback_blk .logo {
    display: block;
    max-width: 200px;
    margin: 0 auto;
  }

  .cart-feedback_popup .blk_headline {
    font-size: 16px;
    margin: 20px 0 25px;
    font-family: 'PT Sans';
  }

  .field-cart-feedback {
    height: 60px;
    margin-bottom: 10px;
    padding: 8px 10px 8px;
  }

}

.cart-feedback_submit {
  display: block;
  border-radius: 5px;
  font-size: 18px;
  font-family: PT Sans;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  padding: 7px 41px;
  margin: 8px auto 0;
}

.cart-feedback_smile {
  max-height: 195px;
  float: right;
}

.cart-feedback_popup ._ico-close {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 19px;
  height: 19px;
  right: 20px;
  top: 20px;
  background: initial;
  border-radius: 0;
  border: none;
}

.text-align-center {
  text-align: center;
}

.cart-feedback_popup form {
  max-width: inherit;
  width: 100%;
  margin: 0;
  padding: 0 95px;
}

.cart-feedback_popup .cart-feedback_blk .blk_headline span {
  color: #000;
  font-size: 26px;
  font-family: PT Sans;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

.cart-feedback_submit {
  background: #ff4f00;
}

.cart-feedback_submit:hover {
  background: #e64d09;
}

._ico-number {
  margin-bottom: 11px;
}

.cart-feedback_popup ._ico-number:before {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0 , -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  color: black;
  border: 1px solid black;
  font-size: 14px;
  font-family: PT Sans;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.cart-feedback_popup ._ico-number span {
  display: block;
}

.cart-feedback_popup ._ico-number1 span,
.cart-feedback_popup ._ico-number2 span,
.cart-feedback_popup ._ico-number4 span {
  /*padding-top: 2px;*/
}

.cart-feedback_popup ._ico-number1:before {
  content: "1";
}

.cart-feedback_popup ._ico-number2:before {
  content: "2";
}

.cart-feedback_popup ._ico-number3:before {
  content: "3";
}

.cart-feedback_popup ._ico-number4:before {
  content: "4";
}

.cart-feedback_popup ._ico-number5:before {
  content: "5";
}

.cart-feedback__title {
  display: block;
  color: #000;
  font-size: 26px;
  font-family: PT Sans;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  text-align: center;
}

.cart-feedback__description {
  color: #000;
  font-size: 14px;
  font-family: PT Sans;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin: 15px 0 0 0;
}

#cart-feedback_popup {
  display: none;
}

.popup-for-user {
  position: fixed;
  opacity: 0;
  z-index: -10001;
  background: white;
  width: 499px;
  left: calc(50% - 280px);
  top: 30%;
  transition: 0.5s;
  padding-bottom: 40px;
  border-radius: 20px;
}

.popup-for-user.active {
  z-index: 10001;
  opacity: 1;
}

.popup-for-user .logo {
  width: 200px;
  display: block;
  text-align: center;
  margin: 0 auto;
}
.popup-for-user .for-user-close {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 19px;
  height: 19px;
  right: -23px;
  top: -23px;
  background: initial;
  border-radius: 0;
  border: none;
  transition: all .2s;
}

.popup-for-user_description {
  max-width: 323px;
  color: #000;
  text-align: center;
  font-size: 20px;
  font-family: PT Sans;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin: 20px auto 0;
}

.feedback__description--br {
  display: none;
}
@media (max-width: 768px) {

  .popup-for-user {
    width: calc(100% - 30px);
    transition: 0.5s;
    padding-bottom: 40px;
    left: 50%;
    transform: translate(-50%);
  }
  .feedback__description--br {
    display: block;
  }
  .popup-for-user_description {
    max-width: 317px;
    margin: 20px auto 0;
    font-size: 20px;
  }

  .popup-for-user .logo {
    width: 139px;
  }
}
.title-about--us {
  margin-top: 46px;
}

.about--us {
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .about--us {
    margin-bottom: 10px;
  }
  .title-about--us {
    margin-top: 21px;
  }
}

.card-basket ._col-image {
  border-left: none !important;
}

._col-number {
  position: relative;
  z-index: 38;
  background: white;
  border-right: 1px solid #d7d9da;
  max-width: 10px;
  color: #000;
  align-items: center;
  padding: 10px !important;
}

.grid__head ._col-number {
  background: inherit;
}

a._col-image.dsctp {
  border-left: none !important;
}

.js-cart-item .product_label {
  left: -1px;
}


@media (max-width: 1024px) {
  ._col-number {
    display: none;
  }
  .js-cart-item .product_label[data-type=sales_leader],
  .js-cart-item .product_label.supershare,
  .js-cart-item .card-basket .product_label {
    left: -28px;
  }
}

.container-500 {
  position: relative;
  top: 25%;
  max-width: 60%;
  padding: 0 12px;
  margin: 0 auto;
}

.error-500-description {
  padding-bottom: 50px;
  border-bottom: 1px solid silver;
}

.error-500-description__item  {
  max-width: 55%;
  text-align: center;
  padding-top: 30px;
  margin: 0 auto;
}

.error-500-description p:not(:last-child){
  margin-bottom: 20px;
}

.error-500-contacts {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-top: 30px;
}

@media (min-width: 768px) {
  .error-500-contacts .location-place:after {
    left: -40px;
    position: absolute;
    top: 16px;
  }
}

.error-500-contacts_item{
  padding: 0;
}

.error-500-contacts .delivery{
  text-align: left;
}

.emails--error-500 {
  position: relative;
}

.emails--error-500:after {
  background-image: url(/build/images/_email-icon-new.b204a2d4.png);
  background-position: 50%;
  background-repeat: no-repeat;
  content: "";
  height: 22px;
  left: -36px;
  position: absolute;
  top: -2px;
  width: 25px;
  background-size: contain;
}
._opr-a1--new {
  background-image: url(/build/images/a1-new.dccd9b55.png);
}
@media (max-width: 1200px) {
  .container-500 {
    position: relative;
    top: 25%;
    max-width: 100%;
    padding: 0 12px;
    margin: 0 auto;
  }
  .error-500-description span {
    margin-top: 20px;
  }
  .error-500-description span {
    max-width: 100%;
  }
  .error-500-description {
    padding-bottom: 30px;
  }
  .error-500-contacts {
    flex-direction: column;
    align-items: center;
  }
  .error-500-contacts_item {
    margin-bottom: 20px;
  }
  ._reviews_item .pagination_blk {
    padding-top: 10px;
  }

  .emails--error-500:after{
    margin-left: -7px;
  }

}

@media (max-width: 768px) {
  .emails--error-500 {
    margin-left: 34px;
  }
}

@media (min-width: 1024px) {
  .card-basket {
    padding: 0
  }
}

.flex-block {
  display: flex;
  align-items: center;
  margin: 18px 0;
}
.sort-price {
  margin-left: 10px;
}


@media (max-width: 1024px) {
  .search_blk {
    padding-bottom: 0;
  }

  .flex-block {
    margin: 18px 0 0 0;
  }

  .some-filters--flex {
    flex-direction: row;
  }

  .some-filters--flex input[type=checkbox]:not(checked) + label,
  .some-filters--flex input[type=radio]:not(checked) + label {
    display: flex;
    flex-basis: initial;
    align-items: center;
    margin: 2px 0 0 0;
  }

  .some-filters--flex .sf-title {
    display: none;
  }

}


.rating-blog {
  display: flex;
  gap: 47px;
}

.rating-blog__item {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.rating-blog__item img {
  width: 40px;
  height: 40px;
  margin-top: 10px;
}

.rating-blog__content {
  margin-left: 15px;
}

.rating-blog__content .c-rating-preview {
  margin: 0;
}
.rating-blog__content .c-rating-preview ._num {
  padding-top: 0;
  margin: 3px 10px 3px 0;
}

.rating-blog__content .c-rating-preview span {
  color: black;
  font-size: 17px;
  font-weight: 700;
}
.footer_blk .c-rating-preview ._stars {
  height: 19px;
  width: 91px;
}
.footer_blk .c-rating-preview ._active:before {
  height: 16px;
  width: 91px;
}

.rating-blog__item {
  color: black;
  text-decoration: none;
}

.rating-blog__item:hover {
  color: black;
  text-decoration: none;
}

.rating-blog__content p {
  font-size: 14px;
  line-height: 8px;
}

.rating-blog__title {
  font-size: 16px;
  line-height: 16px;
  white-space: nowrap;
}

.footer-social__networks {
  display: flex;
  align-items: center;
}

@media (max-width: 1024px) {
  .mob-design .c-google-rating {
    text-align: center;
  }
  .rating-blog {
    justify-content: space-between;
  }
  .c-google-rating .payment-logo {
    display: none;
  }
  .rating-blog__title {
    text-align: left;
  }
  footer .f-icons {
    justify-content: inherit;
  }
  footer .f-icons li {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .rating-blog {
    flex-direction: row;
  }
  .rating-blog__item {
    margin-bottom: 20px;
    margin-top: 20px;
  }
}

@media (max-width: 564px) {
  .rating-blog {
    flex-direction: row;
    align-items: center;
  }
  .rating-blog__item {
    margin: 0;
  }

  .rating-blog__title {
    text-align: left;
  }
  footer .c-google-rating {
    padding: 2em 0 1.1em;
  }
  .rating-blog__content p {
    text-align: left;
  }
  .rating-blog__title {
    font-size: 16px;
    line-height: 11px;
  }
  .rating-blog__content .c-rating-preview span {
    font-size: 14px;
  }
  .rating-blog__content {
    margin-left: 10px;
  }
  .rating-blog__item img {
    margin-top: 0;
  }
  .rating-blog__content p {
    font-size: 14px;
    line-height: 8px;
  }
  .rating-blog__content p span {
    font-size: 14px;
    line-height: 15px;
  }
  .yandex-img__mobile {
    width: 20px !important;
  }
}


.footer-container-desktop {
  display: flex;
  justify-content: space-between;
}

@media (min-width: 1024px) {
  .footer-social-list {
    display: none;
  }
  .rating-blog__content p {
    white-space: nowrap;
  }

}

/*.articles-cat_blk,
.user-select{
  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
}*/

.success-container {
  max-width: 850px;
  margin: -35px auto 0;
  text-align: center;
}


.success-container__text {
  text-align: center;
}

.success-container__text p {
  font-size: 27px;
  line-height: 40px;
  color: #000;
  font-weight: bold;
  text-align: center;
  margin: 0;
}

.success__img {
  margin: 40px 0;
  text-align: center;
}

.success__img img {
  width: 120px;
  height: 120px;
}


@media (max-width: 1024px) {
  .success-container {
    max-width: 390px;
    margin: -35px auto 25px;
    text-align: center;
  }

  .success__img {
    margin: 20px 0;
    text-align: center;
  }

  .success__img img {
    width: 60px;
    height: 60px;
  }

  .success-container__text p {
    font-size: 19px;
    line-height: 23px;
  }

}

.product-label-container{
  position: absolute;
  top: 11px;
  left: 11px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  z-index: 30;
}

.product-label__item{
  font-size: 15px;
  font-weight: 700;
  padding: 4.5px 10px;
  color: white;
  border-radius: 4px;
  box-shadow: 2px 2px 4px 1px rgb(0 0 0 / 25%);
}


.product-label__item:not(:last-child){
  margin-bottom: 5px;
}

.product-label--blue{
  background-color: #4285f3;
}

.product-label--red{
  background: linear-gradient(180deg, #F12605 0%, #DF2204 100%);
}

.product-label--green{
  background: linear-gradient(180deg, #4CF43D 0%, #16CA05 100%);
}

.product-label--yellow{
  background: linear-gradient(180deg,#f6e300,#fc9606);
}

.product-label--purple{
  background: linear-gradient(135deg, #FF6006 -15.03%, #FB6123 -2.2%, #EC6581 36.32%, #D75894 53.97%, #A336C6 86.06%, #6911FC 119.76%);
}


@media(min-width: 727px){
  .js-cart-item .product-label-container,
  ._col-image .dsctp .product-label-container{
    left: 33px;
    top: 9px;
  }
}

.default_product .small_row{
  height: 100%;
}


.default_product__availability{
  position: relative;
  margin: 0 0 10px 10px;
  font-size: 11pt;
  font-weight: 500;
  color: #1fbf2b;
  cursor: pointer;
  padding-left: 10px;
}

.default_product__availability span{
  border-bottom: 1px dashed #1fbf2b;
}

.default_product__availability:after{
  position: absolute;
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #1FBF2B;
  top: 50%;
  left: 0;
  transform: translate(0 , -50%);
}

.default_product__availability:active,
.default_product__availability:hover{
  color:  #15811d;
}

.default_product__availability:active:after,
.default_product__availability:hover:after{
  background: #15811d;
}

.default_product__availability:active span,
.default_product__availability:hover span{
  border-color: #15811d;
}

.availability-container{
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.small_row{
  height: 100%;
}

.product__availability--dec{
  font-size: 16px;
  margin: 0 0 6px 7px;
}

@media(max-width: 768px){
  .default_product .col_image{
    width: 134px;
  }
  .default_product .col_summary {
    flex: 1;
    margin-left: 13px;
    padding: 0 6px 0 6px;
  }
  .small_row .col_cart {
    height: auto;
  }
  .default_product__availability {
    margin: 0 0 10px 24px;
    padding-left: 10px;
  }
  .select--product{
    max-height: initial;
    font-size: 18px;
    line-height: 20px;
    height: 38px;
  }
  .mini_catalog .default_product .col_cart{
    margin: 0;
  }
  ._ico-close:before{
    margin-left: -1px;
  }


  .default_product .send-cart ._ico-cart,
  .orange_btn [class^="_ico-"] {
    vertical-align: 0;
  }
  .send-cart.orange_btn.full-width_btn{
    font-size: 18px;
  }

  .item--text.not-supplied{
    margin: 0 0 12px 0;
  }
}
@media (min-width: 500px) {
  .blue-block--product {
    margin-left: 0;
    padding-left: 15px;
    width: 100%;
  }
}
@media(max-width: 499px){
  .related_catalog .default_product .send-cart {
    height: 36px;
    margin-left: 14px;
    padding: 8px 0 0;
  }
}
@media(min-width: 768px){
  .product-image_blk ._counter [class^="_ico"]{
    height: 36px;
    width: 36px;
    line-height: 36px;
  }
}
@media (max-width: 499px){

  .related-catalog_blk .default_product .send-cart {
    padding: 7px 0 9px;
    margin-left: 14px;
  }
  .send-cart.orange_btn.full-width_btn {
    font-size: 18px;
  }
  [class$="_btn"] [class^="_ico-"] {
    vertical-align: 0;
  }
  .default_product .send-cart ._ico-cart{
    display: initial;
  }
  .related-catalog_blk .default_product .send-cart ._ico-cart {
    font-size: 16px;
    margin-right: 8px;
    vertical-align: 0;
  }
}
@media (min-width: 500px){
  .blue-block {
    margin-left: 0;
    padding-left: 15px;
  }
}
.blue-block {
  width: 100%;
}

.default_product .send-cart ._ico-cart{
  vertical-align: 0;
}
.seo-catalog_blk--product{
  margin-top: 20px;
}

.seo-catalog_blk.seo-catalog_blk--product a {
  margin: 10px 12px 0 0;
}

.seo-catalog_blk--product{
  margin-top: 20px;
}

.seo-catalog_blk.seo-catalog_blk--product a {
  margin: 10px 12px 0 0;
}

.btn-signUp{
  text-align: center;
  background: #ff4f00;
  color: white;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1000;
}

.mob-menu_categories.swipe-menu_mobile .btn-signUp a {
  font-size: 12pt;
  color: #fff;
}

.btn-signUp a{
  padding: 12px 24px;
}

.stick-label{
  font-weight: 200;
  font-size: 12px;
  line-height: 15px;
}

@media (max-width: 1024px) {
  .product-label-container{
    top: 10px;
    left: 10px;
  }
}

.js-cart-item .product-label-container{
  border-radius: 4px;
}

.js-cart-item .stick-label{
  font-weight: 200;
  font-size: 13px;
  line-height: 15px;
}

@media(min-width: 727px){
  .js-cart-item .product-label-container{
    left: 33px;
    top: 9px;
  }
  ._col-image.dsctp .product-label-container{
    align-items: flex-start;
    left: 28px;
    top: 4px;
  }
}

.main-tabs_title li.main-tabs_li.blk_headline--red.active,
.red-title,
.bestSeller .red--title{
  color: #f12605;
}

.filter-flex input.product--title {
  width: 350px;
}

.filter-flex select.product--category{
  width: 220px;
  max-height: 40px;
}

.filter-flex select.product--brand{
  width: 225px;
  max-height: 40px;
}

.filter-flex input,.filter-flex select{
  margin-right: 20px;
}

.search_blk-flex-block{
  display: flex;
  flex-wrap: wrap;
}

.search_blk-flex-block .filter-flex{
  flex-direction: row;
}

.btn-mobile{
  display: none;
}


.search_blk-flex-block input, .search_blk-flex-block select {
  border: 1px solid #d7d9da;
  -webkit-appearance: none;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow:0px 0px 2px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 10px;
  transition: all .2s;
}

.search_blk-flex-block .filter-flex input,
.search_blk-flex-block .filter-flex select {
  flex-basis: initial;
}

.search_blk-flex-block .filter-flex input::placeholder,
.search_blk-flex-block .filter-flex select::placeholder {
  font-weight: 600;
}


.search_blk-flex-block .filter-flex:first-child{
  padding-bottom: 0;
}

.some-filters-flex-start{
  justify-content: flex-start;
}

.btn-mobile{
  height: 38px;
}

input[type=checkbox].checkbox-bg:not(checked)+label:after {
  content: "";
  left: 3px;
  opacity: 1;
  position: absolute;
  top: 3px;
  transition: all .2s;
  width: 14px;
  height: 15px;
  border-radius: 2px;
}

input[type=checkbox].checkbox-bg:checked+label:after {
  content: "\e1011";
  left: 5px;
  opacity: 1;
  position: absolute;
  top: 7px;
  transition: all .2s;
  width: 14px;
  height: 15px;
  color: #F66625;
}

.form--search{
  max-width: initial;
}
.btn-dec{
  border-radius: 4px;
}

@media (max-width: 768px) {
  .btn-dec{
    display: none;
  }
  .btn-mobile{
    display: block;
    border-radius: 4px;
  }
  .some-filters-flex-start{
    justify-content: space-between;
    align-items: center !important;
  }
}

@media (max-width: 560px) {
  .search_blk-flex-block{
    justify-content: space-between;
  }

  .search_blk-flex-block__w100{
    width: 100%;
  }
  .search_blk-flex-block .filter-flex:first-child{
    padding-bottom: 23px;
  }
  .search_blk-flex-block__w48{
    width: 48%;
  }
  .search_blk-flex-block .filter-flex:not(:first-child){
    padding-bottom: 23px;
  }

  .filter-flex input.product--title,
  .filter-flex select.product--brand,
  .filter-flex select.product--category{
    width: 100%;
  }

  .btn-mobile{
    height: 38px;
    font-weight: 500;
    font-size: 16.8px;
    line-height: 20px;
  }
  .filter-flex input,.filter-flex select{
    margin-right: 0;
  }
}

.product_section .product-image_blk .image,
.product_section .product-image_blk .summary,
.tabs_blk .blk_body,
.mini_catalog .default_product,
.product_section .product-image_blk .-shadow_default{
  border-radius: 0 0 4px 4px;
}


@media (max-width: 768px) {
  ._counter input,
  ._counter [class^="_ico"],
  .default_product .send-cart{
    border-radius: 4px;
  }
}



.b24-widget-button-pulse.b24-widget-button-pulse-animate,
.b24-widget-button-show,
.b24-widget-button-hide{
  display: none;
}

.col_4.features__title {
  display: flex;
  justify-content: center;
  align-items: center;
}

.privacy-text {
  color: #a9a9a9;
  margin-top: 5px;
  max-width: 345px !important;
  text-align: right;
}

.tags-list{
  display: flex;
  overflow:hidden;
}

.seo-catalog_blk .blk_body.tags-content{
  margin-bottom: 12px;
}

.tags-list-active{
  margin-bottom: 17px;
}

.seo-catalog_blk .blk_body.tags-list-active.tags-content{
  margin-bottom: 0;
}

.more-tags-btn{
  margin-top: 3px;
}

.under-tags-btn,
.more-tags-btn{
  display: none;
  font-size: 13px;
  line-height: 24px;
  padding: 0 5px;
  color: #ff4f00;
  cursor: pointer;
  white-space: nowrap;
}

.under-tags-btn span,
.more-tags-btn span{
  display: inline-block !important;
  vertical-align: middle;
}


.tags-list ._ico-arr-top:before{
  font-size: 6px;
}

.more-tags-btn ._ico-arr-top{
  position: relative;
  top: 3px;
  transform: rotate(180deg);
}

.under-tags-btn ._ico-arr-top{
  position: relative;
  top: -3px;
  left: 1px;
}

.tags-list--mobile{
  display: none;
}

@media (max-width: 768px) {
  .tags-list{
    height: auto;
  }
  .under-tags-btn,
  .more-tags-btn{
    display: none;
  }
  .tags-content{
    display: flex;
    overflow: scroll;
    padding-bottom: 10px;
  }
  .tags-content a{
    white-space: nowrap;
    margin: 6px 12px 0 0px;
  }
  .tags-list-active,
  .seo-catalog_blk .blk_body.tags-content{
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .filter_seo_block,
  .tags-list{
    padding-bottom: 0;
  }

  .tags-list--desc{
    display: none;
  }
  .tags-list--mobile{
    display: flex;
    width: 100%;
    margin-top: 15px;
  }
  .sort-price {
    margin: 3px 0 0;
  }
}


.tags-content::-webkit-scrollbar {
  display: none;
  width: 0;
  height: 0;
  padding: 0;
}

.tags-content::-webkit-scrollbar-track {
  background-color: transparent;
}

.tags-content::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.checkout_section .control-row,
.checkout-setting_blk .change .control-row,
.checkout-report_blk .elem{
  max-width: 455px;
}

.wrapper-delivery_info ymaps {
  width: 100% !important;
}


@media (max-width: 768px) {
  .ymaps-2-1-79-search__suggest-item {
    white-space: normal !important;
  }
  .ymaps-2-1-79-search__suggest-item {
    line-height: 15px;
    margin-top: 15px;
  }
}

.eye-show-password{
  width: 30px;
  height: 30px;
  cursor: pointer;
  background-size: cover;
  position: absolute;
  right: 10px;
  top: 4px;
}

.eye-show-password:not(.active){
  background-image: url(/build/images/eye-show-password.d03332a2.svg);
}

.eye-show-password.active{
  background-image: url(/build/images/eye-hide-password.f55c034f.svg);
}

.no-wrap{
  white-space: nowrap;
}

@media(max-width: 768px){
  .my-order_blk{
    margin-bottom: 15px;
  }
  span._text {
    display: block;
    text-align: left;
    padding-left: 34px;
  }
  ._empty-item{
    margin-left: 28px;
  }
}

.footer--text{
  text-align: center;
}

.rating-blog__item-img{
  width: 40px;
}

._ico-zoom-fankibox:before{
  position: absolute;
  content: "\e100e";
  right: 5px;
  top: 5px;
  color: #ff4f00;
}

._ico-zoom-fankibox:hover:before{
  color: #ff4f00;
}

@media (min-width: 1024px) {
  ._head-info{
    display: flex;
    flex-direction: column-reverse;
    height: 100%;
    justify-content: center;
  }

  ._head-phone li{
    font-size: 18.2px;
    font-weight: 700;
  }

  ._head-info .delivery a{
    text-decoration-skip-ink: none;
  }

}

.border_b{
  position: relative;
}

.border_b:before{
  position: absolute;
  content: "";
  width: 100%;
  bottom: -10px;
  height: 1px;
  background: #d7d9da;
}

input[type="checkbox"], input[type="radio"] {
  border: none;
  cursor: pointer;
  width: auto;
  opacity: 0;
}


.payment_method__text{
  max-width: 455px;
  margin-left: 20px;
  color: #a9a9a9;
}

.payment_method--container{
  position: relative;
}

@media (min-width: 1250px) {
  .payment_method__text{
    position: absolute;
    width: 455px;
    right: 0;
    top: 0;
  }
  .control-label--big{
    width: 72%;
  }
}
@media(max-width: 1249px){
  .payment_method--container{
    flex-direction: column;
  }
  .payment_method__text{
    margin: 10px 0 0;
  }
}

input[name="do_not_callback"] + label{
  margin: 2px 0 0 0 !important;
}

input[name="do_not_callback"]:first-child + label{
  margin: 2px 30px 0 0 !important;
}

input[name="do_not_callback"]+label:after{
  position: absolute;
  left: 4px;
  top: 4px;
  height: 12px;
  width: 12px;
  background: #ff4f00;
  opacity: 0;
  transition: 0.3s;
}

input[name="do_not_callback"]:checked+label:after{
  opacity: 1;
}


._phone-list__text{
  text-align: center;
}

._phone-list__text a{
  color: #FF4F00;
  text-decoration-line: underline;
  text-decoration-skip-ink: none;
}

.img-list span{
  display: inline-block;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-position: center;
}

._opr-a1{
  background-image: url(/build/images/_a1-new.e7d93f2b.png);
}

._opr-mts{
  background-image: url(/build/images/_mts-new.06097d69.png);
}

.img-list ._opr-smile{
  background-image: url(/build/images/smile.6cedec20.svg);
  width: 12px;
  background-position: center;
}

.header-time-working p{
  margin-top: 11px;
}

.header-phone-min{
  font-size: 25px;
  line-height: 112.02%;
  font-weight: 700;
  color: #2A2A2A;
  text-decoration: none;
  margin-right: 12px;
}

.arrow-popup{
  display: flex;
  cursor: pointer;
  transition: 0.3s;
}

.large_row>[class^=col_].p-0 {
  padding: 0 ;
}

._head-info .item{
  padding: 0 24px;
}


.header-popup{
  display: none;
  position: absolute;
  width: 200px;
  top: calc(100% + 12px);
  left: -7px;
  background: #FFFFFF;
  border-radius: 5px;
  z-index: 31;
  padding: 0 2px;
  transition: .3s;
}


.header-popup:before{
  position: absolute;
  content: "";
  top: -13px;
  height: 13px;
  width: 100%;
}

.header-popup__item{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  padding: 0 7px;
}

.header-popup__item ._phone-list{
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.header-popup__item ._phone-list li:before{
  content: none;
}

.header-popup__item ._phone-list ._number,
.header-popup__item ._phone-list li{
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 13.5px;
  line-height: 145.5%;
  color: #2A2A2A;
}

.header-popup__item ._phone-list li:not(:first-child){
  margin-top: 5px;
}

.header-popup__item ._phone-list ._number{
  text-decoration: none;
  font-size: 13.5px;
}

.header-popup__item ._phone-list__text a{
  font-size: 15px;
  line-height: 120%;
  text-align: left;
}

._phone-list__text--black{
  font-weight: 700;
  font-size: 13.501px;
  line-height: 145.5%;
  color: #2A2A2A;
}

.social-networks{
  display: flex;
  gap: 17px;
  padding-top: 18px;
  margin: 0 auto;
}

.social-networks a{
  color: #2A2A2A;
  font-size: 13.5px;
  text-decoration: none;
  font-weight: 700;
}

.social-networks__item{
  display: flex;
  align-items: center;
}

.header-popup__item ._opr-mts,
.header-popup__item ._opr-a1{
  width: 15px;
  height: 15px;
  display: inline-block;
  margin-right: 10px;
}

.header-popup__item ._phone-list__text{
  text-align: left;
}

._phone-list__text--black,
._phone-list__text{
  font-weight: 700 !important;
}


@media(max-width: 1150px){
  .mobil-phones_popup{
    padding: 10px 15px 15px;
  }
  .header-popup{
    box-shadow: none;
    border-bottom: 1px solid hsla(0,0%,45%,.5);
  }
  .mobil-phones_popup ._head-phone ._opr-mts{
    margin-left: 0;
  }
  .mobil-phones_popup ul+ul li{
    margin-top: 0;
  }

  .mobil-phones_popup ._head-phone [class^=_opr] {
    border-radius: 5px;
    height: 20px;
    margin-right: 10px;
    top: -1px;
    width: 20px;
  }

  .mobil-phones_popup .header-phone-min{
    font-size: 34px;
    margin-left: 15px;
    text-decoration: none;
  }

  .item-mode--mobile{
    display: flex;
    justify-content: center;
  }

  .mobil-phones_popup ._head-phone li+li {
    margin-top: 5px;
  }
  .header-popup__item ._phone-list {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    padding: 0;
    margin: 12px 0;
  }
  .social-networks{
    padding: 12px 0;
  }
  .social-networks svg{
    width: 40px;
    height: 40px;
  }
  .social-networks a{
    font-size: 16.5px;
  }
  .working-hours{
    margin: 0;
    padding:  15px 0 0 14px;
  }
  .working-hours:after{
    left: 0;
    top: 18px;
    width: 23px;
    height: 23px;
  }

  .working-hours_item:first-child strong{
    color: #FF4F00;
  }
  .working-hours_item:not(:first-child){
    text-align: left;
    margin-top: 4px;
  }
  .working-hours strong {
    font-size: 18.8px;
  }
  .img-list[class^=_opr]{
    margin-right: 5px !important;
  }
}

._head-phone ._number{
  margin-left: 5px;
}

._head-phone{
  display: flex;
  align-items: center;
  height: 100%;
}

@media(max-width: 1024px){
  ._head-phone{
    flex-direction: column;
  }
}
@media(min-width: 1024px){
  .header_panel .large_row{
    margin: 0 -20px;
  }

  .header_panel .cart_blk{
    padding: 2px;
  }
}


.promo-block{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.promo-input{
  width: 150px;
  font-weight: 500;
  font-size: 14px;
  line-height: 112%;
  color: #A5A4A4;
  padding: 5px 5px 5px 8px;
  border-radius: 3px 0 0 3px;
  border: 2px solid #FF4F00;
  border-right: none;
}

.promo-btn{
  font-size: 14px;
  line-height: 112%;
  color: #FFFFFF;
  background: #FF4F00;
  border: 2px solid #FF4F00;
  border-left: none;
  border-radius: 0 3px 3px 0;
  padding: 5px 5px 5px 7px;
  transition: 0.3s;
}

.cart-clear__trg{
  font-weight: 500;
  font-size: 14px;
  line-height: 112%;
  text-decoration-line: underline;
  color: #FF4F00;
  text-decoration-skip-ink: none;
}

.warning-message{
  font-size: 14px;
  margin-bottom: 12px;
}

.item-price-basket .title{
  font-weight: 400;
  font-size: 16px;
  line-height: 112%;
  color: #787878;
}

.flex-column{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.cart-warning{
  margin-bottom: 12px;
}

._total-old-price{
  font-size: 14px !important;
  line-height: 17px;
  text-decoration: line-through;
  color: #787878;
}

._total-discount{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 112%;
  text-align: right;
  color: #FF4F00;
}

.item--old-price{
  margin-top: 3px !important;
}

.item-price-basket .content{
  font-size: 17px;
}

._total-btn-container{
  display: flex;
  align-items: center;
  margin-top: 20px;
}

._total-btn-container .col_{
  padding-right: 0;
}

.cart_pdf_new{
  font-weight: 400;
  font-size: 14px;
  line-height: 112%;
  text-align: right;
  text-decoration-line: underline;
  color: #777777;
  background: url(/build/images/pdf-img.28d838c5.svg) no-repeat right;
  padding: 8px 36px 8px 0;
  border: none;
  box-shadow: none;
  text-decoration-skip-ink: none;
}

.promocode-wrong-item{
  display: flex;
  align-items: center;
  font-size: 16px;
  background-color:  #db8383;
  color: white;
  float: left;
  margin-right: 15px;
  padding: 3px;
  border-radius:3px;
}

.promocode-activated-item{
  display: flex;
  align-items: center;
  font-size: 16px;
  background-color: #94db82;
  color: white;
  float: left;
  margin-right: 15px;
  padding: 3px;
  border-radius: 3px;
}

.block-promo{
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.promo-wrapper{
  display: flex;
  align-items: stretch;
}
.cart_pdf_new--mobile{
  display: none;
}

.flex-column .col_full{
  padding: 0;
}

.promocode-wrong__cross{
  display: flex;
  width: 15px;
  margin: 2px 0 0 3px;
}

.promocode-activated____cross{
  display: flex;
  width: 15px;
  margin: 2px 0 0 3px;
}

.promocode-activated____cross svg,
.promocode-wrong__cross svg{
  width: 15px;
  height: 15px;
}

@media (max-width: 499px){
  .cart-total_blk .col_ {
    bottom: 0;
    left: 0;
    margin-left: 0;
  }
  ._total .item + .item {
    margin-top: 3px;
  }
}

@media (max-width: 768px){
  .promo-block {
    align-items: flex-start;
    flex-direction: column-reverse;
    justify-content: flex-start;
  }
  .promo-wrapper{
    margin-top: 15px;
  }

  .cart-total_blk .col_full{
    width: 100%;
  }

  .cart_pdf_new{
    width: 150px;
  }
  .download-cart {
    width: 100%;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
    padding-bottom: 0;
  }
  .flex-column{
    align-items: flex-start;
  }
  .promocode-activated-item,
  .promocode-wrong-item{
    margin: 8px 0 10px 0;
  }
  .block-promo{
    margin: -10px 0 20px 0;
  }
  .promo-input,
  .promo-btn {
    border: 1px solid #FF4F00;
  }
  .cart_pdf_new{
    display: none;
  }
  .cart_pdf_new--mobile{
    display: block;
  }
  ._total-btn-container{
    margin: 0;
    height: 0;
  }
}

#address_pickup input:checked+label:after{
  position: absolute;
  width: 12px;
  height: 12px;
  top: 4px;
  left: 4px;
  background: #ff4f00;
}

#address_pickup label{
  margin: 6px 10px 6px 0;
}

.block-promo .cart-clear__popup{
  padding: 25px 0 25px 0;
}

@media (max-width: 1024px) {
  .head-links_mobile .head-links_mobile__column-icon .mobil-phones_popup-btn:before {
    background-color: transparent;
  }
}

.b24-widget-button-inner-container{
  transform: none !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.b24-widget-button-position-bottom-right{
  width: 60px;
  height: 60px;
  right: 18px !important;
  bottom: 84px !important;
}

.b24-widget-button-inner-block{
  width: 48px !important;
  height: 48px !important;
}

.b24-widget-button-inner-mask{
  width: 60px !important;
  height: 60px !important;
  min-width: inherit !important;
  top: inherit !important;
  left: inherit !important;
}

.b24-widget-button-block {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px!important;
  height: 60px !important;
  border-radius: 0 !important;
}

.b24-crm-button-icon-active {
  width: 20px !important;
}

.b24-widget-button-inner-item{
 animation: none !important;
}

.b24-widget-button-close-item{
  transform: scale(0.6);
}

.delivery-anchor{
  display: flex;
  margin-bottom: 22px;
}

.delivery-anchor__item:not(:last-child){
  margin-right: 32px;
}

.delivery-anchor__item a{
  font-weight: 700;
  font-size: 14px;
  text-decoration-skip-ink: none;
  text-decoration: underline;
  color: #747474;
  transition: 0.3s;
}

.delivery-anchor__item.active a{
  font-weight: 700;
  font-size: 14px;
  text-decoration-skip-ink: none;
  text-decoration: none;
  color: #FF4F00;
}

.delivery-anchor__item a:hover{
  font-weight: 700;
  font-size: 14px;
  text-decoration-skip-ink: none;
  text-decoration: underline;
  color: #FF4F00;
}

.delivery-content{
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  border: 1px solid #FF4F00;
  overflow: hidden;

}

.delivery-content__item{
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 109px;
  background: radial-gradient(182.46% 182.46% at 50% 50%, #F1FEFD 0%, #FFFFFF 100%);
  padding: 0 33px 0 42px;
}

.delivery-content__item:not(:first-child){
  border-top: 1px solid #FF4F00;
}

.delivery-content-title{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 800;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.delivery-content-title span{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 800;
  display: inline-block;
  font-weight: 800;
  font-size: 19.5022px;
  line-height: 112.52%;
  color: #FF4F00;
  text-shadow: 0px 8.86463px 17.7293px rgba(0, 0, 0, 0.1);
}

.delivery-content-title span.delivery-title--big{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 30.14px;
  line-height: 33.91px;
}

.delivery-content-description{
  max-width: 337px;
  padding-right: 34px;
  font-weight: 400;
  font-size: 14px;
  line-height: 126.52%;
  color: #2B2B2B;
  margin-right: 12%;
}

.delivery-content-description--big{
  padding-right: 8px;
}

.delivery-content-description--midlLink{
  margin-top: 10px;
  display: inline-block
}

.delivery-banner{
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 20px 0 10px 0;
  padding: 34px 0 30px 21px;
  background: #F4FEFE url(/build/images/bg-banner-delevery-new.6aacd53e.svg) 74% 11% no-repeat;
  border: 1px solid #FE5102;
  border-radius: 12px;
}

.delivery-banner-img{
  max-width: 488px;
  max-height: 200px;
  width: 100%;
  height: 100%;
  margin-right: 10px;
}

.delivery-banner-content{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 24px;
}

.delivery-banner-img img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin-top: 15px;
}

.delivery-banner-text{
  display: flex;
  flex-direction: column;
}

.delivery-banner-text--delivery{
  font-family: 'Roboto',sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  line-height: 96.69%;
  color: #FE5102;
  text-transform: uppercase;
}

.delivery-banner-text--strong{
  max-width: 170px;
  font-family: 'Roboto',sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 96.69%;
  color: #202020;
}

.delivery-banner-text--mini{
  font-family: 'Roboto',sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 30px;
  color: #000000;
  margin-top: 3px;
}

.delivery-img-block{
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 12px 0 6px;
}

.delivery-banner-text--mini ._orange-text{
  font-weight: 900;
  font-size: 36px;
  color: #FE5102;
}

.delivery-banner-btn{
  font-family: 'Roboto',sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 37px;
  line-height: 43px;
  letter-spacing: 0.04em;
  color: #FFFFFF;
  padding: 13px 35px 10px;
  margin-top: 34px;
  background: #FE5102;
  border-radius: 60px;
}

._title-block{
  display: flex;
  align-items: center;
  margin: 0 0 26px;
}

._title-block span{
  margin-right: 10px;
}

.delivery-price-text{
  font-weight: 400;
  font-size: 14px;
  line-height: 126%;
  color: #2B2B2B;
}

.delivery-price-text a{
  font-size: 14px;
  line-height: 126%;
  text-decoration: none;
}

.delivery-time-content{
  display: flex;
  justify-content: space-between;
  border: 1px solid #FF4F00;
  border-radius: 12px;
}

.delivery-time-list{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 36%;
  list-style-type: none;
  padding: 23px 0;
}

.delivery-time-list__item{
  font-weight: 400;
  font-size: 16px;
  line-height: 88.02%;
  text-align: center;
  color: #2B2B2B;
}

.delivery-time-list__item:before{
  content: none;
}

.delivery-time-list__item:first-child{
  font-size: 19px;
  line-height: 88.02%;
  font-weight: 700;
}

.delivery-time-list__item:not(:last-child){
  margin: 0 0 13px;
}

.delivery-time-list__item:last-child{
  margin: 0;
}

.delivery-time-list__item:not(:first-child){
  width: 90px;
  text-align: right;
}

.delivery-time-description{
  width: 64%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: radial-gradient(182.46% 182.46% at 50% 50%, #F1FEFD 0%, #FFFFFF 100%);
  border-left: 1px solid #FF4F00;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
}

.delivery-time-description p{
  font-weight: 400;
  font-size: 22.3457px;
  line-height: 126.5%;
  text-align: center;
  color: #2B2B2B;
  margin: 0;
}

.delivery-time-description p.delivery-time-description--main{
  font-weight: 700;
  font-size: 22.35px;
  line-height: 28.27px;
  color: #FF4F00;
}

.delivery-time-description-top{
  margin-bottom: 33px;
}

.delivery-unloading{
  padding-top: 30px;
}

.delivery-unloading-content{
  display: flex;
  align-items: center;
  padding: 10px 0 17px;
}

.delivery-unloading-description{
  background: radial-gradient(182.46% 182.46% at 50% 50%, #F1FEFD 0%, #FFFFFF 100%);
  border: 1px solid #FF4F00;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 20px 18px;
}

.unloading-description-list{
  margin: 0;
}

.unloading-description-list__item{
  max-width: 363px;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  margin: 0;
}

.unloading-description-list__item:not(:last-child){
  margin-bottom: 25px;
}

.unloading-description-list__item:before{
  width: 6px;
  height: 6px;
  top: 5px;
}

.unloading-price-list{
  width: 100%;
  padding: 0;
  margin: 0;
}

.unloading-price__item:before{
  content: none;
}

.unloading-price__item{
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0;
  padding: 9px;
}

.unloading-price__item:not(:last-child){
  border-bottom: 1px solid #A5B7B6;
}

.unloading-product{
  padding-left: 16px;
  font-size: 14px;
  line-height: 88.02%;
}

.unloading-price{
  padding-right: 25px;
  text-align: right;
  font-size: 14px;
  line-height: 88.02%;
}

.delivery-unloading-price{
  flex-grow: 1;
}

.delivery-payment{
  padding-top: 30px;
}

.delivery-payment-list{
  margin-top: 26px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
}

.delivery-payment__item-header{
  width: 100%;
  padding: 11px 33px 14px 23px;
  border: 1px solid transparent;
}

.delivery-payment__item:not(:last-child){
  border-bottom: 1px solid #A5B7B6;
}

.delivery-payment__item.no-border{
  border-bottom: none;
}

.delivery-payment__item-header{
  position: relative;
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 18px;
  line-height: 88.02%;
  color: #2B2B2B;
  cursor: pointer;
}

.delivery-payment__img-container{
  display: flex;
  align-items: center;
}

.card-pay-full-list{
  position: relative;
  top: 3px;
  margin-left: 20px;
}

.delivery-payment__item-header img{
  display: inline-block;
  margin-right: 4px;
}

.delivery-payment-arrow{
  position: absolute;
  right: 43px;
  top: 10px;
}

.delivery-payment__item-header.active .delivery-payment-arrow{
  transform: rotate(180deg);
  top: 14px;
}

.delivery-payment__item-header.active{
  background: radial-gradient(182.46% 182.46% at 50% 50%,#f1fefd 0,#fff 100%);
  border: 1px solid #ff4f00;
  border-radius: 15px 15px 0 0;
  padding: 12px 33px 14px 23px;
}

.delivery-payment__item-dropdown{
  display: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 126%;
  color: #2B2B2B;
  background: radial-gradient(182.46% 182.46% at 50% 50%,#f1fefd 0,#fff 100%);
  border: 1px solid #ff4f00;
  border-top: none;
  padding: 20px 33px 20px 23px;
  border-radius: 0 0 15px 15px;
}

.delivery-payment__item.active{
  border-bottom: none;
  margin-top: -1px;
}

.delivery-discounts{
  padding-top: 30px;
}

.delivery-discounts-text{
  font-weight: 400;
  font-size: 14px;
  line-height: 126.5%;
  color: #2A2A2A;
  margin-bottom: 20px;
}

.delivery-discounts-title{
  font-weight: 700;
  font-size: 18px;
  line-height: 182.5%;
}

.delivery-card__item{
  display: flex;
  align-items: flex-start;
  padding: 19px 0;
}

.delivery-card__item:first-child{
  border-bottom: 1px solid #A5B7B6;
}

.discounts-card-img{
  width: 246px;
  flex-shrink: 0;
  padding: 0 30px;
}

.delivery-card-title{
  font-weight: 700;
  font-size: 18px;
  line-height: 126.52%;
  margin: 12px 0 23px;
}

.delivery-card-text{
  font-size: 14px;
  font-weight: 400;
}

.delivery-card-text span{
  color: #FF4F00;
}

.delivery-card__description{
  padding-left: 44px;
}

.return-product{
  padding-top: 30px;
}

.return-product-text{
  font-weight: 400;
  font-size: 14px;
  line-height: 126%;
  color: #2A2A2A;
  margin-bottom: 15px;
}

.return-product-content{
  background: #FFFFFF;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
}

.return-product-title{
  border-radius: 12px;
  padding: 11px 33px 14px 23px;
  width: 100%;
  align-items: center;
  color: #2b2b2b;
  cursor: pointer;
  display: flex;
  font-size: 18px;
  font-weight: 700;
  line-height: 88.02%;
  position: relative;
}

.return-product-title.active{
  background: radial-gradient(182.46% 182.46% at 50% 50%,#f1fefd 0,#fff 100%);
  border: 1px solid #ff4f00;
  border-radius: 12px 12px 0 0;
}

.return-product-dropdown{
  border: 1px solid #ff4f00;
  border-radius: 0 0 12px 12px;
  border-top: none;
  color: #2b2b2b;
  display: none;
  font-size: 14px;
  font-weight: 400;
  line-height: 126%;
  padding: 20px 33px 20px 23px;
  background: radial-gradient(182.46% 182.46% at 50% 50%,#f1fefd 0,#fff 100%);
}

.return-product-title.active .delivery-payment-arrow {
  top: 14px;
  transform: rotate(180deg);
}


.documents-block{
  padding: 30px 0 25px;
}

.features_blk.features_blk-document{
  background: #FFFFFF;
  box-shadow: 0px 9.95255px 19.9051px rgba(0, 0, 0, 0.1);
  border-radius: 11.9431px;
}

.features_blk.features_blk-document [class^="col_"] + [class^="col_"] {
  border-left: 1px solid #A5B7B6;
}

.col_4.features__title{
  padding: 10px 0 8px;
}

.features_blk.features_blk-document ._row:nth-child(2n-1){
  background: white;
  border-bottom: 0.995255px solid #A5B7B6;
}

.delivery-content-container{
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
}

.blk_body.delivery-price--body {
  border-radius: 3px;
  overflow: hidden;
}

.delivery-time-description-bottom{
  max-width: 425px;
}

@media(max-width: 1150px){

  .delivery-anchor{
    display: flex;
    margin-bottom: 22px;
  }

  .delivery-anchor__item:not(:last-child){
    margin-right: 32px;
  }

  .delivery-anchor__item a{
    font-weight: 700;
    font-size: 14px;
    text-decoration-skip-ink: none;
    text-decoration: underline;
    color: #747474;
    transition: 0.3s;
  }

  .delivery-anchor__item.active a{
    font-weight: 700;
    font-size: 14px;
    text-decoration-skip-ink: none;
    text-decoration: none;
    color: #FF4F00;
  }

  .delivery-anchor__item a:hover{
    font-weight: 700;
    font-size: 14px;
    text-decoration-skip-ink: none;
    text-decoration: underline;
    color: #FF4F00;
  }

  .delivery-content{
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    border: 1px solid #FF4F00;
    overflow: hidden;

  }

  .delivery-content__item{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 109px;
    background: radial-gradient(182.46% 182.46% at 50% 50%, #F1FEFD 0%, #FFFFFF 100%);
    padding: 0 33px 0 42px;
  }

  .delivery-content__item:not(:first-child){
    border-top: 1px solid #FF4F00;
  }

  .delivery-content-title{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 3px;
  }
  .delivery-content-title span{
    display: inline-block;
    font-weight: 800;
    font-size: 19.5022px;
    line-height: 112.52%;
    color: #FF4F00;
    text-shadow: 0px 8.86463px 17.7293px rgba(0, 0, 0, 0.1);
    margin-right: 5px;
  }

  .delivery-content-title span.delivery-title--big{
    font-size: 30.14px;
    line-height: 33.91px;
  }

  .delivery-content-description{
    max-width: 337px;
    padding-right: 34px;
    font-weight: 400;
    font-size: 14px;
    line-height: 126.52%;
    color: #2B2B2B;
    margin-right: 12%;
  }

  .delivery-content-description--big{
    padding-right: 0;
  }

  .delivery-content-description--midlLink{
    margin-top: 10px;
    display: inline-block
  }

  .delivery-price{
    padding-bottom: 30px;
  }

  ._title-block{
    display: flex;
    align-items: center;
    margin-bottom: 26px;
  }

  ._title-block span{
    margin-right: 10px;
  }

  .delivery-price-text{
    font-weight: 400;
    font-size: 14px;
    line-height: 126%;
    color: #2B2B2B;
  }

  .delivery-price-text a{
    font-size: 14px;
    line-height: 126%;
    text-decoration: none;
  }

  .delivery-time{
    padding-top: 37px;
  }

  .delivery-time-content{
    display: flex;
    justify-content: center;
    border: 1px solid #FF4F00;
    border-radius: 4px;
  }

  .delivery-time-list{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 36%;
    list-style-type: none;
    padding: 23px 0;
  }

  .delivery-time-list__item{
    font-weight: 400;
    font-size: 16px;
    line-height: 88.02%;
    text-align: center;
    color: #2B2B2B;
  }

  .delivery-time-list__item:before{
    content: none;
  }

  .delivery-time-list__item:first-child{
    font-size: 18px;
    line-height: 88.02%;
    font-weight: 700;
  }

  .delivery-time-list__item:not(:last-child){
    margin: 0 0 13px;
  }

  .delivery-time-list__item:last-child{
    margin: 0;
  }

  .delivery-time-list__item:not(:first-child){
    width: auto;
    text-align: right;
  }

  .delivery-time-description{
    width: 64%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: radial-gradient(182.46% 182.46% at 50% 50%, #F1FEFD 0%, #FFFFFF 100%);
    border-left: 1px solid #FF4F00;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
  }

  .delivery-time-description p{
    font-weight: 400;
    font-size: 22.3457px;
    line-height: 126.5%;
    text-align: center;
    color: #2B2B2B;
    margin: 0;
  }

  .delivery-time-description p.delivery-time-description--main{
    font-size: 22.35px;
    line-height: 28.27px;
    color: #FF4F00;
  }

  .delivery-time-description-top{
    margin-bottom: 33px;
  }

  .delivery-unloading{
    padding-top: 67px;
  }

  .delivery-unloading-content{
    display: flex;
    align-items: center;
    padding: 10px 0 17px;
  }

  .delivery-unloading-description{
    background: radial-gradient(182.46% 182.46% at 50% 50%, #F1FEFD 0%, #FFFFFF 100%);
    border: 1px solid #FF4F00;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 20px 18px;
  }

  .unloading-description-list{
    margin: 0;
  }

  .unloading-description-list__item{
    max-width: 363px;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    margin: 0;
  }

  .unloading-description-list__item:not(:last-child){
    margin-bottom: 25px;
  }

  .unloading-description-list__item:before{
    width: 6px;
    height: 6px;
    top: 5px;
  }

  .unloading-price-list{
    width: 100%;
    padding: 0;
    margin: 0;
  }

  .unloading-price__item:before{
    content: none;
  }

  .unloading-price__item{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0;
    padding: 9px;
  }

  .unloading-price__item:not(:last-child){
    border-bottom: 1px solid #A5B7B6;
  }

  .unloading-product{
    padding-left: 16px;
    font-size: 14px;
    line-height: 88.02%;
  }

  .unloading-price{
    padding-right: 25px;
    text-align: right;
    font-size: 14px;
    line-height: 88.02%;
  }

  .delivery-unloading-price{
    flex-grow: 1;
  }

  .delivery-payment{
    padding-top: 66px;
  }

  .delivery-payment-list{
    margin-top: 26px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
  }

  .delivery-payment__item-header{
    width: 100%;
    padding: 11px 33px 14px 23px;
  }

  .delivery-payment__item:not(:last-child){
    border-bottom: 1px solid #A5B7B6;
  }

  .delivery-payment__item-header{
    position: relative;
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 18px;
    line-height: 88.02%;
    color: #2B2B2B;
    cursor: pointer;
  }

  .delivery-payment-arrow{
    position: absolute;
    right: 43px;
    top: 10px;
  }

  .delivery-payment__item-header.active .delivery-payment-arrow{
    transform: rotate(180deg);
    top: 14px;
  }

  .delivery-payment__item-header.active{
    background: radial-gradient(182.46% 182.46% at 50% 50%,#f1fefd 0,#fff 100%);
    border: 1px solid #ff4f00;
    border-radius: 4px 4px 0 0;
  }

  .delivery-payment__item-dropdown{
    display: none;
    font-weight: 400;
    font-size: 15px;
    line-height: 88.02%;
    color: #2B2B2B;
    background: radial-gradient(182.46% 182.46% at 50% 50%,#f1fefd 0,#fff 100%);
    border: 1px solid #ff4f00;
    border-top: none;
    padding: 20px 33px 20px 23px;
    border-radius: 0 0 4px 4px;
  }

  .delivery-payment__item.active{
    border-bottom: none;
    margin-top: -1px;
  }

  .delivery-discounts{
    padding-top: 66px;
  }

  .delivery-discounts-text{
    font-weight: 400;
    font-size: 14px;
    line-height: 126.5%;
    color: #2A2A2A;
    margin-bottom: 20px;
  }

  .delivery-discounts-title{
    font-weight: 700;
    font-size: 18px;
    line-height: 182.5%;
  }

  .delivery-card__item{
    display: flex;
    align-items: flex-start;
    padding: 19px 0;
  }

  .delivery-card__item:first-child{
    border-bottom: 1px solid #A5B7B6;
  }

  .discounts-card-img{
    flex-shrink: 0;
    padding: 0 30px;
  }

  .delivery-card-title{
    font-weight: 700;
    font-size: 18px;
    line-height: 126.52%;
    margin: 12px 0 23px;
  }

  .delivery-card-text{
    font-size: 14px;
    font-weight: 400;
  }

  .delivery-card-text span{
    color: #FF4F00;
  }

  .delivery-card__description{
    padding-left: 44px;
  }

  .return-product{
    padding-top: 66px;
  }

  .return-product-text{
    font-weight: 400;
    font-size: 14px;
    line-height: 126%;
    color: #2A2A2A;
    margin-bottom: 15px;
  }

  .return-product-content{
    background: #FFFFFF;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  }

  .return-product-title{
    border-radius: 4px;
    padding: 11px 33px 14px 23px;
    width: 100%;
    align-items: center;
    color: #2b2b2b;
    cursor: pointer;
    display: flex;
    font-size: 18px;
    font-weight: 700;
    line-height: 88.02%;
    position: relative;
  }

  .return-product-title.active{
    background: radial-gradient(182.46% 182.46% at 50% 50%,#f1fefd 0,#fff 100%);
    border: 1px solid #ff4f00;
    border-radius: 4px 4px 0 0;
  }

  .return-product-dropdown{
    border: 1px solid #ff4f00;
    border-radius: 0 0 4px 4px;
    border-top: none;
    color: #2b2b2b;
    display: none;
    font-size: 14px;
    font-weight: 400;
    line-height: 126%;
    padding: 20px 33px 20px 23px;
  }

  .return-product-title.active .delivery-payment-arrow {
    top: 14px;
    transform: rotate(180deg);
  }


  .documents-block{
    padding: 66px 0 25px;
  }

  .features_blk.features_blk-document{
    background: #FFFFFF;
    box-shadow: 0px 9.95255px 19.9051px rgba(0, 0, 0, 0.1);
    border-radius: 11.9431px;
  }

  .col_4.features__title{
    padding: 10px 0 8px;
  }

  .features_blk.features_blk-document ._row:nth-child(2n-1){
    background: white;
    border-bottom: 0.995255px solid #A5B7B6;
  }

  .delivery-content-container{
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
  }

  .delivery-payment__item.no-border{
    border-bottom: none;
  }


  .mobil-phones_popup{
    padding: 10px 8px 15px;
  }
  .header-popup{
    box-shadow: none;
    border-bottom: 1px solid hsla(0,0%,45%,.5);
  }
  .mobil-phones_popup ._head-phone ._opr-mts{
    margin-left: 0;
  }
  .mobil-phones_popup ul+ul li{
    margin-top: 0;
  }

  .mobil-phones_popup ._head-phone [class^=_opr] {
    border-radius: 5px;
    height: 20px;
    margin-right: 10px;
    top: -1px;
    width: 20px;
  }

  .mobil-phones_popup .header-phone-min{
    font-size: 30px;
    margin-left: 20px;
    text-decoration: none;
    padding: 15px 0 18px;
  }

  .item-mode--mobile{
    justify-content: center;
    align-items: center;
    gap: 27px;
  }

  .header-popup__item ._phone-list__text a{
    font-size: 18px;
  }

  .header-popup__item ._phone-list__text{
    display: flex;
    flex-direction: column;
  }

  .header-popup__item ._phone-list__text span{
    font-weight: 400;
    font-size: 15px;
    line-height: 120%;
    text-align: center;
    color: #000;
  }

  .header-popup__item ._phone-list ._number{
    font-size: 15px;
  }
  .mobil-phones_popup ._head-phone li+li {
    margin-top: 5px;
  }
  .header-popup__item ._phone-list {
    list-style-type: none;
    padding: 0;
    margin: 12px 0;
  }
  .social-networks{
    padding: 20px 0;
  }

  .working-hours{
    margin: 0;
    padding:  15px 0 0 14px;
  }
  .working-hours:after{
    left: 0;
    top: 18px;
    width: 23px;
    height: 23px;
  }

  .working-hours_item:first-child strong{
    color: #FF4F00;
  }
  .working-hours_item:not(:first-child){
    text-align: left;
    margin-top: 4px;
  }
  .working-hours strong {
    font-size: 18.8px;
  }
  .img-list[class^=_opr]{
    margin-right: 5px !important;
  }
}
@media (max-width: 1060px) {

  .delivery-anchor{
    display: none;
  }

  .delivery-content__item{
    padding: 10px 12px 14px 12px;
    height: auto;
  }
  .delivery-content-container{
    flex-direction: column;
    align-items: flex-start;
  }
  .delivery-content-title span.delivery-title--big{
    font-weight: 800;
    font-size: 16.6667px;
    line-height: 112.52%;
    margin-right: 5px;
  }
  .delivery-content-description{
    font-size: 14px;
    line-height: 126.52%
  }

  ._title-block{
    margin-bottom: 20px;
    font-size: 15.2069px;
    line-height: 112.52%;
  }

  .delivery-price{
    padding-bottom: 0;
  }

  .delivery-time{
    padding-top: 32px;
  }

  .delivery-price-text{
    font-size: 14px;
    line-height: 126%;
  }

  .delivery-time-list__item:first-child{
    font-size: 13.9941px;
    line-height: 88.02%;
  }
  .delivery-time-list__item{
    font-size: 12.4392px;
    line-height: 88.02%;
  }

  .delivery-time-description p{
    font-size: 13.8889px;
    line-height: 126.5%;
  }

  .delivery-time-description p.delivery-time-description--main{
    font-size: 13.8889px;
    line-height: 126.5%;
  }

  .delivery-time-description-bottom p{
    font-size: 14px;
    line-height: 126.5%;
  }
  .delivery-time-description{
    width: 62%;
    background: radial-gradient(182.46% 182.46% at 50% 50%, rgba(241, 254, 253, 0.5) 0%, rgba(255, 255, 255, 0.5) 100%);
    border-radius: 4.94114px;
    padding: 12px;
  }
  .delivery-time-list{
    padding: 14px 0;
    margin: 0;
  }
  .delivery-time-list__item:not(:last-child){
    margin-bottom: 9px;
  }
  .delivery-time-description-top{
    margin-bottom: 19px;
  }
  .delivery-unloading{
    padding-top: 32px;
  }
  .delivery-unloading-content{
    flex-direction: column;
  }
  .delivery-unloading-description{
    padding: 14px 20px 10px 16px;
  }
  .unloading-description-list {
    padding-left: 16px;
  }
  .unloading-description-list__item{
    font-size: 14px;
    line-height: 126%;
  }
  .delivery-unloading-price{
    margin-top: 18px;
  }
  .unloading-price__item{
    padding: 8.5px 0 7px;
  }
  .unloading-product{
    font-size: 14px;
    line-height: 88.02%;
  }
  .delivery-unloading-price{
    width: 100%;
  }

  .unloading-price{
    font-size: 14px;
    line-height: 88.02%;
    padding-right: 20px;
  }

  .delivery-price-text a{
    font-size: 14px;
    line-height: 129.02%;
  }

  .delivery-unloading-content{
    padding: 10px 0 13px;
  }

  .delivery-payment{
    padding-top: 33px;
  }

  .delivery-payment__item-header,
  .return-product-title{
    padding: 8px 13px 8.4px 13px;
    font-size: 14px;
    line-height: 88.02%;
  }

  .delivery-payment__img-container{
    margin-left: 10px;
  }

  .delivery-payment__item-header img{
    height: 13px;
    max-width: 50%;
  }

  .card-pay-full-list{
    position: relative;
    top: 3px;
    margin-left: 10px;
  }

  .delivery-payment-arrow {
    position: absolute;
    right: 21px;
    top: 3px;
  }

  .delivery-payment__item-header.active .delivery-payment-arrow,
  .return-product-title.active .delivery-payment-arrow{
    top: 1px;
  }

  ._title-block span {
    position: relative;
    top: 1px;
    margin-right: 5px;
  }
  span svg {
    height: 22px;
  }
  .delivery-payment__item-dropdown,
  .return-product-dropdown{
    font-size: 14px;
    font-weight: 400;
    line-height: 120.02%;
    padding: 12px 13px 10px 13px;
  }

  .delivery-discounts{
    padding-top: 33px;
  }

  .delivery-discounts-text{
    font-size: 14px;
    line-height: 126.5%;
  }

  .delivery-discounts-title{
    font-size: 12.1528px;
    line-height: 163.5%;
  }

  .discounts-card-img{
    width: auto;
    padding: 0 18px 0 0;
  }

  .discounts-card-img img{
    width: 121px;
    height: 75px;
  }

  .delivery-card__description{
    padding-left: 0;
  }

  .delivery-card-title{
    margin: 0 0 12px;
    font-size: 16px;
    line-height: 126.52%;
  }

  .delivery-card-text{
    font-size: 14px;
    line-height: 126.52%;
  }

  .delivery-card__item{
    padding: 10px 0;
    align-items: center;
  }

  .return-product{
    padding-top: 23px;
  }

  .return-product-text{
    font-size: 14px;
    line-height: 126%;
  }

  .return-product-content{
    margin-top: 20px;
  }

  .documents-block{
    padding: 32px 0 25px;
  }
  .col_4.features__title{
    padding: 0 9px;
    font-size: 14px;
    line-height: 126%;
  }

  .col_4.documents-blc{
    padding: 0 15px;
  }
  .delivery-payment__item-header.active{
    padding: 10px 13px 8.4px;
  }
}
@media (max-width: 1210px) {
  .delivery-content-description{
    margin-right: 0;
  }
}
@media (max-width: 450px) {
  .return-product-title{
    padding-right: 20%;
  }
  .delivery-time-description-bottom{
    width: 188px;
  }
}
.delivery-price-text a {
  white-space: nowrap;
}

@media(max-width: 768px){
  .fl_upld{
    margin-left: 0 !important;
  }
  .delivery-content-description-link-mobile{
    display: block;
    margin-top: 10px;
  }
}

@media(min-width: 1200px){
  .wrapper-delivery_info ymaps{
    min-width: 650px;
  }
}

@media (min-width: 1100px){
  .large_row>[class^=col_].head-midl {
    padding: 0 11px;
  }
  ._head-phone ._row{
    height: 100%;
    align-items: center;
  }
  ._head-phone ._row .col_6{
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 20px;
  }
  ._head-phone ._row .col_6:not(:last-child){
    border-right: 1px solid #d7d9da;
  }
}

.circle-left{
  position: relative;
  padding-left: 15px;
}

.circle-left:before{
  position: absolute;
  content: "";
  background: #ff4f00;
  border-radius: 50%;
  height: 6px;
  width: 6px;
  left: 0;
  top: 6px;

}

.owl-carousel .owl-item img{
  width: auto;
}

.fancybox__thumb{
  background-size: contain;
}

.container-500 ._logo{
  text-align: center;
}

.c-popup.delivery-map_popup {
  padding: 0 20px 50px 20px;
}

.container-500 ._opr-smile{
  background-image: url(/build/images/smile.6cedec20.svg);
  width: 12px;
  background-position: center;
}

@media (max-width: 1200px) {
  .delivery-banner{
    flex-direction: column;
    align-items: center;
    gap: 12px;
    background: #F4FEFE url(/build/images/bg-banner-delevery.bab8da58.svg) -10% 110% no-repeat;
    padding: 35px 0 35px 0;
  }

  .delivery-banner-img{
    align-self: flex-end;
  }

  .delivery-banner-text {
    display: flex;
    flex-wrap: wrap;
    max-width: initial;
    align-items: center;
    justify-content: center;
    padding: 0 62px;
  }

  .delivery-banner-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 0;
  }
  .delivery-img-block__el{
    display: none;
  }

  .delivery-banner-text--delivery{
    font-weight: 700;
    font-size: 42px;
    line-height: 120%;
  }

  .delivery-banner-text--strong{
    max-width: initial;
    font-weight: 700;
    font-size: 34px;
    line-height: 120%;
    margin-left: 5px;
  }
}

@media (max-width: 1024px) {

  .delivery-banner{
    align-self: flex-end;
    background: #F4FEFE url(/build/images/bg-banner-delevery-mobile-new.e1a37696.svg) 3% 88% no-repeat;
    padding: 17px 0 5px 0;
    border-radius: 3px;
  }

  .delivery-banner-text {
    padding: 0;
  }

  .delivery-banner-text--delivery{
    font-size: 22px;
    line-height: 96.69%;
  }

  .delivery-banner-text--mini{
    font-size: 19px;
    line-height: 22px;
  }

  .delivery-banner-text--mini ._orange-text{
    font-size: 19px;
    line-height: 22px;
  }

  .delivery-banner-img {
    max-width: 70%;
    max-height: 300px;
    margin-top: 5px;
  }

  .delivery-banner-btn{
    margin: 8px 0 0;
    padding: 9px 18px;
    font-size: 13px;
    line-height: 11px;
  }

}


.vacancy-header{
  display: flex;
  align-items: flex-end;
  gap: 30px;
  padding-bottom: 32px;
  border-bottom: 2px solid #9A9A9A;
}

.vacancy-header__text{
  flex-grow: 1;
}

.vacancy-header__photo{
  width: 100%;
  max-width: 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.vacancy-header__photo-img{
  width: 161px;
  height: 156px;
  filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.1));
  border-radius: 12px;
  overflow: hidden;
}

.vacancy-header__photo-description{
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  text-align: center;
  margin-top: 20px;
}

.vacancy-header__text p{
  line-height: 20px;
}

.vacancy-header__text p:not(:last-child){
  margin-bottom: 30px;
}

.benefits-content{
  display: flex;
  flex-wrap: wrap;
  gap: 19px 14px;
}

.benefits__item{
  display: flex;
  align-items: center;
  width: 23.9%;
  background: radial-gradient(182.46% 182.46% at 50% 50%, #F1FEFD 0%, #FFFFFF 100%);
  border: 1px solid #FF4F00;
  border-radius: 47px;
  padding: 13px 30px 13px 24px;
}

.benefits__item-img{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 35px;
}

.benefits__item-img svg {
  flex-shrink: 0;
}

.benefits__item-text{
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  color: #000000;
  margin-left: 10px;
}

.vacancy-company{
  padding: 20px 0 0 0;
  margin: 0;
}

.vacancy__item{
  background: #FFFFFF;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
}

.vacancy__item:not(:last-child){
  margin-bottom: 20px;
}

.vacancy__item-specialist{
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 20px 20px 18px 27px;
  cursor: pointer;
}

.vacancy-name-wrapper{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.btn-open-description{
  position: absolute;
  top: 30px;
  right: 20px;
  cursor: pointer;
}

.btn-open-description svg{
  fill: #A5B7B6;
  transition: 0.3s;
}

.btn-open-description:hover svg{
  fill:#FF4F00;
}


.vacancy__item-specialist.active .btn-open-description{
  transform: rotate(180deg);
}

.vacancy__item-description{
  display: none;
  padding: 26px 42px 21px 27px;
  border-top: 1px solid #9A9A9A;
}

.vacancy__item-name{
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 22.6667px;
  line-height: 27px;
  color: #000000;
  margin: 0 0 13px 0;
}

.vacancy__item-name svg{
  margin-left: 10px;
}

.vacancy__item-location{
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  margin: 0;
}

.vacancy__item-salary{
  font-weight: 700;
  font-size: 22.6667px;
  line-height: 27px;
  color: #000000;
  padding-right: 40px;
}

.vacancy__btn-respond{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 180px;
  height: 34px;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #FFFFFF;
  background: #ff4f00;
  border-radius: 26px;
  margin: 20px 0 0 25px;
  text-decoration: none;
  outline: none;
  border: none;
}

.history{
  padding-top: 20px;
  margin: 0;
}

.slider-history {
  position: relative;
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0px 4px 20px rgba(177, 177, 177, 0.2);
  border-radius: 12px;
  overflow: hidden;
}


.swiper-slide{
  display: flex;
  align-items: flex-start;
  padding: 22px 57px 31px  44px;
}

.history-person-img{
  width: 143px;
  height: 143px;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
}

.history-person-img img{
  width: 100%;
  height: 100%;
}

.history-text{
  margin-left: 45px;
}

.history-person-name{
  font-weight: 700;
  font-size: 21px;
  line-height: 126%;
  text-align: justify;
  color: #2A2A2A;
}

.history-person-place{
  font-weight: 400;
  font-size: 18px;
  line-height: 126%;
  text-align: justify;
  color: #2A2A2A;
  margin-bottom: 20px;
}

.history-text p{
  text-indent: 15px;
  font-size: 14px;
  line-height: 126%;
}


.slider-history .swiper-button-next:after,
.slider-history .swiper-button-prev:after{
  color: #9A9A9A;
  width: 9px;
  height: 20px;
  font-size: 22px;
  transition: .3s;
}

.slider-history .swiper-button-next:hover:after,
.slider-history .swiper-button-prev:hover:after{
  color: #FF4F00;
}

.slider-history .swiper-button-next{
  right: 22px;
  width: 9px;
}


.slider-history .swiper-button-prev{
  left: 22px;
  width: 9px;
}

.history-text-wrapper{
  overflow: hidden;
  transition: 0.3s;
}

.history-more{
  font-weight: 400;
  font-size: 14px;
  line-height: 126%;
  color:  #FF4F00;
  text-decoration: underline;
  cursor: pointer;
}

.history-more.d-none{
  display: none;
}

.vacancy-gallery{
  width: 100%;
  overflow: hidden;
  padding-top: 20px;
  margin: 0;
}

.vacancy-gallery *{
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.vacancy-swiper-wrapper{
  max-width: 768px;
  width: 100%;
  margin: 0 7% 0;
}

.vacancy-gallery__swiper{
  width: 100%;
}

.vacancy-gallery__swiper .swiper-wrapper{
  width: 100%;
  height: 528px;
}

.vacancy-gallery__swiper .swiper-slide{
  max-width: 100%;
  width: 100%;
  height: 100%;
  filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.1));
  border-radius: 12px;
  padding: 0;
  opacity: 0.5;
  overflow: hidden;
}

.vacancy-gallery__swiper .swiper-slide img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.vacancy-gallery__swiper .swiper-slide-active{
  opacity: 1;
}

.vacancy-gallery__swiper .swiper-button-next:after,
.vacancy-gallery__swiper .swiper-button-prev:after{
  color: #9A9A9A;
  font-size: 22px;
  transition: .3s;
}

.vacancy-gallery__swiper .swiper-button-next:hover:after,
.vacancy-gallery__swiper .swiper-button-prev:hover:after{
  color: #FF4F00;
}

.vacancy-gallery__swiper .swiper-button-next{
  right: -120px;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  border: 2px solid #9A9A9A;
}


.vacancy-gallery__swiper .swiper-button-prev{
  left: -65px;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  border: 2px solid #9A9A9A;
}

.vacancy-feedback{
  margin-top: 50px;
  border-radius: 12px;
}

.vacancy-feedback-content{
  position: relative;
  height: 636px;
}

.vacancy-feedback .vacancy-feedback-content{
  overflow: hidden;
}

.feedback-vacancy_blk--form{
  max-width: 400px;
  width: 100%;
}

.feedback-vacancy_blk--form{
  position: absolute;
  left: 16px;
  bottom: 12px;
  background: #FFFFFF;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
}

.feedback-vacancy_blk--form{
  padding: 35px 15px 47px;
}

.feedback-vacancy_blk--form .control-label{
  max-width: 125px;
  width: 100%;
  padding-right: 15px;
}

.feedback-vacancy_blk--form .control-label label{
  font-weight: 700;
  font-size: 14px;
  line-height: 15px;
  color: #000000;
}

.feedback-vacancy_blk--form .fl_upld{
  justify-content: flex-start;
}

.feedback-vacancy_blk--form .fl_upld label,
.feedback-vacancy_blk--form .orange_btn{
  border-radius: 27px;
}

.feedback-vacancy_blk--form .orange_btn{
  margin-bottom: 0;
  padding: 10px 23px
}

.feedback-vacancy_blk--form .control-row + .control-row {
  margin-top: 15px;
}

.feedback-vacancy_blk--form .control-row.control-row--captcha{
  margin-top: 7px;
}

.feedback-vacancy_blk--form .control-submit{
  display: flex;
  align-items: center;
  min-width: inherit;
  margin-top: 32px;
}
.feedback-vacancy_blk--form .privacy-text{
  max-width: 240px !important;
  font-size: 12px;
  line-height: 16px;
  margin: 0;
}

.feedback-vacancy-photo{
  position: absolute;
  width: 99px;
  height: 99px;
  top: -73px;
  left: 9px;
  overflow: hidden;
  border-radius: 50%;
  border: 4px solid #FF5000;
}

.modal-feedback-form{
  position: fixed;
  left: 0;
  top: 0;
  opacity: 0;
  z-index: -1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  transition: 0.3s;
}

.modal-feedback-form:before{
  position: fixed;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: rgba(0, 0, 0, 0.5);
}

.modal-feedback-form.active{
  left: 0;
  opacity: 1;
  z-index: 3000;
}

.modal-feedback-form.active .vacancy-feedback-content{
  height: auto;
  position: relative;
  z-index: 102;
}

.modal-feedback-form .feedback-vacancy_blk--form{
  max-width: 440px;
  position: relative;
  padding: 35px 35px 47px;
  left: 0;
}

.modal-feedback-cross{
  position: absolute;
  right: -20px;
  top: 0;
  cursor: pointer;
}

.modal-feedback-form__title{
  text-align: center;
  margin: 0 0 30px;
}

.swiper-pagination-bullet{
  background: #A9A9A9;
  margin: 0 8px !important;
  opacity: 1;
}

.swiper-pagination-bullet-active{
  background: #FF4F00;
}

.swiper-pagination{
  display: none;
}

.vacancy-feedback-map{
  border-radius: 12px;
  overflow: hidden;
}

.vacancy-feedback-map--mobile{
  display: none;
}

@media (max-width: 1280px) {
  .benefits__item {
    width: 32%;
  }

}

@media (max-width: 1024px) {
  .vacancy-header{
    margin-top: 0;
  }

  .benefits-content{
    gap: 14px 10px;
  }

  .benefits__item {
    width: calc(50% - 5px);
  }

}



@media (max-width: 768px) {
  .vacancy-header {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 0;
    border-bottom: 1px solid #9A9A9A;
  }

  .vacancy-title{
    font-size: 19px;
    line-height: 24px;
  }

  .vacancy-header__text p{
    font-size: 14px;
    line-height: 18px;
  }

  .vacancy-header__text p:first-child{
    text-align: justify;
  }

  .vacancy-header__photo{
    margin-top: 20px;
  }

  .vacancy-header__photo-img{
    width: 107px;
    height: 107px;
    border-radius: 50%;
  }

  .vacancy-header__photo-img img{
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .vacancy-header__photo-description{
    margin-top: 10px;
  }

  .swiper-pagination{
    display: block;
  }

  .benefits__item {
    padding: 9px 10px 9px 20px;
  }

  .benefits__item-img{
    width: 20px;
    height: 20px;
  }

  .benefits__item-text{
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 120%;
    min-height: 30px;
    margin-left: 10px;
  }

  .vacancy__item{
    box-shadow: 0px 39px 229px rgba(0, 0, 0, 0.0216667), 0px 25.2778px 134.113px rgba(0, 0, 0, 0.0353333), 0px 15.0222px 72.9407px rgba(0, 0, 0, 0.045), 0px 7.8px 37.2125px rgba(0, 0, 0, 0.0546667), 0px 3.17778px 18.6593px rgba(0, 0, 0, 0.0683333), 0px 0.722222px 9.01157px rgba(0, 0, 0, 0.09);
    border-radius: 12px;
  }

  .vacancy__item-specialist {
    padding: 10px 9px 10px 12px;
  }

  .vacancy-name-wrapper{
    flex-direction: column;
  }

  .vacancy__item-name{
    font-size: 19px;
    line-height: 27px;
    margin-bottom: 2px;
  }

  .vacancy__item-salary{
    font-size: 19px;
    line-height: 27px;
    margin-bottom: 10px;
  }

  .vacancy__item-description {
    padding: 20px 9px 21px 12px;
  }

  .vacancy__item-description p{
    font-size: 14px;
    line-height: 20px;
  }

  .vacancy__item-description li{
    margin-top: 6px;
  }

  .btn-open-description {
    top: 9px;
    right: 12px;
  }

  .vacancy__btn-respond{
    font-size: 14.2px;
    line-height: 21px;
    width: 142px;
    height: 26px;
  }

  .history .swiper-slide{
    flex-direction: column;
    align-items: center;
    padding: 18px 16px 44px 16px;
  }

  .swiper-button-next, .swiper-button-prev{
    display: none;
  }

  .history .swiper-pagination{
    bottom: 16px !important;
  }

  .history-person-img{
    width: 112px;
    height: 112px;
  }

  .history-person-name{
    font-size: 21px;
    line-height: 126%;
    text-align: center;
  }

  .history-person-place{
    font-size: 18px;
    line-height: 16px;
    text-align: center;
    margin-bottom: 13px;
  }

  .history-more{
    font-size: 14px;
    line-height: 126%;
  }

  .history-text{
    margin: 13px 0 0 0;
  }

  .history-text p{
    text-align: justify;
  }

  .vacancy-gallery__swiper .swiper-wrapper{
    height: 198px;
  }

  .vacancy-gallery__swiper .swiper-pagination{
    position: relative;
    top: 9px;
  }

  .vacancy-feedback-content{
    height: inherit;
  }

  .feedback-vacancy_blk--form{
    position: static;
    padding: 26px 13px 40px;
    box-shadow: 0px 39px 229px rgba(0, 0, 0, 0.0216667), 0px 25.2778px 134.113px rgba(0, 0, 0, 0.0353333), 0px 15.0222px 72.9407px rgba(0, 0, 0, 0.045), 0px 7.8px 37.2125px rgba(0, 0, 0, 0.0546667), 0px 3.17778px 18.6593px rgba(0, 0, 0, 0.0683333), 0px 0.722222px 9.01157px rgba(0, 0, 0, 0.09);
    border-radius: 10.4204px;
    margin-top: 66px;
  }

  .vacancy-feedback .feedback-vacancy_blk--form{
    padding-top: 30px;
    margin-top: 90px;
  }

  .feedback-vacancy-photo {
    width: 85px;
    height: 85px;
    left: 9px;
  }

  .modal-feedback-form .feedback-vacancy_blk--form {
    padding: 35px 13px 47px;
  }


  .vacancy-feedback .vacancy-feedback-content{
    overflow: inherit;
  }

  .vacancy-feedback-map{
    height: 408px;
  }

  .feedback-vacancy_blk--form .control-row{
    display: flex;
    align-items: center;
  }
  .feedback-vacancy_blk--form .control-label{
    margin-bottom: 0;
  }

  .vacancy-feedback .feedback-vacancy_blk--form{
    margin-bottom: 65px;
  }

  /*.privacy-text{
    margin-left: 10px;
  }*/

  .modal-feedback-form{
    padding: 0 10px;
  }

  .modal-feedback-form .feedback-vacancy_blk--form{
    position: relative;
    left: 0;
  }

  .modal-feedback-cross {
    position: absolute;
    right: 2px;
    top: 18px;
    cursor: pointer;
  }

  .vacancy-feedback-map{
    display: none;
    border-radius: 10px;
  }

  .vacancy-feedback-map--mobile{
    display: block;
  }

  .benefits__item:nth-last-child(-n+2) .benefits__item-img{
    width: 25px;
    height: 25px;
  }

  .vacancy-gallery{
    min-height: 325px;
  }

  .vacancy-swiper-wrapper {
    width: 80%;
    margin: 0 auto;
  }
}

.c-panel-header{
  display: flex;
  align-items: flex-start;
}

.mobile-menu-site{
  display: none;
  font: 400 0.875em/1.4 PT Sans,sans-serif;
}

@media (max-width: 859px) {
  .c-search-widget{
    flex-grow: 1;
    padding-left: 10px;
  }

  .c-panel-header a{
    display: flex;
    border-radius: 2.38095px;
  }

  .cart.active svg *,
  .cart:hover svg *{
    fill: #FF4F00;
  }

  .mobil-phones_popup-btn.active svg *,
  .mobil-phones_popup-btn:hover svg *{
    fill: #FF4F00;
    stroke: #FF4F00;
  }

  .mobile-login svg{
    stroke: #FF4F00;
  }

  .mobile-popup-company:hover svg *{
    stroke: #FF4F00;
  }

  .mobile-phones-login.active svg *,
  .mobile-phones-login:hover svg *{
    fill: none;
    stroke: #FF4F00;
  }

  .mobil-phones_popup-btn.active svg *,
  .mobil-phones_popup-btn:hover svg * {
    fill: none;
    stroke: #FF4F00;
  }
  .mobile-cart__item.active svg *,
  .mobile-cart__item:hover svg * {
    fill: none;
    stroke: #FF4F00;
  }

  .head-links_mobile .head-links_mobile__column-icon a:before{
    content: none;
  }

  .mobile-menu-site{
    position: fixed;
    top: 95px;
    left: -100%;
    display: block;
    overflow-y: auto;
    width: 100%;
    height: 100%;
    max-height: calc(100vh - 80px);
    z-index: 100;
    background: white;
  }

  .mobile-menu-site.active{
    left: 0;
  }

  .mobile-menu-site__head{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px 0 0;
  }


  .mobile-menu-site .vertical_nav.open_nav {
    display: block;
    min-height: calc(100vh - 50px);
  }

  .mobile-menu-site li{
    margin: 0;
  }

  .mobile-menu-site a {
    width: 100%;
    display: block;
    border: none;
    background: transparent;
    color: #000;
    text-decoration: none;
    font-size: 12pt;
    padding: 13px 20px 12px 15px;
    transition: all 0.1s;
  }

  .mobile-login svg{
    stroke: black;
  }

  .mobile-login.active svg{
    stroke: #FF4F00;
  }

}

.write-to-us_blk img {
  display: block;
  margin: 0 auto;
}

.grid-block{
  display: flex;
  justify-content: space-between;
}

.grid-block__item{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 33.3%;
}

.grid-block:nth-child(2) .grid-block__item{
  width: 50%;
}

@media (max-width: 768px) {
  .grid-block{
    flex-direction: column;
  }

  .grid-block__item{
    width: 100%;
  }

  .product-label__item {
    font-size: 12px;
  }
}

.burger-menu{
  display: flex;
  align-items: center;
  width: 25px;
  height: 28px;
  margin: 0 5px 0 8px;
}

.burger-menu__hamburger{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 14px;
  transition: 0.3s;
}

.burger-menu__hamburger:after{
  position: absolute;
  content: "";
  width: 100%;
  height: 2.2px;
  background: #FF4F00;
  border-radius: 10px;
  bottom: -6px;
  left: 0;
}

.burger-menu__hamburger-line{
  position: relative;
  width: 100%;
  height: 2.3px;
  background: #FF4F00;
  border-radius: 10px;
  transition: 0.3s;
}

.burger-menu__hamburger-line.cross-none{
  transition: 0s;
}

.burger-menu.active .cross-left{
  top: 3px;
  transform: rotate(-45deg);
}

.burger-menu.active .cross-right{
  top: -3px;
  transform: rotate(45deg);
}

.burger-menu.active .burger-menu__hamburger:after{
  width: 0;
}
.burger-menu.active .cross-none{
  width: 0;
}

@media (min-width: 861px) {
  .burger-menu{
    display: none;
  }
}

@media (max-width: 860px) {
  .head-links_mobile__column-logo{
    width: 170px;
  }

  .mobile-menu-site .vertical_nav li + li{
    margin: 0;
  }

  .mobile-menu-site .vertical_nav{
    display: none;

  }

  .mobile-menu-site .vertical_nav.open_nav{
    display: block;
  }

  .mobile-menu-site-close-icon{
    position: absolute;
    left: 22px;
    top: 12px;
    display: flex;
  }

  .head-links_mobile a._logo{
    justify-content: flex-start;
    padding: 0;
  }

  .mobile-menu-site .mobile-menu-site__head{
    justify-content: flex-end;
  }

  .vertical_nav--menu li:first-child a{
    border-top: none;
  }

  .pulse-button{
    background: url(/build/images/micro-mobile.65d7ce13.svg) no-repeat center center;
  }

  ._ico-search:before {
    background: url("/build/images/icon _search_.da60043a.svg") 50% 50% no-repeat;
  }
}


.working-hours_item--color{
  color: #ff0000;
}

@media (max-width: 1690px) {
  .cart-info_close-btn:after,
  .cart-info_close-btn:before{
    left: 8px;
    top: 3px;
  }
}

@media(max-width: 768px){
  .sticky_cart{
    display: block;
  }

  .cart_blk.cart-info{
    width: 230px;
  }

  .cart-info--discount{
   gap: 10px;
  }

  .cart_blk.cart-info--discount {
    padding: 2px 7px;
    right: 28px;
    top: 105px;
    transition: 0.3s;
  }

  .cart-info--discount__num{
    font-size: 25px;
    line-height: 32px;
  }

  .cart-info--discount__text{
    font-size: 11px;
    line-height: 108.9%;
    padding-left: 12px;
  }

  .cart-info_close-btn {
    width: 16px;
    height: 16px;
  }

  .cart-info_close-btn:before,
  .cart-info_close-btn:after {
    height: 8px;
    width: 1px;
    background-color: #000000;
    top: 3px;
    left: 7px;
  }

  .cart-info--discount.hide-right{
    right: -100% !important;
  }

  .cart--basket{
    display: none;
  }
}

.categories_nav_mob-menu .open  ._ico-arr-right::before{
  color: rgb(0 0 0);
}

.success-order{
  display: flex;
  align-items: flex-start;
  gap: 40px;
}

.success-subtitle{
  max-width: 512px;
  margin-bottom: 18px;
}

.success-order--content{
  flex-grow: 1;
}

.success-order--check{
  max-width: 380px;
  background: #F5F8F7;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 12px 20px 14px 26px;
}

.success-subtitle{
  font-weight: 400;
  font-size: 16px;
  line-height: 157%;
  margin-bottom: 18px;
}

.success-subtitle span{
  display: block;
  font-size: 14px;
}

.success-data__item{
  display: block;
  font-size: 14px;
  line-height: 157%;
}

.success-data__description{
  display: inline;
  margin-left: 5px;
}

.success-data__name{
  display: inline;
  font-weight: 700;
  white-space: nowrap;
}

.order-weight{
  font-weight: 400;
  font-size: 14px;
  line-height: 126%;
  color: #2B2B2B;
}

.order-weight .big-order{
  color: #FF4F00;
}

.big-order-message{
  font-weight: 400;
  font-size: 11px;
  line-height: 126%;
  color: #FF4F00;
  margin: 6px 0;
}

.success-order__item-header{
  padding-bottom: 13px;
  border-bottom: 1px solid #A5B7B6;
}

.order-list-check{
  padding: 13px 0 13px 23px;
  margin: 0;
  border-bottom: 1px solid #A5B7B6;
}

.order-list-check__item:not(:last-child){
  margin-bottom: 15px;
}

.order-list-check__item:before{
  width: 5px;
  height: 5px;
}

.order-list-check__product,
.order-list-check__item [class$="_product"]:hover{
  border: none;
  outline: none;
  box-shadow: none;
  font-size: 14px;
  line-height: 126%;
}

.order-list-check__sum{
  font-weight: 700;
  font-size: 11px;
  line-height: 126%;
  color: #ADADAD;
}

.check-result{
  padding: 10px 0 15px;
}

.check-result__item{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.check-result__item:not(:last-child){
  margin-bottom: 6px;
}

.check-result-name{
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 126%;
}

.heck-result-description{
  font-size: 12px;
  line-height: 126%;
}

.check-result-description--orange{
  color: #FF4F00;
}

.check-result-description--bold{
  font-weight: 700;
  font-size: 16px;
}

.check-result-name__label{
  background: #95DC80;
  border-radius: 1.60374px;
  font-weight: 400;
  font-size: 10px;
  line-height: 126%;
  color: #FFFFFF;
  padding: 1px 5px;
  margin-left: 5px;
}

.success-order__print{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.success-printOrder.success-order__print a{
  font-weight: 700;
  font-size: 14px;
  line-height: 126%;
  text-decoration-line: underline;
  color: #787676;
  margin: 0 0 0 7px;
}

.order-error-message{
  font-weight: 400;
  font-size: 11px;
  line-height: 126%;
  color: #9A9A9A;
  margin-top: 6px;
}

.pickup-order{
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.pickup-order__address,
.pickup-order__time,
.pickup-order__phone{
  position: relative;
  font-weight: 400;
  font-size: 14px;
  line-height: 126%;
  color: #2B2B2B;
  padding-left: 30px;
  margin-bottom: 18px;
}

.pickup-order__phone{
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
}

.pickup-order__address:before{
  position: absolute;
  content: "";
  left: 0;
  width: 17px;
  height: 20px;
  background: url(/build/images/location-order.554b5fcf.svg) no-repeat center;
}

.pickup-order__time:before{
  position: absolute;
  content: "";
  left: 0;
  width: 17px;
  height: 20px;
  background: url(/build/images/time-order.05a6ac40.svg) no-repeat center;
}

.pickup-order__phone:before{
  position: absolute;
  content: "";
  top: 5px;
  left: 0;
  width: 17px;
  height: 20px;
  background: url(/build/images/phone-order.1e8516e3.svg) no-repeat center;
}

.pickup-order-map{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 40px;
}

.pickup-order-map__img{
  position: relative;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0 0px 28px 1px rgba(0,0,0,.1);
  cursor: pointer;
}

.pickup-order-map__img img{
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0;
  top: 0;
  max-width: inherit;

}

.pickup-order-map-btn{
  font-weight: 400;
  font-size: 12px;
  line-height: 126%;
  text-decoration-line: underline;
  color: #FF4F00;
  margin-top: 7.5px;
  background: none;
  border: none;
  outline: none;
}

.pickup-order-map-btn:hover{
  cursor: pointer;
}

.success-message{
  background: #FFFFFF;
  border: 1px solid #FF4F00;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  padding: 17px 0;
  text-align: center;
  font-weight: 700;
  margin-top: 26px;
}

.success-message__top{
  font-size: 20px;
  line-height: 126%;
  text-align: center;
  color: #2B2B2B;
}

.success-message__bottom{
  font-size: 14px;
  line-height: 126%;
  text-align: center;
  color: #2B2B2B;
}

.back-shopping{
  display: block;
  text-align: right;
  margin-top: 12px;
}

.page_headline .success-page-title{
  display: block;
  margin-left: 106px;
  padding-top: 16px;
}

.success-page-title svg{
  margin-right: 5px;
  stroke-width: 0.3px;
  stroke: #79d428;
}

.c-popup-map{
  width: 800px;
  max-width: 800px;
  padding-top: 30px;
  transform: translate(-50%);
  margin: 0;
}

.c-popup-map-container{
  width: 100%;
  overflow: hidden;
}

.success-message--mobile{
  display: none;
}

.success-order--check-title{
  display: none;
  font-weight: 700;
  font-size: 20px;
  line-height: 112.52%;
  color: #2B2B2B;
}

@media(max-width: 1200px){
  .success-order--check{
    max-width: 325px;
  }

  .success-subtitle{
   line-height: inherit;
  }

  .success-title{
    font-size: 20px;
    line-height: 112.52%;
    margin-bottom: 16px;
  }

  .success-subtitle span{
    display: inline;
    font-weight: 700;
    font-size: 14px;
    line-height: 157%;
    color: #2B2B2B;
  }
  .success-subtitle span:not(:first-child){
    margin-left: 5px;
  }
}

@media(max-width: 1060px){
  .pickup-order{
    flex-direction: column;
    align-items: flex-start;
  }

  .pickup-order-map{
    width: 100%;
    margin: 18px 0 0 0;
  }

  .back-shopping{
    display: none;
  }
}

@media(max-width: 1024px){
  .success-order{
    flex-direction: column;
    gap: 21px;
  }

  .success-order--content{
    width: 100%;
  }

  .success-order--check {
    width: 100%;
    max-width: 100%;
  }

  .success-message--desc{
    display: none;
  }

  .success-message--mobile{
    display: block;
  }

  .success-order--check-title{
    display: block;
  }

  .c-popup-map{
    max-width: 90%;
  }
}

@media (max-width: 550px) {
  .page_headline .success-page-title{
    margin-left: 0;
  }

  .success-order--check{
    padding: 12px 22px 14px;
  }

  .success-order__item-header{
    padding-bottom: 6px;
  }

  .big-order-message{
    margin-bottom: 0;
  }

  .pickup-order-map__img{
    width: 100%;
    border-radius: 12px;
    overflow: hidden;
  }

  .pickup-order-map__img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: scale(1.12);
  }

  .c-popup-map{
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.data-delivery-info {
  display: none;
  max-width: 455px;
  margin-left: 20px;
  color: #a9a9a9;
}

.data-delivery-info.active{
  display: block;
}

@media (min-width: 1250px){
  .data-delivery-info {
    position: absolute;
    width: 455px;
    right: 0;
    top: 0;
  }
}

.checkout-setting_blk .date-row.date-row--container{
  position: relative;
  max-width: 100%;
}

.checkout-setting_blk .element{
  max-width: 100%;
}

@media(max-width: 1249px){
  .data-delivery-info {
    margin: 10px 0 0;
  }
}

.tabs_blk{
  margin-top: 30px;
}

.status-out_production .default_product .col_cart{
  gap: 12px;
}

.status-out_production .default_product .item--text{
  font-size: 12.5px;
  line-height: 13px;
  color: #6d6b6b;
}

.status-out_production .default_product .btn{
  text-decoration: none;
  font-size: 14px;
  background: none;
  border: 1px solid #f66625;
  border-radius: 4px;
  box-shadow: none;
  color: #f66625;
  font-weight: 400;
  padding: 4px 8px 3px;
  display: inline-block;
  flex-shrink: 0;
}

.status-not_in_stock .default_product .col_cart__wrapper{
  display: flex;
  gap: 12px;
  align-items: center;
  padding: 0;
}

.status-not_in_stock .default_product .btn{
  text-decoration: none;
  font-size: 14px;
  background: none;
  border: 1px solid #f66625;
  border-radius: 4px;
  box-shadow: none;
  color: #f66625;
  font-weight: 400;
  padding: 4px 8px 3px;
  display: inline-block;
}

.status-not_in_stock .default_product .item--text,
.status-not_in_stock .item--text{
  min-width: 114px;
  position: relative;
  font-size: 14px;
  color: #f40606;
  padding-left: 15px;
}

.product-image_blk.status-not_in_stock .item--text{
  font-size: 16px;
}

.status-not_in_stock .default_product .item--text:after,
.status-not_in_stock .item--text:after{
  position: absolute;
  content: "x";
  left: 0;
  color: #f40606;
  top: -1px;
}

.item-wrapper{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.status-not_in_stock .item--link{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
  text-decoration: none;
}

.status-not_in_stock .item--link span{
  font-size: 16px;
  color: black;
  border-bottom: 1px dashed black;
  transition: .3s;
}

.status-not_in_stock .item--link:hover span{
  color: #f66625;
}

.item-wrapper{
  display: flex;
  align-items: center;
}

.status-out_production .out_production--text{
  font-size: 16px;
  color: #8b8787;
}

.product_section .product-image_blk.status-out_production .price,
.product_section .product-image_blk.status-not_in_stock .price{
  display: flex;
  gap: 5px;
  color: #a1a3a9;
}

.status-out_production .basePrice,
.status-not_in_stock .basePrice{
  color: #a1a3a9;
}

@media (min-width: 1024px) {
  .status-out_production .col_cart,
  .status-not_in_stock .col_cart{
    min-height: 61px;
  }
}

@media (max-width: 768px) {
  .status-out_production .default_product .item--text,
  .status-not_in_stock .default_product .item--text{
    width: 140px;
  }

  .status-not_in_stock .default_product .btn,
  .status-out_production .default_product .btn{
    text-decoration: none;
    font-size: 17px;
    background: none;
    border: 1px solid #f66625;
    border-radius: 4px;
    box-shadow: none;
    color: #f66625;
    font-weight: 400;
    padding: 6px 6px 7px;
    display: inline-block;
  }
}

.default_product .send-cart.product-in-cart {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 130px;
  gap: 3px;
  text-decoration: none;
  font-size: 15px;
  background: white;
  border: 1px solid #ff4f00;
  border-radius: 4px;
  box-shadow: none;
  color: #ff4f00;
  font-weight: 400;
  padding: 2.5px 11px 4px 11.9px;
  transition: none;
}

.default_product .send-cart.product-in-cart:hover{
  background: white;
  border: 1px solid #ff4f00;
  color: #ff4f00;
}

.default_product .send-cart.product-in-cart ._ico-cart {
  margin-right: 6px;
  font-size: 16px;
}

.default_product .send-cart.product-in-cart:hover {
  color: #ff4f00;
  background-color: white;
}

.product-image_blk .send-cart{
  transition: none;
  cursor: pointer;
}

.product-image_blk .send-cart.product-in-cart{
  color: #ff4f00;
  padding: 9px 0 9px;
  border-radius: 3px;
  text-decoration: none;
}

.product-image_blk .send-cart.product-in-cart.orange_btn{
  color: white;
}

.product-image_blk .send-cart.product-in-cart [class^="_ico-"]{
  font-size: 12pt;
  padding-right: 6px;
}

@media (max-width: 768px) {
  .default_product .send-cart.product-in-cart {
    font-size: 18px;
    padding: 5px 0 7px;
  }

  .product-image_blk .send-cart.product-in-cart{
    color: #ff4f00;
    font-size: 18px;
    padding: 7px 0 7.62px;
    border-radius: 3px;
    text-decoration: none;
  }

  .product-image_blk .send-cart.product-in-cart.orange_btn{
    color: white;
  }

  .default_product .send-cart ._ico-cart,
  .default_product .send-cart.product-in-cart ._ico-cart{
    font-size: 16px;
  }

  .product_section .default_product ._ico-cart{
    width: initial;
    display: flex;
    align-items: center;
    justify-content: center;
  }

}

.status-not_in_stock ._product-image-item .image,
.status-out_production ._product-image-item .image,
.status-not_in_stock ._product-image-item .owl-item.active .lightbox-container img,
.status-out_production ._product-image-item .owl-item.active .lightbox-container img{
  opacity: 0.5;
}

.status-not_in_stock .price,
.status-out_production .price{
  color: #a1a3a9;
}

.c-cart__grid .i-item.status-out_production,
.c-cart__grid .i-item.status-not_in_stock{
  position: relative;
}

.c-cart__grid .i-item.status-out_production:before,
.c-cart__grid .i-item.status-not_in_stock:before{
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgb(255 0 0 / 8%);
  z-index: 39;
}


.c-cart__grid .status-out_production ._col-num,
.c-cart__grid .status-not_in_stock ._col-num{
  color: red;
}

@media (min-width: 1024px) {
  .status-out_production .default_product .item--text{
    max-width: 114px;
  }
}

.supplies-product{
  display: flex;
  justify-content: flex-end;
  text-align: end;
  font-size: 11px;
  color: #666;
}

.js-cart-item .supplies-product{
  text-align: center;
  max-width: 85px;
  margin: 20px auto 0;
}

@media (max-width: 768px) {
  .supplies-product{
    font-size: 13px;
  }

  .card-basket ._col-image{
    min-width: 130px;
  }

  .js-cart-item .supplies-product {
    max-width: 85px;
    margin: 20px 0 0;
    text-align: left;
  }

  .js-cart-item.status-out_production .cart-design,
  .js-cart-item.status-not_in_stock .cart-design{
    color: #f70000;
    font-size: 11pt;
  }

  .js-cart-item.status-out_production ._price.new-price,
  .js-cart-item.status-not_in_stock ._price.new-price{
    color: #8b8787;
  }

}

.c-cart__grid ._headline{
  position: relative;
  z-index: 40;
  text-align: left;
}


.js-cart-item a._col-image{
  position: relative;
}

.c-cart__grid .i-item.status-out_production ._col-image:before,
.c-cart__grid .i-item.status-not_in_stock ._col-image:before{
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 40;
}

.c-cart__grid .i-item.status-out_production ._col-image img,
.c-cart__grid .i-item.status-not_in_stock ._col-image img{
 opacity: 0.5;
}

.c-cart__grid .i-item.status-out_production ._price,
.c-cart__grid .i-item.status-not_in_stock ._price {
  display: none;
}

.sidebar_banners_blk{
  width: 240px;
  height: 332px;
}

.sidebar_banners_blk .slider_wrap,
.sidebar_banners_blk .owl-carousel,
.sidebar_banners_blk div.owl-stage-outer{
  height: 100%;
  max-height: initial;
}

.sidebar_banners_blk a.slide-owl.owl-item {
  height: 100% !important;
}

.large_row .cart_blk{
  width: 160px;
}

.login_blk__item{
  width: 95px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.cart_blk.cart--basket .cart{
  justify-content: flex-start;
  gap: 12px;
}

@media (max-width: 590px) {
  .delivery-banner {
    align-self: flex-end;
    background: #F4FEFE url(/build/images/bg-banner-delevery-mobile-new.e1a37696.svg) 0 106% no-repeat;
    padding: 17px 0 0 0;
    border-radius: 3px;
  }

  .delivery-banner-text--delivery,
  .delivery-banner-text--strong {
    font-weight: 700;
    font-size: 17px;
    line-height: 120%;
    text-transform: initial;
  }

  .delivery-img-block {
    margin: 0;
  }

  .delivery-banner-img {
    max-width: 230px;
    max-height: 120px;
    margin-right: 0;
  }

  .delivery-banner-text {
    flex-direction: row;
  }

  .delivery-banner-text--mini {
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    color: #202020;
    padding: 0 40px;
  }

  .delivery-banner-btn {
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.04em;
  }
}

@media (max-width: 499px) {
  .related-catalog_blk .default_product .send-cart ._ico-cart{
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.categories_nav .subnav a:hover{
  background: #ff4f00;
}

.categories_nav .subnav .sel > a{
  background: #ff4f00;
  color: #fff;
}

.video-elem{
  width: 600px;
  height: 371px;
  margin: 0 auto;
}

input[type=radio]:not(checked) + label.address_pickup-label{
  margin-right: 15px !important;
}

.address_open-location.pickup-order-map{
  display: contents;
  cursor: pointer;
}

.c-popup-map-location{
  display: none;
}

.c-popup-map-location.active{
  display: block;
}

.control-widget .form-row input[type=radio]:not(checked) + label:after{
  margin-right: 0;
}

.-shadow_default .blk_headline{
  margin-top: 0;
}

@media (max-width: 768px) {
  .video-elem{
    width: 100%;
    height: 215px;
  }

  .address_open-location.pickup-order-map svg{
    position: relative;
    top: 3px;
  }

  ._total {
    min-width: 280px;
  }
}

._popup-product{
  display: none;
  position: fixed;
  padding: 30px 16px;
  background: white;
  left: 50%;
  top: 50%;
  transform: translate(-50% , -50%);
  width: 100%;
  max-width: 400px;
  z-index: 10001;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
}

._popup-success{
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  position: fixed;
  padding: 30px 16px;
  background: white;
  left: 50%;
  top: 50%;
  transform: translate(-50% , -50%);
  width: 100%;
  max-width: 400px;
  z-index: 10001;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
}

._popup-product.active{
  display: block;
}

._popup-success.active{
  display: flex;
}

._popup-success__test{
  font-size: 27px;
  line-height: 40px;
  color: #000;
  font-weight: bold;
  text-align: center;
}

._popup-product-cross,
._popup-success__cross{
  position: absolute;
  top: -25px;
  right: -25px;
  cursor: pointer;
}

._popup-product-title{
  display: block;
  font-weight: 700;
  font-size: 22px;
  line-height: 27px;
  color: #000000;
  text-align: center;
  margin-bottom: 14px;
}

._popup-product-item{
  display: flex;
  gap: 21px;
  margin-bottom: 28px;
}

._popup-product-item{
  padding: 14px 0;
  border-top: 1px solid #C2C2C2;
  border-bottom: 1px solid #C2C2C2;
  margin-bottom: 28px;
}

._popup-product-status{
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 20px;
  color: #FF0009;
}

._popup-product-elem{
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: #000000;
  margin-bottom: 17px;
}

._popup-product-old_name{
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: #C2C2C2;
}

._popup-product-old_price{
  font-weight: 700;
  font-size: 14px;
  line-height: 120%;
  color: #C2C2C2;
}

._popup-product-img{
  width: 132px;
  height: 150px;
  margin-left: 10px;
  flex-shrink: 0;
}

._popup-product-img img{
  width: 100%;
  height: 100%;
  object-fit: contain;
  opacity: 0.5;
}

._popup-product-text{
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: #000000;
  margin-bottom: 30px;
}

._popup-product-form .control-row-btn{
  justify-content: center;
  text-align: center;
  margin-top: 28px;
}

._popup-product-description{
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  text-align: center;
  color: #A9A9A9;
  margin-top: 25px;
}

._popup-product-description a{
  text-decoration: underline;
  color: #A9A9A9;
  transition: 0.3s;
}

._popup-product-description a:hover{
  color: #ff4f00;
}

._popup-product-form .control-label{
  width: 125px;
}

._popup-product-form .orange_btn{
  border-radius: 3px;
}

._popup-product-container{
  max-height: 88vh;
  overflow: auto;
}

.btn-up-top{
  display: none;
  position: fixed;
  right: 26px;
  bottom: 33px;
  width: 44px;
  height: 44px;
  cursor: pointer;
  z-index: 10150;
}

.btn-up-top.show{
  display: block;
}

@media (max-width: 768px) {
  ._popup-product,
  ._popup-success{
    width: calc(100% - 24px);
    top: 52%;
  }
  ._popup-product-form .control-row{
    display: flex;
    align-items: flex-end;
  }

  ._popup-product-description{
    font-size: 13px;
  }

  ._popup-product-form .control-label {
    width: 125px;
    margin-bottom: 5px;
  }

  ._popup-product-cross,
  ._popup-success__cross{
    position: absolute;
    top: -27px;
    right: 0;
  }

  .btn-up-top{
    width: 41px;
    height: 41px;
    right: 23px;
  }

  .cartPage .btn-up-top{
    bottom: 81px;
  }

  .b24-widget-button-block{
    width: 52px !important;
  }

  .b24-widget-button-inner-block{
    width: 45px!important;
    height: 45px!important;
  }

  .b24-widget-button-wrapper{
    align-items: center !important;
  }
}

.cart-feedback_popup__img{
  padding: 15px 0 15px;
  text-align: center;
  background: #FF4F00;
  border-radius: 20px 20px 0 0;
}

.popup-for-user .cart-feedback_popup__img{
  padding: 15px 0 15px;
}

.cart-feedback_popup ._ico-number{
  position: relative;
  margin-bottom: 10px;
}

.form-cart-feedback__message{
  color: #A9A9A9;
  text-align: center;
  font-size: 14px;
  font-family: PT Sans;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin-top: 20px;
}

.form-cart-feedback__message a{
  color: #A9A9A9;
  text-align: center;
  font-size: 14px;
  font-family: PT Sans;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  text-decoration: underline;
}

.cart-feedback_popup{
 flex-direction: column;
}

#form-cart-feedback{
  max-height: 80%;
  overflow: auto;
}

._product-cnt{
  position: relative;
  display: inline-block;
  color: #A9A9A9;
  font-size: 16px;
  line-height: 120%;
  margin-left: 13px;
}

@media (max-width: 1100px) {
  .cart-feedback_popup ._ico-close,
  .popup-for-user .for-user-close{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 19px;
    height: 19px;
    right: 2px;
    top: -28px;
    background: initial;
    border-radius: 0;
    border: none;
  }
}

@media (max-width: 768px) {
  .cart-feedback_popup form{
    padding: 0 16px;
  }

  #form-cart-feedback{
    padding-top: 1px;
  }

  .cart-feedback_popup__img{
    border-radius: 15px 15px 0 0;
    padding: 15px 0 15px;
  }

  .cart-feedback__title{
    padding: 0 21px 0;
  }

  .cart-feedback__description{
    margin-top: 10px;
  }

  .b24-widget-button-inner-mask{
    width: 56px !important;
    height: 56px !important;
  }

  .b24-widget-button-social-item {
    margin: 0 auto 10px 0 !important;
  }

  .b24-widget-button-position-bottom-right{
    width: 56px;
    height: 56px;
    right: 15px!important;
    align-items: center;
  }

  .cartPage .b24-widget-button-position-bottom-right{
    bottom: 131px !important;
  }

  .bx-touch.crm-widget-button-mobile, .bx-touch.crm-widget-button-mobile body{
    overflow: inherit !important;
  }

  .cart-total_blk{
    padding-top: 10px;
  }

  .cart-total_blk ._total{
    padding: 12px;
    border-radius: 5px;
    border: 1px solid #FF4F00;
    background: #F4FEFE;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.14);
    margin-bottom: 0;
  }

  .card-basket form{
    display: flex;
    flex-direction: column;
  }

  .download-cart{
    order: 3;
    margin-top: 20px;
  }

  #dop__product_title{
    margin-top: 15px;
  }

  ._product-cnt{
    position: relative;
    display: inline-block;
    color: #A9A9A9;
    font-size: 14px;
    line-height: 120%;
    margin-left: 13px;
    bottom: 3px;
  }

  ._catalog-slider{
    overflow: hidden;
    margin: 0 -10px;
  }

  ._catalog-slider .swiper-wrapper{
    flex-wrap: nowrap;
  }

  ._catalog-slider ._catalog .swiper-slide{
    width: 208px;
    height: auto;
    margin-top: 8px;
  }

  ._catalog-slider .mini_catalog .col_12{
    min-width: inherit;
  }

  ._catalog-slider .swiper-slide{
    padding: 0 5px;
  }

  ._catalog-slider .small_row{
    display: flex;
    flex-direction: column;
    margin: 0;
  }

  ._catalog-slider .col_cart ._item-cnt{
    display: none;
  }

  ._catalog-slider .col_summary{
    display: flex;
    flex-direction: column;
  }

  ._catalog-slider .col_summary .item.mini{
    flex-grow: 1;
  }

  ._catalog-slider .col_summary .price{
    display: flex;
    height: 100%;
    min-height: 40px;
    flex-direction: column;
    justify-content: flex-end;
    border-top: none;
    padding-bottom: 5px;
  }

  ._catalog-slider .price .old-price{
    display: none;
  }

  ._catalog-slider .price .basePrice{
    font-size: 18px;
    font-weight: 700;
  }

  ._catalog-slider .mini_catalog .default_product .col_image{
    width: 100%;
    padding: 11px 0 8px !important;
  }

  ._catalog-slider .col_cart{
    position: absolute;
    bottom: 18px;
    right: 10px;
    justify-content: flex-end;
  }

  ._catalog-slider .send-cart,
  ._catalog-slider .send-cart.product-in-cart{
    width: 60px;
    height: 34px;
  }

  ._catalog-slider .send-cart .js-text-button{
    display: none;
  }

  ._catalog-slider .mini_catalog .default_product{
    padding: 10px 10px 18px;
    border-radius: 5px;
  }

  ._catalog-slider .default_product .col_summary{
    margin-left: 0;
  }

  ._catalog-slider .default_product .headline{
    width: 100%;
    max-width: 100%;
    font-size: 14px;
    line-height: 120%;
    height: 50px;
    overflow: hidden;
  }

  ._catalog-slider .default_product .image img{
    width: 188px !important;
    height: 121px !important;
    object-fit: contain;
  }

  ._catalog-slider .item + .item{
    margin-top: 11px;
  }

  ._catalog-slider .default_product .send-cart ._ico-cart{
    margin: 0;
    font-size: 19px;
  }

  ._catalog-slider .price .old-price + .basePrice{
    font-size: 18px;
    line-height: 120%;
  }

  ._catalog-slider .price .basePrice span{
    font-size: 12px;
  }

  ._pagination-product{
    width: 170px !important;
    justify-content: center;
    margin: 20px auto 0;
    transform: none !important;
  }

  ._cart-result{
    display: flex;
    justify-content: space-between;
    background: #FFF;
    box-shadow: -1px 0px 6px 0px rgba(0, 0, 0, 0.12);
  }

  ._cart-result-sum{
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    padding: 0 32px;
  }

  ._cart-result__price{
    display: flex;
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    color: #000000;
    white-space: nowrap;
  }

  ._cart-result__cnt{
    font-size: 14px;
    line-height: 17px;
    font-weight: 400;
    color: #C2C2C2;
    text-align: center;
  }

  ._catalog-slider .c-rating-preview ._stars{
    width: 15px;
    height: 15px;
    background: inherit;
  }

  ._catalog-slider .c-rating-preview ._active{
    width: 15px !important;
    height: 15px;
  }

  ._catalog-slider .c-rating-preview ._active:before{
    width: 15px;
    height: 15px;
    background: url(/build/images/star-one.e57a5fc4.svg)no-repeat center center;
  }

  ._catalog-slider .c-rating-preview ._num{
    font-size: 12px;
    line-height: 120%;
    padding-top: 0;
  }

  ._catalog-slider  .default_product .col_image .image{
    max-width: 100%;
    height: 121px;
  }

  .cart_generate_pdf{
    text-decoration: none;
    background: none;
    border: 1px solid #f66625;
    border-radius: 3px;
    box-shadow: none;
    color: #f66625;
    font-weight: bold;
    padding: 7px 8px;
  }
  .cart_generate_pdf:hover{
    background: #f66625;
    color: white;
  }
  #dop__product_title{
    margin-top: 40px;
  }

  ._product-cnt{
    display: none;
    font-weight: 400;
  }

  ._catalog-slider{
    margin: 0 -12px;
  }

  ._catalog-slider .swiper-slide{
    padding: 0 12px;
  }

  ._catalog-slider .swiper-wrapper{
    flex-wrap: wrap;
  }

  ._catalog-slider ._catalog .swiper-slide{
    height: auto;
  }

  ._cart-result-sum{
    display: none;
  }

  ._stars-num{
    color: #FF9C00;
    font-size: 12px;
    line-height: 120%;
    margin-right: 9px;
  }
}

._menu_price-list{
  position: relative;
}

._menu_price-list:before{
  position: absolute;
  content: url(/build/images/_price-list.5261aced.svg);
  left: 13px;
}

._menu_discounted{
  position: relative;
}

.mob-menu_categories.swipe-menu_mobile li a._menu_discounted{
  border-top: 5px solid #e9e9e9;
}

.mob-menu_categories.swipe-menu_mobile li a._menu_price-list{
  border: none;
}

._menu_discounted:before{
  position: absolute;
  content: url(/build/images/_discounted.1463f910.svg);
  left: 11px;
}

@media (max-width: 768px) {
  .filter-set--mobile{
    display: flex;
    justify-content: space-between;
    padding-bottom: 8px;
    border-bottom: 1px solid #efefef;
  }

  .filter-set .right-part{
    max-width: 100%;
    margin-right: auto;
    border-radius: 5px;
    background: transparent;
  }

  .filter-set .price_sort {
    display: flex;
    justify-content: center;
    align-content: center;
    gap: 10px;
    color: #000;
    padding: 9px 0;
    text-align: center;
    font-size: 14px;
    line-height: 20px;
  }

  .filter-set .price_sort.asc svg{
    transform: rotate(180deg);
  }

  .filter-set .btn-filter-catalog_blk{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;
    margin: 0;
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    padding: initial;
    background: initial;
    color: black;
  }

  .filter_seo_block .filter_blk{
    padding: 11px;
    border-radius: 2px;
    background: #F9F9F9;
  }

  .cart-total_blk{
    padding-top: 10px;
  }

  .cart-total_blk ._total{
    padding: 12px;
    border-radius: 5px;
    border: 1px solid #FF4F00;
    background: #F4FEFE;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.14);
    margin-bottom: 0;
  }

  .card-basket form{
    display: flex;
    flex-direction: column;
  }

  .download-cart{
    order: 3;
    margin-top: 20px;
  }

  #dop__product_title{
    margin-top: 15px;
  }

  ._product-cnt{
    position: relative;
    display: inline-block;
    color: #A9A9A9;
    font-size: 14px;
    line-height: 120%;
    margin-left: 13px;
    bottom: 3px;
  }

  ._catalog-slider{
    overflow: hidden;
    margin: 0 -10px;
  }

  ._catalog-slider .swiper-wrapper{
    flex-wrap: nowrap;
  }

  ._catalog-slider ._catalog .swiper-slide{
    width: 208px;
    height: auto;
    margin-top: 8px;
  }

  ._catalog-slider .mini_catalog .col_12{
    min-width: inherit;
  }

  ._catalog-slider .swiper-slide{
    padding: 0 5px;
  }

  ._catalog-slider .col_cart ._item-cnt{
    display: none;
  }

  ._catalog-slider .col_summary{
    display: flex;
    flex-direction: column;
  }

  ._catalog-slider .col_summary .item.mini{
    flex-grow: 1;
  }

  ._catalog-slider .col_summary .price{
    display: flex;
    height: 100%;
    min-height: 40px;
    flex-direction: column;
    justify-content: flex-end;
    border-top: none;
    padding-bottom: 5px;
  }

  ._catalog-slider .price .old-price{
    display: none;
  }

  ._catalog-slider .price .basePrice{
    font-size: 18px;
    font-weight: 700;
  }

  ._catalog-slider .mini_catalog .default_product .col_image{
    width: 100%;
    padding: 11px 0 8px !important;
  }

  ._catalog-slider .col_cart{
    position: absolute;
    bottom: 18px;
    right: 10px;
    justify-content: flex-end;
  }

  ._catalog-slider .send-cart{
    width: 60px;
    height: 34px;
  }

  ._catalog-slider .send-cart .js-text-button{
    display: none;
  }

  ._catalog-slider .mini_catalog .default_product{
    padding: 10px 10px 18px;
    border-radius: 5px;
  }

  ._catalog-slider .default_product .col_summary{
    margin-left: 0;
    padding: 0;
  }

  ._catalog-slider .default_product .headline{
    width: 100%;
    max-width: 100%;
    font-size: 14px;
    line-height: 120%;
    height: 50px;
  }

  ._catalog-slider .default_product .image img{
    width: 188px !important;
    height: 121px !important;
    object-fit: contain;
  }

  ._catalog-slider .item + .item{
    margin-top: 11px;
  }

  ._catalog-slider .default_product .send-cart ._ico-cart{
    margin: 0;
    font-size: 19px;
  }

  ._catalog-slider .price .old-price + .basePrice{
    font-size: 18px;
    line-height: 120%;
  }

  ._catalog-slider .price .basePrice span{
    font-size: 12px;
  }

  ._pagination-product .swiper-pagination-bullet{
    width: 24px;
    height: 5px;
    border-radius: 0;
    margin: 0 5px !important;
    transform: none !important;
  }

  ._cart-result{
    display: flex;
    justify-content: space-between;
    background: #FFF;
    box-shadow: -1px 0px 6px 0px rgba(0, 0, 0, 0.12);
  }

  ._cart-result-sum{
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    width: 100%;
    padding: 0 32px;
  }

  ._cart-result__price{
    display: flex;
    justify-content: center;
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    color: #000000;
    white-space: nowrap;
  }

  ._cart-result__cnt{
    font-size: 14px;
    line-height: 17px;
    font-weight: 400;
    color: #C2C2C2;
    text-align: center;
  }

  ._catalog-slider .c-rating-preview ._stars{
    width: 15px;
    height: 15px;
    background: inherit;
  }

  ._catalog-slider .c-rating-preview ._active{
    width: 15px !important;
    height: 15px;
  }

  ._catalog-slider .c-rating-preview ._active:before{
    width: 15px;
    height: 15px;
    background: url(/build/images/star-one.e57a5fc4.svg)no-repeat center center;
  }

  ._catalog-slider .c-rating-preview ._num{
    font-size: 12px;
    line-height: 120%;
    padding-top: 0;
  }

  ._catalog-slider  .default_product .col_image .image{
    max-width: 100%;
    height: 121px;
  }

  .cart-total_blk .col_._cart-result {
    bottom: 0;
    left: 0;
    margin-left: 0;
    position: fixed;
    z-index: 40;
    width: 100%;
  }
}

@media (min-width: 768px) {
  #_catalog-slider .swiper-wrapper{
    flex-wrap: wrap;
    margin: -24px -12px 0;
  }

  #_catalog-slider .swiper-slide{
    padding: 0 12px
  }
}
.product-image_blk .owl-nav.disabled{
  justify-content: space-between;
  position: relative;
  top: 48%;
  width: 100%;
}

._product-image-item:hover .owl-nav.disabled{
  display: flex;
}

.product-image_blk .owl-carousel .owl-nav button.owl-next{
  position: relative;
  color: transparent;
  width: 38px;
  height: 38px;
  background: url(/build/images/_owl-next.cce40a50.svg) no-repeat center/cover;
  opacity: 0;
  z-index: -1;
  transition: .3s;
}

.product-image_blk .owl-carousel .owl-nav button.owl-prev{
  position: relative;
  color: transparent;
  width: 38px;
  height: 38px;
  background: url(/build/images/_owl-prew.e2726082.svg) no-repeat center/cover;
  opacity: 0;
  z-index: -1;
  transition: .3s;
}

.product-image_blk .owl-carousel .owl-nav button.owl-prev.active,
.product-image_blk .owl-carousel .owl-nav button.owl-next.active{
  z-index: initial;
}

._product-image-item .navigation-thumbs .owl-nav{
  display: none;
}

._product-image-item .owl-carousel .owl-nav button.owl-prev,
._product-image-item .owl-carousel .owl-nav button.owl-prev.active{
  position: absolute;
  top: -150px;
  left: -30px;
  opacity: 0;
}

._product-image-item .owl-carousel .owl-nav button.owl-next,
._product-image-item .owl-carousel .owl-nav button.owl-next.active{
  position: absolute;
  top: -150px;
  right: -30px;
  opacity: 0;
}

.pr-slider:hover .owl-nav button.owl-prev.active,
.pr-slider:hover .owl-nav button.owl-next.active{
  z-index: initial;
  opacity: 1;
}

.pr-slider:hover .owl-nav button.owl-prev.active{
  left: 0;
}

.pr-slider:hover .owl-nav button.owl-next.active{
  right: 0;
}

._product-image-item .owl-nav.disabled{
  display: flex;
  z-index: -1;
}

.pr-slider:hover .owl-nav.disabled{
  display: flex;
  z-index: initial;
}


@media (max-width: 1024px) {
  ._product-image-item .owl-nav,
  .pr-slider:hover .owl-nav.disabled{
    display: none;
  }
}

@media (min-width: 769px) {
  ._cart-result-sum{
    display: none;
  }
}

.cookie-block{
  display: none;
  gap: 43px;
  position: fixed;
  z-index: 11000;
  right: 20px;
  bottom: 30px;
  border-radius: 12px;
  border: 1px solid #D9DBE9;
  background: #F4FEFE;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.25);
  padding: 25px 30px;
}

.cookie-block.show{
  display: flex;
}

.cookie-content{
  max-width: 405px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  color: #000;
}

.cookie-content a{
  text-decoration-line: underline;
  transition: 0.3s;
  color: #000;
}

.cookie-content a:hover{
  color: #FF4F00;
}

.cookie-btn{
  width: 123px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 9px;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  color: #FFF;
  border-radius: 5px;
  background: #FF4F00;
  cursor: pointer;
}

@media (max-width: 768px) {
  .cookie-block{
    width: calc(100% - 20px);
    right: 10px;
    bottom: 10px;
    flex-direction: column;
    align-items: center;
    text-align: left;
    gap: 16px;
    padding: 26px 36px 30px;
  }
}

._discount .delivery-card__item:first-child{
  border: none;
}

.discounts-banner{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 40.5px;
  border-radius: 12px;
  border: 1px solid #FF4F00;
  background: #F4FEFE;
}

.discounts-banner-text__wrapper{
  flex-grow: 1;
  display: flex;
  justify-content: center;
}

.discounts-banner-description{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
}

.discounts-banner__title{
  color: #FF4F00;
  font-family: Inter;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  text-transform: uppercase;
}

.discounts-banner__subtitle{
  color: #FF4F00;
  font-family: Inter;
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  text-transform: uppercase;
}

.discounts-banner-text{
  max-width: 287px;
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: 0.56px;
}

._discount-title{
  color: #2A2A2A;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  padding-left: 42px;
  margin-top: 20px;
}

._discount .delivery-card__item{
  align-items: center;
}

._discount .delivery-card-title{
  margin: 0 0 20px;
}

.discounts-card--discounts .discounts-card-img{
  width: 273px;
  padding: 0 42px;
}

.discounts-card--discounts .discounts-card-img img{
  display: block;
}

.discounts-card--discounts .delivery-card__description{
  padding-left: 8px;
}

.discounts-card--discounts .delivery-card__item{
  padding: 20px 0 0;
}

.discounts-card--discounts .delivery-card-text{
  max-width: 655px;
}

.discounts-banner-2{
  margin-top: 30px;
}

.discounts-banner-2 .discounts-banner-text{
  max-width: 330px;
}

.discounts-banner-wrapper{
  display: flex;
  align-items: center;
  gap: 23px;
}

.discounts-banner-sale{
  display: flex;
  align-items: center;
  gap: 10px;
}

.discounts-banner-sale-text{
  color:  #FF4F00;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  text-transform: uppercase;
}

.discounts-banner-sale-sum{
  display: flex;
  align-items: center;
  color: #FF4F00;
  font-family: Inter;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  text-transform: uppercase;
}

.discounts-banner-sale-sum span{
  display: flex;
  align-items: center;
  font-size: 30px;
}

.discounts-banner-2 .discounts-banner__subtitle{
  font-size: 20px;
}

._discount-text--orange{
  color: #FF4F00;
}

._discount-text{
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin: 20px 0;
}

._discount-container{
  padding-left: 42px;
  margin: 20px 0;
}

._discount-example{
  padding: 10px 20px 10px 28px;
  border-top: 1px solid #B0C0BF;
  border-bottom: 1px solid #B0C0BF;
}

._discount ul>li{
  line-height: 106%;
}

.discounts-banner-3{
  margin-top: 30px;
}

.discounts-banner-3 .discounts-banner__subtitle{
  font-size: 20px;
}

.discounts-banner-3 .discounts-banner__title span{
  font-size: 30px;
}

.discounts-banner-3 .discounts-banner__title .discounts-banner__title__sum{
  font-size: 40px;
}

.discounts-banner-3 .discounts-banner-text{
  max-width: 330px;
}

._discount-container ul{
  margin: 20px 0;
}

.delivery-banner--discount{
  margin-top: 30px;
}

.delivery-price-text .delivery-price-text__link{
  text-decoration-line: underline;
  text-decoration-skip-ink: none;
}

.delivery-price-text__link:hover{
  text-decoration: none;
}

.delivery-price-text._color-gray{
  color: #747474;
}

._discount-container ._discount-title{
  padding-left: 0;
}

._discount-container ul>li:before{
  top: 6px;
}

.delivery-price__discount ul>li:before{
  top: 8px;
}

@media (max-width: 1250px) {
  .discounts-banner-description{
    padding-right: 25px;
  }

  .discounts-banner__title{
    font-size: 18px;
  }

  .discounts-banner__subtitle{
    font-size: 16px;
    text-transform: lowercase;
  }

  .discounts-banner-sale-text{
    font-size: 14px;
  }

  .discounts-banner-sale-sum{
   font-size: 20px;
  }

  .discounts-banner-sale-sum span{
   font-size: 16px;
  }

  .discounts-banner-3 .discounts-banner__title span{
    font-size: 16px;
  }

  .discounts-banner-3 .discounts-banner__title .discounts-banner__title__sum{
    font-size: 18px;
  }

  .discounts-banner-2 .discounts-banner__subtitle,
  .discounts-banner-3 .discounts-banner__subtitle{
    font-size: 16px;
  }

  .discounts-banner-3 .discounts-banner__subtitle{
    text-transform: initial;
  }

  .discounts-banner-wrapper{
    gap: 7px;
  }

  .discounts-banner__img{
    width: 70px;
    height: 70px;
    flex-shrink: 0;
  }

  .discounts-banner__img img{
    width: 100%;
    height: 100%;
  }
}

@media (max-width: 1024px) {
  .discounts-banner{
    padding: 10px 12px 14px 12px
  }
}

@media (max-width: 768px) {
  ._discount-container{
    padding-left: 0;
  }

  ._discount-example {
    padding: 10px 5px 10px 12px;
    width: calc(100% - 10px);
    margin-left: auto;
  }


  .discounts-banner-description {
    padding-right: 25px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .discounts-card--discounts .discounts-card-img {
    padding: 0 10px 0 12px;
    width: 137px;
  }

  ._discount .delivery-card-title{
    margin-bottom: 8px;
  }

  ._discount-title{
    padding-left: 0;
  }

  .discounts-banner-sale{
    gap: 4px;
  }

}

.mini_catalog ._stars-num,
.related-catalog_blk .c-rating-preview ._stars-num{
  display: none;
}



.related-catalog_blk .c-rating-preview ._num{
  margin-left: 8px;
}

.mini_catalog .c-rating-preview ._num{
  margin-left: 5px;
}

@media (max-width: 768px) {
  ._catalog-slider .mini_catalog ._stars-num{
    display: block;
  }

  ._catalog-slider .mini_catalog .c-rating-preview ._num{
    margin-left: 0;
  }

  .modal-feedback-form .vacancy-feedback-content{
    height: initial;
  }

  .modal-feedback-form.active .vacancy-feedback-content{
    position: absolute;
    height: initial;
    top: 50%;
    transform: translate(0, -50%);
    width: calc(100% - 20px);
  }
}

.error-info{
  border-radius: 5px;
  background: #FFF;
  color: #747474;
  border: 1px solid #d7d9da;
  box-shadow: 0 4px 15px rgba(0,0,0,.18);
  padding: 20px 22px;
  margin: 20px 0;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 14px;
}

.error-info strong{
  color: black;
}

.c-popup-error{
  display: none;
  width: 640px;
  border-radius: 10px;
  border: 1px solid #EFEFEF;
  background: #FFF;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.18);
  padding: 30px 35px 35px;
}

.c-popup-error.show{
  display: block;
}

._c-popup__cross{
  position: absolute;
  right: -21px;
  top: -21px;
  cursor: pointer;
}

.c-popup-error__title{
  color: #000;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin-bottom: 30px;
}

.c-popup-error__subtitle{
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin-bottom: 7px;
}

.c-popup-error__content{
  height: 48px;
  color: #2B2B2B;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 129.023%;
  overflow: auto;
  margin-bottom: 21px;
}

.c-popup-error__content::-webkit-scrollbar {
  width: 7px;
}

.c-popup-error__content::-webkit-scrollbar-track {
  background: #F5F5F5;
  border-radius: 10px;
}

.c-popup-error__content::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #D9D9D9;
}

.c-popup-error__message{
  margin-bottom: 30px;
}

.c-popup-error__message label{
  display: flex;
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin-bottom: 10px;
}

.c-popup-error__message textarea{
  border-radius: 5px;
  border: 1px solid #E1E3E3;
  background: #FFF;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.10) inset;
}

.c-popup-error__btn{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 175px;
  height: 38px;
  color: #FFF;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin: 0 auto;
  border-radius: 5px;
  background: #FF4F00;
}

.c-popup-success{
  width: 470px;
  padding: 40px 50px;
  text-align: center;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.18);
}

.c-popup-success.show{
  display: block;
}

.c-popup-success__text{
  color: #000;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin-top: 30px;
}

.item-mode:hover {
  cursor: pointer;
}

.item-mode:hover .arrow-popup{
  transform: rotate(180deg);
  margin-bottom: -5px;
}

.item-mode:hover .header-popup{
  position: absolute;
  display: block;
  border-radius: 5px;
  border: 1px solid #EFEFEF;
  background: #FFF;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.18);
  padding: 20px 15px;
}

@media (max-width: 1024px) {
  .item-mode:hover {
     box-shadow: none;
     left: initial;
  }

  .error-info{
    display: none;
  }
}

.slider-mobile .swiper-wrapper{
  flex-wrap: wrap;
}

.slider-mobile ._pagination-product{
  display: none;
}

.slider-mobile .swiper-slide{
  padding: 0 12px;
}

.slider-mobile{
  margin: -12px;
}

@media (max-width: 768px) {
  .slider-mobile .swiper-wrapper{
    flex-wrap: nowrap;
  }

  ._catalog-slider .default_product{
    width: 100%;
  }

  .related-catalog_blk.mob-add{
    width: 94vw;
  }

  .slider-mobile .item.mini .c-rating-preview{
    border: none;
  }
  .slider-mobile .c-rating-preview ._num{
    color: #AEAEAE;
  }

  .slider-mobile._catalog .swiper-slide{
    width: 218px;
  }

  .slider-mobile form{
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
  }

  .slider-mobile ._pagination-product{
    display: flex;
    justify-content: center;
  }

  .slider-mobile ._pagination-product .swiper-pagination-bullet{
    flex-shrink: 0;
  }

  ._pr-desc-title{
    font-size: 19px;
  }

  .slider-mobile{
    margin: 0;
  }

  .product_section .product-image_blk .headline,
  .blk_headline,
  .pr-video h5,
  .prod-info .blk_headline,
  .trust li:first-child{
    font-size: 19px;
  }
}

@media (max-width: 499px){
  .slider-mobile .default_product .send-cart {
    height: 34px;
  }

  .blk_headline__seo{
    margin-bottom: 10px;
  }

  .seo-catalog_blk__mobile{
    margin-top: 10px;
  }
}

@media (max-width: 500px) {
  .cart-clear__popup{
    width: calc(100% - 20px);
    left: 50%;
    transform: translate(-50%, 0);
    margin: 0;
  }

  h2{
    font-size: 20px !important;
  }

  .cart-clear__popup ._title{
    max-width: 280px;
    margin: 0 auto 12px;
  }
}

.slider-mobile .swiper-slide{
  height: auto;
}

.owl-carousel .animated {
  -webkit-animation-duration: 500ms;
  animation-duration: 500ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.payment-logo{
  max-width: 422px;
}

.payment-logo img{
  width: 100%;
}

.delivery-payment__item-header img{
  height: 20px;
}

.delivery-payment__item-header img:first-child{
  margin-left: 20px;
}

.product_section .product-image_blk .price{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.product_section .product-image_blk .price .base-price span{
  font-size: 14px;
}

.price-inner{
  display: flex;
  flex-direction: column;
}

.price-meter{
  width: 50%;
  font-size: 16px;
  font-weight: 700;
  line-height: 120%;
  color: #4F4E4E;
  border-left: 1px solid #4F4E4E;
  padding: 7px 0;
  text-align: center;
}

@media (max-width: 768px) {
  .product_section .product-image_blk .price {
    border-radius: 3px;
    background: #DFF0F7;
    padding: 9px 0 9px 20px;
  }

  .review-form_blk .blk_headline{
    padding-bottom: 0;
  }

  .page_headline.page_headline--filter{
    margin: -9px 0 6px;
  }
}

body.compensate-for-scrollbar{
  padding: 0;
}

.control-widget__text{
  color: #A9A9A9;
  font-size: 14px;
  line-height: 20px;
  margin-top: 10px;
}

.control-row.control-row--phone{
  align-items: flex-start;
}

.control-row.control-row--phone .control-label{
  margin-top: 8px;
}

.control-submit-column{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.header-bottom{
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 19.2px;
  padding: 12px 0 22px;
}

.header.scroll .header-bottom{
  padding: 15px 0;
}

.header-logo img,
.header-logo a{
  display: block;
}

.header-search{
  flex-grow: 1;
}

.header{
  position: relative;
  box-shadow: 0 2px 15px 0 rgba(0,0,0,.1);
  z-index: 1000;
  width: calc(100% + 15px);
  left: -15px;
  margin-bottom: 25px;
  font-family: PT Sans,sans-serif;
}

.header-login{
  display: flex;
  gap: 19.2px;
}

.header-catalog{
  display: flex;
  align-items: center;
  gap: 23px;
  padding: 0 23px;
  border-radius: 5px;
  border: 1px solid #EFEFEF;
  background: #E1F0F8;
  cursor: pointer;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 38px;
  text-transform: none;
  color: #000;
  text-decoration: none;
}

.header-catalog path{
  transition: .3s;
}

.header-catalog:hover{
  background: #FF4F00;
}

.header-catalog:hover path{
  stroke: white;
}

.header-catalog:hover a.header-catalog,
a.header-catalog:hover{
  color: white;
}

.header-cart a{
  width: 82px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
}

.header-cart__icon{
  display: flex;
  position: relative;
}

.header-cart__icon .num{
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 15px;
  height: 12px;
  top: -5px;
  right: -8px;
  background: #FF4F00;
  border-radius: 4px;
  color: #FFF;
  font-size: 9px;
  line-height: 9px;
  font-style: normal;
  font-weight: 400;
  padding: 0 3px;
}

.header-cart .cart-total-header{
  color: #000;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  text-decoration: none;
}

.header-login .login_blk{
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header-login .login_blk__item{
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.header-login .login_blk__item span,
.header-login a{
  width: 90px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: center;
  text-decoration: none;
  font-size: 13px;
  font-weight: 400;
}

.header-cart a{
  overflow: initial;
}

.login_blk_popup{
  display: none;
  position: absolute;
  right: -2px;
  top: 40px;
  z-index: 20;
  padding-top: 10px;
}

.login_blk-menu{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  list-style-type: none;
  margin: 0;
  border: 1px solid #EFEFEF;
  background: #FFF;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.18);
  padding: 16px 34px 16px 16px;
  border-radius: 5px;
}

.login_blk-menu__item{
  margin: 0;
  padding: 0;
}

.login_blk-menu__item a{
  color: #2A2A2A;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 145.5%;
  text-decoration: none;
  white-space: nowrap;
}

.login_blk:hover .login_blk_popup{
  display: block;
}

.login_blk:hover .login_blk__item svg{
  transform: rotate(180deg);
}

.login_blk-menu>li:before{
  content: none;
}

.login_blk__img{
  display: flex;
  justify-content: center;
}

.header-search-btn{
  position: absolute;
  right: 20px;
  top: 8.5px;
}

.header-search .pulse-button{
  display: none;
}

.header-top{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0 0;
}

.shop-data{
  display: flex;
  align-items: center;
  gap: 35px;
}

.shop-data-phone{
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  text-decoration: none;
  color: #2A2A2A;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}

.shop-data-phone:hover{
  color: #ff4f00;
}

.shop-data-time,
.shop-data-location{
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  color: #000;
  font-family: "PT Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 15.4px;
  text-decoration: none;
}

.nav-list{
  display: flex;
  align-items: center;
  gap: 43px;
  margin: 0;
}

.nav-list>li{
  margin: 0;
}

.nav-list>li:before{
  content: none;
}

.nav-list__el a{
  display: flex;
  align-items: center;
  gap: 5px;
  color: #000000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  text-decoration: none;
  transition: 0.3s;
}

.nav-list__el.nav-list__el--brand a{
  color: #FF4F00;
  font-weight: 700;
}

.nav-list__el:hover a{
  color: #FF4F00;
}

.header-scroll{
  display: none;
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  background: #FFF;
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.10);
  transition: .3s;
  z-index: 1000;
}

.header-scroll .main_wrapper{
  box-shadow: none;
}

.header-scroll .header-bottom{
  padding: 15px 0;
}

.header_panel{
  display: none;
}

@media (max-width: 859px) {
  .header {
    display: none;
  }
  .header_panel,
  .header-popup{
    display: block;
  }

  .header-popup{
    position: static;
    width: 100%;
  }

  .mobil-phones_popup{
    background: #fff;
    display: block;
    max-width: 100%;
    height: 100%;
    left: -100%;
    max-height: calc(100vh - 90px);
    overflow-y: auto;
    position: fixed;
    top: 90px;
    width: 100%;
  }

  .mobil-phones_popup.active{
    left: 0;
    transform: translate(0);
    box-shadow: none;
  }

  .header-popup__item ._phone-list__text a{
   text-decoration: none;
    font-size: 19px;
    line-height: 120%;
    margin-top: 3px;
  }

  .header-popup__item ._phone-list li._phone-list__text{
    align-items: flex-start;
    gap: 3px;
  }

  ._phone-list__content{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  ._phone-list__time{
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
  }

  ._phone-list__work{
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
  }

  .header-popup{
   border: none;
  }

  .header-popup__item ._phone-list{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin: 0;
    padding: 16px 0 16px;
    border-bottom: 2px solid rgba(226, 226, 226, 0.5);
  }

  .header-popup__item ._phone-list:first-child{
    border-top: 2px solid rgba(226, 226, 226, 0.5);
  }

  .header-popup__item{
   gap: 0;
  }

  .social-networks__item span{
   margin: 0;
  }

}

.header ._container{
  position: relative;
  left: 7px;
  max-width: 1280px;
  margin: 0 auto;
}

@media (min-width: 859px) {
  .header-popup__item:last-child{
    border-top: 2px solid rgba(230, 230, 230, 0.5);
    margin-top: 12px;
  }

  .header-popup__item .social-networks{
    width: 100%;
    justify-content: center;
    gap: 20px;
    padding-top: 12px;
    margin-left: -7px;
  }
}

.header-popup__item ._phone-list li > span{
  display: flex;
  align-items: center;
}

.header-popup__item ._phone-list li{
  gap: 10px;
}

@media screen and (min-width: 1024px) {
  /*  html {
      margin-left: calc(100vw - 100% - 15px);
      margin-right: 0;
    }*/
}

.footer_panel ._container{
  max-width: 1280px;
  margin: 0 auto;
}

.product_discount{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-top: 20px;
  cursor: pointer;
  padding: 7px 0;
  border: 1px solid #FF4F00;
  border-radius: 3px;
}

.product_discount__link{
  color: #FF4F00;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
  text-decoration-line: underline;
  text-decoration-skip-ink: none;
}

.product_discount__icon{
  display: flex;
}

.summary.-shadow_default{
  padding-bottom: 24px;
}

._modal{
  position: fixed;
  width: 768px;
  left: 50%;
  top: 50%;
  transform: translate(-50% , -50%);
  opacity: 0;
  z-index: -1;
  padding: 25px 40px 40px;
  border-radius: 20px;
  background: #FFF;
}

._modal.active{
  opacity: 1;
  z-index: 10001;
}

._c-popup-product-content{
  background: white;
  font-family: PT Sans, sans-serif;
}


@media (max-width: 768px) {
  .pr-im, .prod-info>li, .product_section .default_product{
    border: none;
    padding: 0;
  }
}

h2._pr-desc-title,
h2._pr-desc-title{
  margin: 0;
}

.c-popup-holiday{
  padding: 0;
  margin: 0;
  transform: translate(-50%, 0);
  width: 496px;
  min-height: 220px;
  max-width: calc(100% - 30px);
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.10);
  top: 145px;
  border-radius: 28px;
}

.c-popup-holiday .blk_body{
  border-radius: 28px;
  overflow: hidden;
}

.container-holiday {
  position: absolute;
  border-radius: 10px;
  left: 50%;
  bottom: 44px;
  transform: translateX(-50%);
  min-width: 410px;
  font-family: PT Sans,sans-serif;
}

.container-holiday__title{
  font-weight: 700;
  font-size: 41px;
  line-height: 120%;
}

.container-holiday__text{
  font-size: 21px;
}

.container-holiday p:last-child{
  margin: 0;
}

.container-holiday p:not(:last-child){
  margin-bottom: 17px;
}

.c-popup-holiday img{
  display: block;
}

.c-popup.c-popup-holiday  ._ico-close{
  background: none;
  border: none;
  right: -23px;
  top: -30px;
}

.c-popup-holiday ._ico-close:before{
  content: none;
}

.c-popup.c-popup-holiday ._ico-close {
  right: 0;
  top: -40px;
}

@media (max-width: 768px) {
  .c-popup-holiday {
    top: 137px;
    height: 312px;
  }

  .container-holiday p:not(:last-child) {
    margin-bottom: 15px;
  }
  .c-popup-holiday .blk_body {
    width: 100%;
    height: 100%;
  }

  .c-popup-holiday img {
    display: block;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .container-holiday{
    width: 100%;
    min-width: initial;
    max-width: 285px;
    bottom: 5px;
  }

  .mobile-login.active svg * {
    fill: none;
    stroke: #ff4f00;
  }

  .container-holiday__title{
    font-size: 32px;
    line-height: 120%;
  }

  .container-holiday__text{
    font-size: 20px;
    line-height: 120%;
  }

  .c-popup.c-popup-holiday ._ico-close{
    top: -30px;
  }
}

.weekend-notice{
  height: 0;
  display: none;
  position: relative;
  width: 100%;
  padding: 12px 28px;
  background: #F4FEFE;
  font-family: PT Sans,sans-serif;
  box-shadow: inset 0px 2px 15px 0px rgba(0, 0, 0, 0.10);
}

.weekend-notice.show{
  height: initial;
  display: block;
}

.weekend-notice-cross{
  position: absolute;
  right: 28px;
  top: 15px;
  cursor: pointer;
}

.weekend-notice-content{
  display: flex;
  justify-content: center;
  gap: 35px;
}

.weekend-notice__text{
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.weekend-notice__data{
  display: flex;
  gap: 45px;
}

.weekend-notice__data__item{
  font-weight: 700;
  color: #FF4F00;
}

.weekend-notice__data__item span{
  color: black;
}

@media (max-width: 768px) {
  .weekend-notice.show,
  .weekend-notice{
    display: none;
  }
}

.weekend-notice-mobile{
  max-width: 252px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #FF4F00;
  color: #FF4F00;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  /*text-transform: lowercase;*/
  text-align: center;
  margin: 12px auto 0;
}

.cart-clear__popup ._btn{
  min-width: 92px;
}

._c-popup-product__title{
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin: 0 0 44px;
  font-family: PT Sans, sans-serif;
}

.markdown-text{
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin-bottom: 44px;
}

.markdown-price{
  color: #E81800;
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  padding: 10px 20px;
  margin-bottom: 30px;
  border-radius: 3px;
  background: #DFF0F7;
}

.markdown-price span{
  font-size: 14px;
  margin-left: 5px;
}
.product-id{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.product-id__number{
  position: relative;
  display: flex;
  align-items: center;
  gap: 5px;
  color: #A9A9A9;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  cursor: pointer;
}

.product-id__status{
  position: relative;
  color: #1FBF2B;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  padding-left: 10px;
}

.product-id__status:before{
  position: absolute;
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #1FBF2B;
  top: 50%;
  left: 0;
  transform: translate(0 , -50%);
}

.product-id__number__info{
  display: none;
  position: absolute;
  top: calc(100% + 3px);
  right: -8px;
  border-radius: 5px;
  border: 1px solid #FF4F00;
  background: #FFF;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.18);
  padding: 5px 8px 7px;
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  white-space: nowrap;
}


.markdown-buy__inner{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.markdown-buy ._counter input{
  height: 36px;
}

.markdown-buy .full-width_btn{
  width: 152px;
}

.fancybox__container{
  z-index: 10003 !important;
}


._c-popup-product .owl-carousel .owl-dots{
  display: flex;
  justify-content: center;
  gap: 4px;
  margin-top: 15px;
}

._c-popup-product .owl-carousel button.owl-dot{
  width: 7px;
  height: 7px;
  background: #A9A9A9;
  border-radius: 50%;
}

._c-popup-product .owl-carousel button.owl-dot.active{
  background: #FE5102;
}

._c-popup-product ._product-image-item{
  position: relative;
}

._c-popup-product .product-label-container{
  top: 0;
  left: 0;
}

.product_discount-info{
  position: relative;
  width: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border-radius: 3px;
  background: #DFF0F7;
  padding: 6px;
  color:  #FF4F00;
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  margin-left: 1px;
  cursor: pointer;
  z-index: 1;
  border: 1px solid #FF4F00;
}

._c-popup-product-content .js-select-length{
  width: 122px;
}

._c-popup-product-content ._product-image-item img{
  max-height: 298px;
}

@media (max-width: 840px) {
  ._c-popup-product .product-image_blk > ._row{
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  ._c-popup__cross {
    right: 0;
    top: -30px;
  }

  ._modal{
    width: calc(100% - 26px);
    border-radius: 10px;
    padding: 20px 15px 30px 15px;
  }


  ._c-popup-product .product-image_blk > ._row >.col_12{
    width: 100%;
    margin: 0;
  }

  ._c-popup-product__title{
    font-size: 16px;
    margin-bottom: 15px;
  }

  .markdown-text{
    font-size: 14px;
    margin-bottom: 15px;
  }

  .markdown-buy .col_6{
    padding: 0;
  }

  .markdown-buy .full-width_btn{
    margin-left: auto;
  }

  .markdown-price{
    margin-bottom: 15px;
  }

  ._c-popup-product ._product-image-item{
    border: none;
  }

  ._c-popup-product ._ico-zoom-fankibox:before{
    display: none;
  }

  ._c-popup-product .pr-slider.owl-carousel{
    min-height: 219px;
    max-height: 219px;
  }

  ._c-popup-product div.owl-item .item.lightbox-container,
  ._c-popup-product div.owl-item .item.lightbox-container img {
    display: block;
    max-height: 219px;
  }

  ._c-popup-product  .product-image_blk ._counter [class^=_ico],
  ._c-popup-product  .product-image_blk ._counter input {
    box-shadow: none;
    height: 36px;
    width: 36px;
  }

  ._c-popup-product__title{
    font-size: 19px!important;
  }

  .product_discount__link{
    font-size: 16px;
  }

  .product_discount-info{
    width: 130px;
    padding: 7px;
  }

  ._c-popup-product-content .js-select-length{
    max-height: 38px;
    max-width: 122px;
  }

  .status-not_in_stock .product_discount-info{
    margin-bottom: 20px;
  }
}

.footer-inner{
  display: flex;
  flex-direction: column;
  gap: 15px;
  min-width: 488px;
}

._footer-networks__text{
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 138%;
  margin-right: 6px;
}

._footer-networks{
  display: flex;
  align-items: center;
  gap: 19px;
}

.footer_blk .large_row>[class^=col_]{
  padding: 0 36px;
}

.footer_blk  .large_row>.col_full:first-child {
  padding: 0 36px 0 36px;
}

.footer_blk .large_row {
  margin: 0 -36px;
}

.footer_blk{
  padding-bottom: 35px;
}

.company-info_blk__inner{
  display: flex;
  justify-content: space-between;
  gap: 70px;
  padding-bottom: 28px;
  font-size: 12px;
  color: #A1A3A9;
}

.company-development ._container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.company-development-copy{
  color: #A1A3A9;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

@media (max-width: 1024px) {
  footer.mob-design{
    font: 400 0.875em/1.4 PT Sans,sans-serif;
  }

  .footer--text{
    margin-top: 15px;
    color: #747474;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
  }

  .company-info_blk{
    color: #747474;
    font-size: 14px;
    border-top: none;
    padding: 0;
  }

  footer .c-google-rating{
    padding: 40px 0;
  }

  .footer{
    padding: 32px 0 30px;
  }

  .rating-blog{
   gap: 0;
  }

  .f-nav{
    display: flex;
    flex-direction: column;
    gap: 25px;
  }

  .f-nav li:not(.black) a{
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
  }

  .f-nav-block__body{
    display: none;
    border-top: 1px solid #d4d0d0;
    border-bottom: 1px solid #d4d0d0;
    padding: 9px 0;
    margin-top: 15px;
  }

  .f-nav-block__head{
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
  }

  footer{
    background-color: #edf6fb;
  }
}

@media (max-width: 768px) {
  .rating-blog__item img{
    width: 35px;
    height: 35px;
    margin-bottom: 5px;
  }

  .rating-blog__title{
    font-size: 16px;
  }

  .rating-blog__content{
    margin-left: 8px;
  }

  .rating-blog__content .c-rating-preview span{
    font-size: 14px;
    font-weight: 400;
  }

  .rating-blog__content p{
    font-size: 13px;
  }

  .rating-blog__content p span{
    font-size: 13px;
  }

  .mob-design .company-info_blk ._container{
    line-height: 120%;
  }

  footer .company-info_blk ._container,
  .f-nav, .mob-design__top, .trust, footer .c-google-rating{
    max-width: 94vw;
  }

  .f-nav-block__head svg{
    margin-right: 5px;
  }

  .f-nav-block__head.active svg{
    transform: rotate(180deg);
  }

  .categories_nav_mob-menu ._ico-arr-right:before{
    content: none;
  }

  .second-level_mob li .mob-menu_categories__arr-icon-level2 span{
    left: initial;
  }
}

.categories_nav_mob-menu li > img{
  position: absolute;
  left: 13px;
  top: 8px;
}

.delivery__new-banner{
  margin: 20px 0 10px;
}

.delivery__new-banner .banner_mobile{
  display: none;
}

.delivery__new-banner img{
  display: block;
  width: 100%;
}

@media (max-width: 768px) {
  .delivery__new-banner .banner_desc{
    display: none;
  }

  .delivery__new-banner .banner_mobile{
    display: block;
  }
}

.article-author{
  display: flex;
  gap: 30px;
  margin-bottom: 20px;
}

.article-author__photo{
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
}

.article-author__photo img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.article-author__text{
  font-size: 16px;
}

.article-author__job{
  color: #A1A3A9;
}

@media (max-width: 499px) {
  .blue-block--product {
    width: calc(100% + 20px);
    margin: 2em -10px 0;
  }
  .product_section .product-image_blk .image{
    border: none;
  }
}

.markdown-quantity{
  margin-bottom: 7px;
  font-size: 12px;
  line-height: 14.4px;
  color: #747474;
  font-weight: 400;
}

._col-num .markdown-quantity{
  margin: 0 auto 7px;
}

@media (min-width: 1200px) {
  ._c-popup-product-content div.owl-stage-outer{
    height: 298px;
  }
}

.product-info{
  display: flex;
  flex-direction: column;
  gap: 8px;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.18);
  background: #fff;
  border: 1px solid #d7d9da;
  border-radius: 0 0 5px 5px;
  padding: 19px 12px;
  margin-top: 28px;
}

.product-info__el{
  display: flex;
  align-items: center;
  font-size: 14px;
}

.product-info__el__i{
  display: flex;
  margin-right: 9px;
}

.product-info__el__modal,
.product-info__el__link{
  color: #FF4F00;
  font-weight: 700;
  text-decoration: underline;
  text-decoration-skip-ink: none;
  transition: .3s;
  cursor: pointer;
  margin-right: 5px;
}

.product-info__el__modal:hover{
  text-decoration: none;
}

._c-popup-info {
  width: 100%;
  background: #fff;
  box-shadow: 0 1px 11px 0 rgba(50,50,50,.59);
  display: none;
  left: 50%;
  top: 5%;
  border-radius: 20px;
  padding: 24px 32px 24px;
  position: fixed;
  max-width: 1125px;
  z-index: 10001;
  transform: translate(-50% , 0);
  font-family: PT Sans,sans-serif;
}

._c-popup-info__title{
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  color: #000;
  margin-bottom: 30px;
}

._c-popup-info__tab{
  display: flex;
  align-items: center;
  gap: 25px;
  margin-bottom: 22px;
}

._c-popup-info__tab__item{
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;
  text-decoration: underline;
  text-decoration-skip-ink: none;
  color: #747474;
  transition: .3s;
  cursor: pointer;
}

._c-popup-info__tab__item.active{
  color: #FF4F00;
  text-decoration: none;
}

._c-popup-info-el{
  display: none;
}

._c-popup-info-delivery{
  display: flex;
  align-items: center;
  gap: 30px;
}

._c-popup-info__text{
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: #000;
  margin: 0;
}

._c-popup-info-delivery__map{
  max-width: 666px;
}

.delivery__map__content{
  width: 100%;
  height: 406px;
  overflow: hidden;
  margin: 15px 0;
}

._c-popup-info-delivery__description{
  display: flex;
  flex-direction: column;
  gap: 38px;
  flex-shrink: 0;
}

._c-popup-info-delivery__title{
  display: flex;
  align-items: center;
  gap: 6px;
  font-weight: 700;
  font-size: 18px;
  line-height: 78%;
  color: #000;
  margin-bottom: 11px;
}

.info-delivery__time{
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: #000;
  margin-bottom: 5px;
}

.info-delivery__day{
  font-weight: 700;
  font-size: 14px;
  line-height: 120%;
  color: #ff4f00;
}

._c-popup-info-delivery__col ul>li:before{
  width: 5px;
  height: 5px;
}

._c-popup-info-delivery__col ul{
  padding-left: 22px;
  margin: 0;
}

._c-popup-info-delivery__col ul li:not(:first-child){
  margin-top: 5px;
}

._c-popup-info-offload{
  max-width: 831px;
}

._c-popup-info-offload .unloading-description-list{
  padding-left: 18px;
}

._c-popup-info-return-content{
  max-height: 326px;
  overflow: auto;
  margin-top: 25px;
  padding-right: 34px;
}

._c-popup-info-return-content::-webkit-scrollbar {
  width: 7px;
}

._c-popup-info-return-content::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #F5F5F5;
}

._c-popup-info-return-content::-webkit-scrollbar-thumb {
  background: #D9D9D9;
  border-radius: 10px;
}

._c-popup-info--return{
  padding-right: 24px;
}

._c-popup-info-return-content ul{
  padding-left: 27px;
}

._c-popup-info-return-content ul li{
  margin: 0;
}

._c-popup-info-return-content ul li:before{
  background: #2b2b2b;
}

._c-popup-info-return-content ._c-popup-info__text:not(:first-child){
  margin: 14px 0;
}

._c-popup-info-return-content ._c-popup-info__text:last-child{
  margin-bottom: 0;
}

._c-popup-info--return-head{
  padding-right: 24px;
}

.text-underline{
  text-decoration: underline;
  text-decoration-skip-ink: none;
}

.product-instalment{
  position: relative;
}

.instalment{
  position: absolute;
  top: 100%;
  left:0;
  width: 285px;
  border: 1px solid #efefef;
  border-radius: 0 0 5px 5px;
  padding: 20px 20px 25px 20px;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.18);
  background: #fff;
  transition: .3s;
  z-index: -1;
  opacity: 0;
  cursor: default;
}

.mobile-content{
  display: none;
}

@media (min-width: 1025px){
  .product-instalment:hover .instalment{
    opacity: 1;
    z-index: 110;
  }

  ._c-popup-info.auto-w{
    min-width: 954px;
  }
}

@media (max-width: 1024px) {
  .product-instalment .instalment{
    display: none;
  }

  .instalment{
    opacity: 1;
    box-shadow: none;
    position: static;
    border: none;
  }

  .product-instalment:hover .instalment{
    display: none;
  }
}

.instalment-inner{
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 25px;
}

.instalment-el{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.instalment-el__i{
  display: flex;
  width: 48px;
  height: 20px;
  margin-right: 20px;
}

.instalment-el__i:first-child,
.instalment-el__i:last-child{
  height: 27px;
}

.instalment-el__i img{
 display: block;
  object-fit: contain;
}

.instalment-el:not(:first-child):not(:last-child) img{
  width: 31px;
}

.instalment-el__title{
  font-weight: 700;
  font-size: 14px;
  line-height: 88%;
  color: #2b2b2b;
  flex-grow: 1;
}

.instalment-el__time{
  font-size: 14px;
  line-height: 88%;
  color: #2b2b2b;
}

.instalment__link {
  display: block;
  font-size: 14px;
  line-height: 88%;
  text-decoration: underline;
  text-decoration-skip-ink: none;
  color: #ff4f00;
  text-align: center;
}

.availability-product{
  display: flex;
  align-items: center;
  gap: 27px;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.18);
  background: #fff;
  border: 1px solid #efefef;
  border-radius: 5px;
  padding: 0 12px;
  margin-bottom: 35px;
}

.availability-product__i{
  width: 53px;
  height: 75px;
  flex-shrink: 0;
  padding: 10px 0;
}

.availability-product__i img{
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.availability-product__name{
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  color: #000;
}

.availability-product__price{
  flex-grow: 1;
  text-align: right;
  font-weight: 700;
  font-size: 19px;
  line-height: 120%;
  color: #000;
  padding: 0 18px;
  flex-shrink: 0;
}

.availability-product__price span{
  font-size: 12px;
}

.availability-tab{
  display: none;
}

.c-popup-availability-content{
  display: flex;
  gap: 30px;
}

.c-popup-availability__map{
  width: 446px;
  height: 100%;
}

.availability-location{
  height: 450px;
  overflow: auto;
  padding-right: 30px;
}

.availability-location::-webkit-scrollbar {
  width: 7px;
}

.availability-location::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #F5F5F5;
}

.availability-location::-webkit-scrollbar-thumb {
  background: #D9D9D9;
  border-radius: 10px;
}

.availability-location__header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.availability-location__title{
  display: flex;
  align-items: center;
  gap: 10px;
}

.availability-location__title .availability-location__link{
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  color: #ff4f00;
  transition: .3s;
}

.availability-location__quantity{
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: #1fbf2b;
}

.availability-location__el:not(:first-child){
    margin-top: 20px;
}

.availability-location__el:not(:last-child){
  border-bottom: 1px solid #c2c2c2;
  padding-bottom: 20px;
}

.availability-contact{
  display: flex;
  align-items: center;
}

.availability-contact__el--phone{
  padding-right: 25px;
  border-right: 1px solid #c2c2c2;
  margin-right: 25px;
}

.availability-contact__el--work{
  display: flex;
  flex-direction: column;
}

.availability-contact__el--work strong{
  font-weight: 700;
  font-size: 13px;
  line-height: 120%;
  text-transform: uppercase;
  color: #000;
}

.availability-contact__el--work span{
  font-weight: 400;
  font-size: 14px;
}

.availability-contact__el ._phone-list{
  padding-left: 0;
  margin: 0;
}

.availability-contact__el ._phone-list li{
  display: flex;
  align-items: center;
  margin: 3px 0 5px;
}

.availability-contact__el ._phone-list li > span{
  display: flex;
  align-items: center;
  gap: 3px;
}

.availability-contact__el ._phone-list li:before{
  content: none;
}

.availability-contact__el ._phone-list li svg{
  width: 11px;
}

.availability-contact__el ._phone-list li svg:last-child{
  width: 15px;
}

.availability-contact__el ._phone-list li:last-child{
  margin-bottom: 3px;
}

.availability-contact__el  ._number{
  font-size: 14px;
  line-height: 120%;
  color: #000;
  text-decoration: none;
  margin-left: 5px;
}

.c-popup-availability__item:first-child{
  flex-grow: 1;
}

._c-popup-info[data-modal="availability"]{
  max-width: 950px;
}

._c-popup-info.auto-w{
  width: auto;
}

._c-popup-info-delivery__col ul li{
  font-size: 14px;
}

.product_section h1{
  line-height: 1.2;
}

._c-popup__cross path{
  stroke: silver;
  stroke-width: 3px;
}

._c-popup-info ._c-popup__cross{
  right: 20px;
  top: 20px;
}

._c-popup-info ._c-popup__cross svg{
  width: 15px;
}

@media (max-width: 1200px) {
  ._c-popup-info ._c-popup__cross {
    right: 15px;
    top: 23px;
  }
}

@media (max-width: 1155px) {
  ._c-popup-info{
    width: calc(100% - 30px);
    max-height: 91vh;
    padding: 20px 15px 30px;
    overflow: hidden;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  ._c-popup-info-content{
    max-height: 67vh;
    overflow: auto;
  }
}

@media (max-width: 1024px) {
  ._c-popup-info__title{
    font-size: 22px;
    line-height: 120%;
    margin-bottom: 15px;
  }

  ._c-popup-info-delivery__title{
    font-size: 16px;
    line-height: 120%;
  }

  ._c-popup-info-delivery__map ._c-popup-info__text{
    font-size: 12px;
    line-height: 120%;
    color: #747474;
  }

  .availability-product{
    display: none;
  }

  .availability-location__title .availability-location__link{
    font-size: 16px;
    line-height: 87%;
  }

  .availability-location__header{
    gap: 10px;
    margin-bottom: 15px;
  }

  .availability-location__el:not(:last-child) {
    padding-bottom: 20px;
  }

  .availability-location__el:not(:first-child) {
    margin-top: 20px;
  }

  .availability-location{
    height: auto;
    max-height: 450px;
    padding-right: 20px;
  }

}

@media (max-width: 950px){
  .c-popup-availability__map{
    max-width: 350px;
  }
}

@media (max-width: 768px) {
  ._c-popup-info-delivery{
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
  }

  ._c-popup-info__tab__item{
   white-space: nowrap;
  }

  ._c-popup-info.auto-w {
    width: calc(100% - 30px);
  }

  ._tab-block{
    position: relative;
    display: flex;
    gap: 0;
    border-radius: 5px;
    overflow: hidden;
  }

  ._tab-block:before{
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: #bdbdbd;
    z-index: 1;
  }

  ._tab-block:after{
    position: absolute;
    content: "";
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: #bdbdbd;
    z-index: 1;
  }


  ._tab-block__el{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 50%;
    font-size: 16px;
    line-height: 87%;
    color: #000;
    text-decoration: none;
    border: 1px solid #BDBDBD;
    height: 40px;
    font-weight: 400;
  }

  ._tab-block__el:first-child{
    border-right: none;
    border-radius: 5px 0 0 5px;
  }

  ._tab-block__el:last-child{
    border-left: none;
    border-radius: 0 5px 5px 0;
  }

  ._tab-block__el.active{
    border: 1px solid #FF4F00;
    border-radius: 5px;
    color: #FF4F00;
    z-index: 2;
  }

  ._c-popup-info-offload .unloading-product,
  ._c-popup-info-offload .unloading-price{
    padding: 0;
  }

  ._c-popup-info-delivery__col ul{
    margin: 10px 0 0;
  }

  ._c-popup-info-delivery__description{
    gap: 20px;
  }

  .instalment{
    width: 100%;
    padding: 0;
  }

  .instalment-el__dot{
    position: relative;
    flex-grow: 1;
  }

  .instalment-el__dot:before{
    position: absolute;
    content: "";
    width: 97%;
    border: 1px dashed;
    bottom: -4px;
    left: 2px;
  }

  .instalment-el__title{
    flex-grow: 0;
  }

  ._c-popup-info-return-content{
    padding-right: 25px;
  }

  .c-popup-availability-content{
    margin-top: 20px;
  }

  .c-popup-availability__item{
    width: 100%;
    display: none;
  }

  .c-popup-availability__item.active{
    display: block;
  }

  ._tab-block__el path{
    stroke: #000;
  }

  ._tab-block__el.active path{
    stroke: #FF4F00;
  }

  .c-popup-availability__map{
    width: calc(100% + 29px);
    margin: 0 -14px -30px;
    height: 506px;
    max-width: initial;
  }
}

@media (max-width: 768px) {
  .product-id--inner {
    margin-bottom: 15px;
  }

  .product_section h1 {
    font-size: 16pt;
    margin-bottom: 0;
  }

  .availability-contact{
    justify-content: center;
  }

  .delivery__map__content{
   height: 365px;
  }

  .availability-location__quantity{
    font-size: 15px;
  }

  .availability-location{
    padding-right: 5px;
  }

  .availability-location::-webkit-scrollbar {
    width: 3px;
  }

  .availability-location__title{
    gap: 7px;
  }
}

.js-cart-item .product-id__number{
  font-size: 12px;
  margin-bottom: 6px;
}
._more-contacts{
  display: flex;
  gap: 20px;
}

._more-contacts__item{
  width: calc(50% - 20px / 2);
  border-radius: 5px;
  border: 1px solid #EFEFEF;
  background: #FFF;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.10);
  padding: 23px;
}

._more-contacts__title{
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  text-align: center;
  margin-bottom: 10px;
}

._more-contacts__el{
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin-bottom: 5px;
}

._more-contacts__el a{
  font-weight: 600;
}

._contact-networks{
  display: flex;
  justify-content: center;
  gap: 16px;
}

._contact-networks__el a{
  display: flex;
}

._contact-info{
  display: flex;
  align-items: center;
  gap: 46px;
  border-radius: 5px;
  border: 1px solid #EFEFEF;
  background: #FFF;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.10);
  margin-top: 20px;
  padding: 20px 28px 20px;
}

._contact-info-description{
  width: 100%;
  max-width: 639px;
}

._contact-info__title{
  margin: 0 0 15px;
}

._contact-info__test{
  max-width: 590px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin: 0 0 20px;
}

._contact-info__test:last-child{
  margin-bottom: 0;
}

._contact-info__btn{
  color: #FFF;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  border-radius: 5px;
  padding: 10px 16px;
}

._contact-content{
  display: flex;
  gap: 26px;
  max-height: 595px;
  margin: 25px 0 25px;
}

._contact-map{
  width: 60%;
  overflow: hidden;
}

._address-list{
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-height: 100%;
  overflow: auto;
  margin: -15px 0 -13px -15px;
  padding: 15px 15px 15px 15px
}

._address-list::-webkit-scrollbar {
  width: 7px;
}

._address-list::-webkit-scrollbar-track {
  border-radius: 5px;
  background: #F5F5F5;
  height: calc(100% - 30px);
  margin: 15px 0 13px;
}

._address-list::-webkit-scrollbar-thumb {
  background: #D9D9D9;
  border-radius: 5px;
}

._address-list__el{
  border-radius: 5px;
  border: 1px solid #EFEFEF;
  background: #FFF;
  box-shadow: 0 0 16.3px 0 rgba(0, 0, 0, 0.18);
  padding: 15px;
}

._address-gallery{
  display: flex;
  gap: 10px;
}

._address-gallery a{
  width: calc(100% / 3 - 20px / 3);
  height: 70px;
  border-radius: 5px;
  overflow: hidden;
}

._address-gallery img{
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

._address__location{
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
  font-weight: 700;
}

._address__location span{
  display: flex;
  gap: 5px;
  color: #FF4F00;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

._address-phone{
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

._address-phone-inner{
  display: flex;
  flex-direction: column;
  gap: 5px;
}

._address-phone__el{
  display: flex;
  align-items: center;
  gap: 5px;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  text-decoration: none;
}

._address-phone__el--short{
  gap: 3px;
}

._address-phone__el--short span {
  margin-left: 4px;
  font-size: 16px;
  font-style: normal;
  line-height: 120%;
}

._work-time{
  max-width: 105px;
  color: #000;
  font-size: 16.5px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

._work-time strong{
  font-size: 15px;
}

._contact-nav{
  display: none;
}

@media (max-width: 1250px) {
  ._contact-content {
    flex-direction: column;
    max-height: initial;
    margin-bottom: 15px;
  }

  ._contact-map {
    width: 100%;
  }

  ._more-contacts {
    flex-direction: column;
    gap: 15px;
  }

  ._more-contacts__item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 10px 20px;
    min-height: 118px;
  }

  ._contact-info {
    flex-direction: column;
    gap: 20px;
    padding: 15px 10px;
  }

  ._contact-info__btn {
    background: white;
    color: #ff4f00;
    border: 1px solid #ff4f00;
    border-radius: 5px;
  }

  ._address-list {
    margin: 0;
    padding: 0;
    overflow: initial;
  }

  ._more-contacts__title{
    margin-bottom: 15px;
  }

  ._contact-networks{
    gap: 12px;
  }

  ._contact-networks__el svg{
    width: 34px;
    height: 34px;
  }

  ._contact-map{
    max-height: 506px;
  }
}

@media (max-width: 768px) {
  ._contact-nav{
    position: relative;
    display: flex;
    margin-bottom: 15px;
    border-radius: 5px;
    overflow: hidden;
  }

  ._contact-nav:before{
    position: absolute;
    content: "";
    height: 1px;
    width: 100%;
    left: 0;
    top: 0;
    background: #BDBDBD;
  }

  ._contact-nav:after{
    position: absolute;
    content: "";
    height: 1px;
    width: 100%;
    left: 0;
    bottom: 0;
    background: #BDBDBD;
  }

  ._contact-nav__item{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    height: 34px;
    width: 50%;
    font-size: 16px;
    border-radius: 5px;
    border: 1.5px solid #BDBDBD;
    background: white;
  }

  ._contact-nav__item.active{
    position: relative;
    border: 1.5px solid #ff4f00;
    color: #ff4f00;
    z-index: 1;
  }

  ._contact-nav__item path{
    stroke: #000000;
  }

  ._contact-nav__item.active path{
    stroke: #ff4f00;
  }

  ._contact-nav__item:last-child:not(.active){
    border-left: none;
    border-radius: 0 5px 5px 0;
  }
  ._contact-nav__item:first-child:not(.active){
    border-right: 1.5px solid transparent;
    border-radius: 5px 0 0 5px;
  }

  ._address-list,
  ._more-contacts,
  ._contact-info,
  ._contact-map{
    display: none;
  }


  ._address-list.active,
  ._more-contacts.active,
  ._contact-info.active,
  ._contact-map.active{
    display: flex;
  }

  ._more-contacts__el{
   max-width: 250px;
  }

  ._address__location span{
    width: initial;
    margin: 0;
    text-decoration-line: underline;
    text-decoration-skip-ink: none;
  }
}

@media (min-width: 1024px) {
  ._address-list{
    width: 40%;
  }
}

.weekend-popup{
  max-width: 640px;
  padding: 0;
  border-radius: 12px;
  overflow: hidden;
}

.weekend-popup.show{
  display: block;
}

.weekend-popup-content {
  display: flex;
  align-items: center;
}

.weekend-popup-img {
  width: 329px;
  height: 464px;
  flex-shrink: 0;
}

.weekend-popup-img img{
  display: block;
  width: 100%;
  height: 100%;
}

.weekend-popup-description{
  text-align: center;
  padding: 0 28px 0 18px;
}

.weekend-popup__title{
  font-weight: 700;
  font-size: 30px;
  line-height: 120%;
  text-align: center;
  color: #000;
  margin-bottom: 23px;
}

.weekend-popup__info{
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
  text-align: center;
  color: #ff4f00;
  margin-bottom: 15px;
}

.weekend-popup__text{
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
  text-align: center;
  color: #000;
}

.weekend-popup-img .weekend-img-mobile{
  display: none;
}

@media (max-width: 768px) {
  .weekend-popup-img .weekend-img-mobile {
    display: block;
  }

  .weekend-popup-img .weekend-img-dec {
    display: none;
  }
  .weekend-popup-content {
    flex-direction: column;
  }

  .weekend-popup {
    max-width: 315px;
  }

  .weekend-popup-img {
    width: 100%;
    height: 213px;
  }

  .weekend-popup-description {
    padding: 20px 52px 30px;
  }

  .weekend-popup__title {
    font-size: 20px;
    margin-bottom: 16px;
  }

  .weekend-popup__info {
    font-size: 16px;
    margin-bottom: 6px;
  }

  .weekend-popup__text {
    font-size: 16px;
  }

  .weekend-popup ._c-popup__cross {
    right: 25px;
    top: 22px;
  }

  .weekend-popup ._c-popup__cross path {
    stroke: white;
    stroke-width: 2px;
  }

  .mobile-menu-site li{
    display: flex;
    padding-left: 15px;
    border-bottom: 1px solid #e9e9e9;
  }
}

.status-not_in_stock .product-id--inner{
  justify-content: flex-end;
}

.status-not_in_stock .product-id--inner .item--text{
  flex-grow: 1;
}

.choices__inner{
  border: none;
  padding: 0 !important;
  min-height: initial;
}

.choices__list--single{
  height: 24px;
  padding: 4px 15px 4px 5px;
  background: #fff;
  border: 1px solid #d7d9da;
  border-radius: 4px;
}

.choices__list--dropdown .choices__item{
  word-break: normal;
}

.choices__list--dropdown .choices__item--selectable.is-highlighted:after{
  content: none;
}

.choices[data-type*=select-one]:after{
  border: none;
  width: 9px;
  height: 7px;
  background: url(/build/images/arrow-select.815b6182.svg) no-repeat center;
  margin-top: -3.5px;
}

.choices[data-type*=select-one].is-open:after{
  background: url(/build/images/arrow-select.815b6182.svg) no-repeat center;
  margin-top: -3.5px;
}

.choices__list.choices__list--dropdown{
 margin-top: 0;
  border: 1px solid #efefef;
  border-radius: 5px;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.18);
  background: #fff;
  z-index: 35;
}

.choices__list--dropdown .choices__item--selectable.is-highlighted{
  background: #ff4f00;
  color: white;
  word-break: normal;
}

.choices__list--dropdown .choices__item {
  position: relative;
  padding: 5px 20px;
}

.right-part--sort{
  position: relative;
  cursor: pointer;
  min-width: 198px;
}

.price_sort--all{
  display: flex;
  align-items: center;
  gap: 8px;
  white-space: nowrap;
  cursor: pointer;
}

.price_sort-list{
  position: absolute;
  display: none;
  width: 100%;
  top: 100%;
  left: 0;
  border: 1px solid #efefef;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.18);
  background: #fff;
  z-index: 35;
}

#sortList.price_sort-list label{
  font-size: 14px;
  margin: 0;
  padding-left: 30px;
}

#sortList.price_sort-list input[type="radio"]{
  display: none;
}

#sortList.price_sort-list label:before{
  position: absolute;
  left: 0;
  width: 15px;
  height: 15px;
  border: 1px solid #747474;
}

#sortList.price_sort-list label:after{
  position: absolute;
  left: 3px;
  top: 3px;
  border-radius: 100%;
  background: transparent;
  width: 9px;
  height: 9px;
  transition: .3s;
}

#sortList.price_sort-list input:checked + label{
  color: #ff4f00;
}

#sortList.price_sort-list input:checked + label:after{
  background: #ff4f00;
}

#sortList.price_sort-list input:checked + label:before{
  border-color: #ff4f00;
}

.row-input{
  width: 100%;
  text-align: left;
}

#sortList.price_sort-list .row-input:not(:last-child){
  margin-bottom: 15px;
}

.choices__list--single .choices__item{
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 768px) {
  .price_sort--all svg{
    flex-shrink: 0;
  }

  .filter-set .right-part{
    margin: 0;
    flex-grow: 1;
    max-width: 195px;
  }

  .price_sort-list{
    top: calc(100% + 5px);
  }
}

.main-location-list .pickup-order-map{
  align-items: flex-start;
  cursor: pointer;
  transition: .3s;
  color: #ff4f00;
}

.main-location-list .pickup-order-map strong{
  text-decoration: underline;
  text-decoration-skip-ink: none;
  transition: .3s;
}

.main-location-list .pickup-order-map:hover strong{
  text-decoration: none;
}

.c-popup-map{
  padding: 0;
}

._footer-phone{
  position: relative;
  display: flex;
  align-items: center;
  gap: 20px;
  font-weight: 700;
  font-size: 33px;
  line-height: 120%;
  color: #2a2a2a;
  margin-bottom: 8px;
}

._footer-phone-list{
  display: flex;
  gap: 78px;
  flex-grow: 1;
}

._footer-phone-list ._location-number{
  display: flex;
  gap: 4px;
  font-weight: 700;
  font-size: 14px;
  line-height: 120%;
  color: #000;
  margin-bottom: 12px;
}

._footer-phone-list a{
  font-weight: 400;
  font-size: 13.5px;
  line-height: 120%;
  text-align: center;
  color: #2a2a2a;
  text-decoration: none;
}

._footer-phone-list ._number:not(:last-child){
  margin-bottom: 8px;
}

._footer-phone-inner{
  border-top: 1px solid #d7d9da;
  padding: 30px 0;
}

._footer-phone-inner ._container{
  display: flex;
  gap: 90px;
}

.__footer-networks__el a{
  display: flex;
}

@media (max-width: 768px) {
  .footer-inner{
    min-width: initial;
    align-items: center;
    gap: 13px;
  }

  ._footer-networks{
    justify-content: center;
    gap: 18px;
  }

  ._footer-networks__text{
    font-weight: 400;
    font-size: 14px;
    line-height: 110%;
    margin: 0;
  }

  ._footer-phone{
   font-size: 30px;
    gap: 7px;
  }

  ._footer-phone ._i-arrow{
    margin-left: 8px;
  }

  .__footer-networks__el svg{
    width: 34px;
    height: 34px;
  }

  ._footer-phone-inner{
    display: flex;
    align-items: center;
    gap: 30px;
    padding: 27px 0 20px;
    border-top: none;
  }

  ._footer_phone-hide{
    display: none;
    padding: 15px 0 14px;
    border-top: 1px solid #E6E6E6;
    border-bottom: 1px solid #E6E6E6;
    margin: -24px 17px 20px;
  }

  .single-number{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-bottom: 15px;
    font-weight: 400;
    font-size: 15px;
    color: #2a2a2a;
  }

  ._footer-phone-list{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 0;
    position: static;
    background: transparent;
    opacity: 1;
    border: none;
    box-shadow: none;
    padding: 0;
  }

  .single-number a{
    font-weight: 700;
    font-size: 20px;
    line-height: 120%;
    color: #ff4f00;
    text-decoration: none;
  }

  ._footer-phone-list__el{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
    gap: 10px;
    padding: 13px 23px;
    border-bottom: 1px solid rgba(230, 230, 230, 0.5);
  }

  ._footer-phone-list__el:first-child{
    padding-top: 0;
  }

  ._footer-phone-list__el:last-child{
    padding-bottom: 0;
    border-bottom: none;
  }

  ._footer-phone-list__el ._location-number{
    display: flex;
    flex-direction: column;
    gap: 3px;
    font-weight: 700;
    font-size: 18px;
    line-height: 120%;
    color: #000;
    margin-bottom: 2px;
  }

  ._footer-phone-list__el ._location-number span:first-child{
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    color: #000;
  }

  ._footer-phone-list__el ._number svg{
    flex-shrink: 0;
    width: 16px;
    height: 15px;
  }

  footer{
    padding: 30px 0;
  }

  ._footer-phone-list__el ._number a {
    font-size: 14px;
    font-weight: 400;
    line-height: 120%;
  }

  ._footer-phone{
    align-items: center;
  }

  ._footer-phone.active ._i-arrow{
    transform: rotate(180deg);
  }

  ._footer-phone-list{
    width: initial;
  }

  .cart-feedback_popup ._ico-close {
    top: 15px;
    right: 15px;
  }

  .default_product:hover{
    border-color: #d7d9da;
    outline: none;
  }

  .benefits__item-img svg {
    flex-shrink: 0;
    width: 30px;
    height: 24px;
  }

  ._location-number svg{
    height: inherit;
  }

  .checkout-report_blk{
    margin: 24px 0 15px;
    padding-left: 0;
  }

  .control-submit-cart{
    margin-top: 0;
    padding-left: 0;
  }

  .control-submit-cart .orange_btn{
    width: 100%;
    padding: 12px 24px;
  }
}

.popup-product-info-product-page ._c-popup__cross:hover path{
  stroke: black;
}

.status-not_in_stock .default_product .item--text{
 margin-bottom: 2px;
}

.popup-product-info-product-page.active{
  display: block;
}

.grecaptcha-badge {
  visibility: hidden !important;
}

.adoptive-table {
  overflow-x: auto;
}

._user-data{
  display: flex;
  border-radius: 15px;
  border: 1px solid #FF4F00;
  margin-bottom: 30px;
}

._user-data__item{
  width: 47%;
}

.user-data__person{
  padding: 22px 30px 29px 26px;
}

._user-data__title{
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 20px;
  padding-bottom: 19px;
  border-bottom: 1px solid #474747;
  margin-bottom: 19px;
  color: #474747;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
}

._user-data-list{
  width: 100%;
  padding: 0;
  margin: 0;
}

._user-data-list__item:before{
  content: none;
}

._user-data-list__item{
  display: flex;
}

._user-data-list__item:not(:last-child){
  margin-bottom: 14px;
}

._user-data-list__item-description{
  width: 50%;
  color: #474747;
  font-size: 14px;
  line-height: 14px;
  flex-shrink: 0;
}

._user-data-list__item-result{
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
}

._user-data__item.user-data__discount ._user-data-list__item-result{
  white-space: nowrap;
}

.user-data__discount{
  display: flex;
  flex-direction: column;
  border-radius: 14px;
  border: 1px solid #FF4F00;
  border-right: none;
  background: #F4FEFE;
  margin: -1px 0 -1px 0;
  flex-grow: 1;
}

._user__discount-inner{
  min-height: 110px;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 22px;
  border-radius: 13px;
  border: 1px solid #ff4f00;
  border-top: none;
  margin: 0 -1px 0 -1px;
}

._user__discount__title{
  color: #000;
  font-size: 17px;
  font-weight: 700;
  line-height: 120%;
  margin-bottom: 5px;
}

._no-card ._user__discount__title{
  font-size: 14.5px;
}

._user__discount__sale{
  color: #FF4F00;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
}

._user__discount__sale a{
  color: #FE5102;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  text-decoration-line: underline;
  text-decoration-skip-ink: none;
  margin-top: 5px;
}

._user__discount__points{
  display: flex;
  align-items: center;
  gap: 5px;
  color: #474747;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  margin-top: 10px;
}

._user__discount__points strong{
  color: #000;
}

._user__discount__sum{
  display: flex;
  align-items: center;
  gap: 20px;
  text-align: center;
  margin-top: 11px;
  padding: 0 22px;
}

._user__discount__info ._user__discount__sum{
  padding: 0;
}

._user__discount__sum__description{
  display: flex;
  gap: 5px;
  color: #474747;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
}

._user__discount__sum__description strong{
  color: #000;
}

._user__discount__img{
  display: flex;
  max-width: 64px;
  flex-shrink: 0;
}

._user__discount__img img{
  display: block;
  width: 100%;
}

.discount-progress__description{
  color: #474747;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  margin-top: 30px;
  margin-bottom: 24px;
  text-align: center;
  padding: 0 10px;
}

.discount-range{
  padding: 0 21px 0 21px;
}
.discount-range-content{
  position: relative;
  width: 100%;
  height: 7px;
  background: #D9D9D9;
  margin: 30px 0 20px;
  border-radius: 5px;
}

.discount-range__dot__center{
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(0, -50%);
  z-index: 2;
}

.discount-range__dot__center:before{
  position: absolute;
  content: "3%";
  color: #FF4F00;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
  top: -13px;
  left: 2px;
}

.discount-range__dot__end{
  display: flex;
  position: absolute;
  top: 50%;
  left: calc(100% - 15px);
  transform: translate(0, -50%);
  z-index: 2;
}

.discount-range__dot__end:before{
  position: absolute;
  content: "5%";
  color: #FF4F00;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
  top: -13px;
  left: 2px;
}

.discount-range__progress{
  display: flex;
  position: relative;
  z-index: 1;
  height: 100%;
  border-radius: 5px 0px 0px 5px;
  background: #FF4F00;
}

.discount-range__progress:before{
  position: absolute;
  content: "";
  border: 10px solid transparent;
  border-bottom: 8px solid #FF4F00;
  bottom: -25px;
  left: 100%;
  transform: translate(-50% , -50%);
}

._user__discount__info{
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  padding: 0 22px;
  gap: 24px;
}

._user__discount__info ._user__discount__sum{
  justify-content: flex-start;
  margin: 0;
}

._user__discount__info ._user__discount__points{
  margin-top: 0;
}

._user__discount__points svg{
  margin-right: 15px;
}

._user__discount__info ._user__discount__points strong,
._user__discount__info ._user__discount__sum__description strong{
  color: #474747;
}

._order-green{
  color: #278E9D;
}

._order-green.minus-points{
  color: #FE0202;
}

._order-red{
  color: #FE0202;
}

._table-custom thead th{
  color: #474747;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
  background: #F4FEFE;
  padding: 12px;
}

._table-custom td{
  padding: 9px 12px;
}


._table-custom-history ._table-custom_order-number{
  max-width: 37px;
  min-width: initial;
}

._table-custom-history ._order-photo{
  width: 151px;
  min-width: initial;
}

._table-custom-history tbody  ._order-photo{
  width: 151px;
  height: 130px;
  min-width: initial;
}

._table-custom-history ._order-photo img{
  height: 100%;
}


._table-custom-history td.summary a{
  display: block;
  text-align: left;
}

._order-status{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0 20px;
}

._order-status h2{
  margin: 0;
}

._order-status-btn{
  display: flex;
  align-items: center;
  gap: 26px;
}

._order-status-btn__item{
  display: flex;
  align-items: center;
  gap: 5px;
}

._order-status-btn__item a{
  color: #474747;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
  text-decoration-line: underline;
  text-decoration-skip-ink: none;
  transition: .3s;
}

._order-status-btn__item a:hover{
  color: #FE5102;
}

._user-data__title--order{
  justify-content: flex-start;
  width: 100%;
  gap: 10px;
}

._user-data__title--order ._user-data-list__item-description {
  width: 29%;
  flex-shrink: 0;
}

.user-data__person--order{
  width: 410px;
  flex-shrink: 0;
  padding-bottom: 34px;
}

._user__discount-inner--order{
  flex-direction: column;
  border: none;
  gap: 0;
  padding-right: 44px;
}

.user-data__person--order ._user-data-list__item-description{
  width: 86px;
  flex-shrink: 0;
}

._user-data-list--order ._user-data-list__item-description{
  width: 60%;
}

._order-total{
  display: flex;
  width: 100%;
  padding: 23px 0 24px;
  background: url(/build/images/dashed.93abf15d.png) repeat-x top , url(/build/images/dashed.93abf15d.png) repeat-x bottom;
  margin: 19px 0 13px;
}

._order-total__text{
  width: 60%;
  color:  #474747;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  text-transform: uppercase;
}

._order-total__sum{
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
  white-space: nowrap;
}

._order-points{
  display: flex;
  width: 100%;
}

._order-points__text{
  width: 65%;
  color:  #474747;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
}

._order-points__sum{
  color: #60AF73;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
}

._order-points__sum.minus-points{
  color: #FE0202;
}

._user-data__title--receipt{
  position: relative;
  padding-left: 30px;
}

._user-data__title--receipt:before{
  position: absolute;
  content: "";
  width: 20px;
  height: 20px;
  left: 0;
  background: url(/build/images/list-order.c0274cef.svg) no-repeat center;
}

._table-custom td._order-number{
  color: #FE5102;
  text-decoration-line: underline;
}

._status-order-inner{
  display: flex;
  align-items: flex-start;
  gap: 15px;
}

._status-order__item{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 5.5px 10px;
  color: #FFF;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  border-radius: 5px;
}

._status-order__item--done{
  background: #60AF73;
}

._status-order__item--work{
  background: #f5c900;
}

._status-order__item--new{
  background: #4DA2DB;
}

.popup-account{
  display: none;
}

.account-data{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  margin-top: 9px;
}

.account-data__name{
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26.667px;
}

.account-data__email{
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
}

.account-list{
  margin: 24px 0 0 0;
  padding: 0;
  color: #000;
  font-size: 16px;
  line-height: 14px;
}

.account-list__item{
  margin: 0;
}

.account-list__item a{
  display: flex;
  align-items: center;
  gap: 15px;
  height: 41px;
  padding: 10px 22px;
  border-bottom: 1px solid #F2F2F2;
  color: #000;
  font-family: 'PT Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  text-decoration: none;
}

.account-list__item:first-child{
  border-top: 1px solid #F2F2F2;
}

.account-list__item--basket a{
  position: relative;
}

.account-basket-cnt{
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 23px;
  height: 23px;
  background: #FE5102;
  right: 22px;
  color: #FFF;
  font-size: 10.5px;
  font-style: normal;
  font-weight: 400;
  line-height: 10.5px;
  border-radius: 100%;
}

.current-password-block{
  display: flex;
  align-items: center;
}

@media (max-width: 1230px) {
  ._user-data{
    flex-direction: column;
  }

  ._user-data__item{
    width: 100%;
  }

  .user-data__discount{
    margin: 0 0;
    border-bottom: 0;
    border-left: none;
    padding-bottom: 24px;
  }

  ._user__discount__sum{
    margin-top: 19px;
  }

  ._user__discount__info {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 24px 22px 0;
  }

  ._status-order__item{
    padding: 5px 10px;
    font-size: 12px;
    margin-top: 2px;
  }

  ._status-order-inner{
    align-items: center;
  }

  .discount-progress__description{
    padding: 0;
    margin-bottom: 0;
  }
}

@media (max-width: 859px) {
  .popup-account{
    position: fixed;
    top: 95px;
    left: -100%;
    display: block;
    overflow-y: auto;
    width: 100%;
    height: 100%;
    max-height: calc(100vh - 80px);
    z-index: 100;
    background: white;
    font-family: 'PT Sans';
  }

  .popup-account.active{
    left: 0;
  }

  #profile-menu_btn{
    display: none;
  }

  ._order-status{
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }

  .current-password-block{
    display: flex;
  }
}

._status__back-link{
  display: none;
}

@media (max-width: 768px) {
  ._status__back-link{
    display: flex;
  }

  ._status-order-inner h1{
    margin: 0;
    flex-grow: 1;
  }

  .user-date_blk{
    margin-top: 13px;
  }
}

._user__discount__sale--more{
  color: #000;
}

._user__discount__sale--more span{
  color: #FE5102;
}

._phone-list__text svg{
  margin-bottom: 5px;
}

._user__discount-inner._no-card{
  flex-direction: column;
  align-items: flex-start;
  border: none;
}

.discount__img__inner{
  display: flex;
  align-items: center;
  gap: 20px;
}

._user__discount-inner__title{
  font-weight: 700;
  font-size: 18px;
  text-transform: uppercase;
  color: #FE5102;
}

.discount-btn-inner{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}

.discount-btn{
  display: block;
  margin-top: 15px;
  border: 1px solid #FE5102;
  font-size: 13px;
  font-weight: 400;
  padding: 5px 16px;
  border-radius: 3px;
  text-decoration: none;
  transition: .3s;
}

._no-card .discount-btn{
  margin: 0;
}

.discount-btn:hover{
  background: #ff4f00;
  color: #fff;
}

.silver-card .discount-btn{
  font-size: 13px;
}

._user__discount-inner.silver-card{
  min-height: 122px;
}

._user__discount-inner.show-btn{
  align-items: flex-start;
}

.discount-wrapper{
  height: 100%;
  padding: 0 20px;
}

.discount-wrapper ._user__discount__points{
  height: 100%;
  margin: 0;
}

@media (max-width: 768px) {
  ._user__discount-inner._no-card{
    padding-bottom: 0;
  }

  .discount-btn{
   font-size: 14px;
  }

  .discount-wrapper{
    padding-top: 20px;
  }
}

.control-submit--card{
  display: flex;
  gap: 20px;
}

.control-submit--card .cart-feedback_submit{
  font-size: 11pt;
  margin: 0;
  padding: 9px 22px;
}

._success-card p{
  font-size: 24px;
  text-align: center;
}

._success-card-content{
  max-width: 830px;
}

@media (max-width: 768px) {
  .control-submit--card{
    flex-direction: column-reverse;
    gap: 15px;
  }

  .success-container__text ._success-card-content{
    margin-top: 20px;
  }

  ._success-card p{
    font-size: 20px;
  }
}

.personal-data-link{
  display: inline-block;
  font-size: 14px;
  color: #a9a9a9;
  transition: .3s;
  margin-top: 20px;
  text-decoration-skip-ink: none;
}
.elem ._total .title{
  color: #4d4d4d;
}

.elem ._total .item{
  line-height: 12px;
}

.personal-data-link:hover{
  color: black;
  text-decoration: none;
}
.elem ._total .item:last-child .title{
  font-weight: 700;
  color: #000;
}

.elem ._total .content,
.elem ._total .content-thin{
  font-size: 14px;
}

.elem ._total ._total-full{
  font-size: 18px;
}

.elem ._total .item+.item{
  margin-top: 12px;
}

.control-submit-cart .orange_btn{
  margin-right: 0;
}

.feedback_blk--sale{
  margin-top: 40px;
}

@media (max-width: 768px) {
  .control-submit .orange_btn {
    font-size: 17px;
    font-weight: 700;
  }

  .elem ._total .content-thin,
  .elem ._total .content{
    font-size: 16px;
  }

  .elem ._total .title {
    line-height: 14px;
  }

  .elem ._total ._total-full {
    font-size: 18px;
  }
}

._product-image-item .owl-dots{
  display: flex;
  justify-content: center;
  gap: 4px;
  margin-top: 19px;
}

._product-image-item .owl-dots button.owl-dot{
  width: 7px;
  height: 7px;
  border-radius: 100%;
  background: #d9d9d9;
}

._product-image-item .owl-dots button.owl-dot.active{
  background: #fe5102;
}

@media (min-width: 1024px) {
  ._product-image-item .owl-dots{
    display: none;
  }
}

.message-client-account {
  font-size: 16px;
  font-weight: 700;
}
